const ObjectService = {
	getFullName: (person: any, allowMX: boolean = true) => {
		let fullName = "";

		if (person) {
			const { title, last_name, first_name } = person;

			if (title) fullName += `${title} `;
			if (!allowMX && title === "Mx.") fullName = "";
			if (first_name) fullName += `${first_name} `;
			if (last_name) fullName += `${last_name}`;
		}

		return fullName.trim();
	},
	getObjectPropertiesSum: (values: any) => {
		let sum = 0;

		for (const key in values) {
			if (Object.prototype.hasOwnProperty.call(values, key)) {
				const element = values[key];
				sum += Number(element);
			}
		}

		return sum;
	},
	getDevice: () => {
		let device = navigator.userAgent
			.split("(")[1]
			.split(")")[0]
			.split(";")[1]
			.split(";")[0];

		if (!device) {
			if (navigator.userAgent.toLowerCase().includes("android"))
				device = "Android";
			else if (navigator.userAgent.toLowerCase().includes("ip"))
				device = "iOS";
			else if (navigator.userAgent.toLowerCase().includes("windows"))
				device = "Windows";
			else if (navigator.userAgent.toLowerCase().includes("mac"))
				device = "Macintosh";
			else if (navigator.userAgent.toLowerCase().includes("linux"))
				device = "Linux";
			else device = "android";
		}

		return device;
	},
};

export default ObjectService;
