import { useState } from "react";
import { useAppSelector } from "redux/hooks";
import BookingService from "services/booking.service";
import ReportAProblemForm from "./ReportAProblemForm";
import CircleLoader from "components/atoms/CircleLoader";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ReportAProblem({ setOpen }: any) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const data = useAppSelector((state) => state.modal.data);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const [success] = await BookingService.reportProblem(data._id, values);
    setLoading(false);

    if (success) setOpen?.(false);
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && <CircleLoader />}
      <Typography mb={"40px"} fontSize={"24px"} fontWeight={700}>
        {t("reportProblem")}
      </Typography>
      <ReportAProblemForm onSubmit={handleSubmit} />
    </div>
  );
}
