import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg">
      <Box mt={{ xs: "60px", md: "80px" }}>
        <Typography
          color={"#520A76"}
          component={"h1"}
          fontSize={{ xs: 24, md: 40 }}
          fontWeight={700}
        >
          {t("Terms and Conditions")}
        </Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Introduction")}
        </Typography>
        <Typography component={"p"} mb={2}>
          {t("Introduction Description A")}
        </Typography>

        <Typography component={"p"}>
          {t("Introduction Description B")}
        </Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Copyright")}
        </Typography>
        <Typography component={"p"}>{t("Copyright Description")}</Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Trademarks")}
        </Typography>
        <Typography component={"p"}>{t("Trademarks Description")}</Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Privacy")}
        </Typography>
        <Typography component={"p"}>{t("Privacy Description")}</Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Registration")}
        </Typography>
        <Typography component={"p"}>{t("Registration Description")}</Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Code of Conduct")}
        </Typography>
        <Typography component={"p"}>
          {t("Code of Conduct Description")}
        </Typography>
        <ul>
          <li>{t("Code of Conduct Description List A")}</li>
          <li>{t("Code of Conduct Description List B")}</li>
          <li>{t("Code of Conduct Description List C")}</li>
          <li>{t("Code of Conduct Description List D")}</li>
          <li>{t("Code of Conduct Description List E")}</li>
          <li>{t("Code of Conduct Description List F")}</li>
          <li>{t("Code of Conduct Description List G")}</li>
          <li>{t("Code of Conduct Description List H")}</li>
          <li>{t("Code of Conduct Description List I")}</li>
          <li>{t("Code of Conduct Description List J")}</li>
          <li>{t("Code of Conduct Description List K")}</li>
          <li>{t("Code of Conduct Description List L")}</li>
          <li>{t("Code of Conduct Description List M")}</li>
        </ul>
        <Typography component={"p"}>
          {t("Code of Conduct Description Last")}
        </Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t(
            "Making Reservations and Purchasing or Requesting Products or Services"
          )}
        </Typography>
        <Typography component={"p"} mb={2}>
          {t(
            "Making Reservations and Purchasing or Requesting Products or Services Description A"
          )}
        </Typography>
        <Typography component={"p"} mb={2}>
          {t(
            "Making Reservations and Purchasing or Requesting Products or Services Description B"
          )}
        </Typography>
        <Typography component={"p"}>
          {t(
            "Making Reservations and Purchasing or Requesting Products or Services Description C"
          )}
        </Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Links")}
        </Typography>
        <Typography component={"p"} mb={2}>
          {t("Links Description A")}
        </Typography>
        <Typography component={"p"} mb={2}>
          {t("Links Description B")}
        </Typography>
        <Typography component={"p"}>{t("Links Description C")}</Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Use of Cookies and Other Tracking Technologies")}
        </Typography>
        <Typography component={"p"}>
          {t("Use of Cookies and Other Tracking Technologies Description")}
        </Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Accuracy of Information")}
        </Typography>
        <Typography component={"p"}>
          {t("Accuracy of Information Description")}
        </Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Disclaimers")}
        </Typography>
        <Typography component={"p"}>{t("Disclaimers Description")}</Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Conflicts")}
        </Typography>
        <Typography component={"p"}>{t("Conflicts Description")}</Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Limitation of Liability")}
        </Typography>
        <Typography component={"p"}>{t("Limitation of Liability Description")}</Typography>
          <ol>
            <li>{t("Limitation of Liability Description List A")}</li>
            <li>{t("Limitation of Liability Description List B")}</li>
            <li>{t("Limitation of Liability Description List C")}</li>
          </ol>
        <Typography component={"p"}>{t("Limitation of Liability Description Last")}</Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Indemnification")}
        </Typography>
        <Typography component={"p"}>{t("Indemnification Description")}</Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Availability of this Website")}
        </Typography>
        <Typography component={"p"}>{t("Availability of this Website Description")}</Typography>
      </Box>
      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Void Where Prohibited")}
        </Typography>
        <Typography component={"p"}>{t("Void Where Prohibited Description")}</Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
