import { date, email, required, requiredAutoComplete } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import AutocompleteRedux from "components/molecules/AutocompleteRedux";
import { BsCircleFill, BsClockFill, BsFillCalendarMinusFill, BsStopwatchFill } from "react-icons/bs";
import SelectRedux from "components/molecules/SelectRedux";
import OptionService from "utils/option.util";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import TimePickerRedux from "components/molecules/TimePickerRedux";

export { default } from "./PickUpForm";

export const fields: ReduxFormField[] = [
	{
		name: "pickup",
		label: "Pickup",
		validate: [requiredAutoComplete],
		component: AutocompleteRedux,
		cellProps: {lg: 3 },
		// InputProps: {
		//   StartIcon: BsCircleFill,
		//   StartIconProps: { color: "#EFDF66" },
		// },
	  },
	  {
		name: "duration",
		label: "Duration",
		validate: [required],
		component: SelectRedux,
		cellProps: { lg: 3 },
		// SelectProps: {
		//   StartIcon: BsStopwatchFill,
		//   options: OptionService.getOptions(23, 2, "", " hours"),
		// },
	  },
	  {
		name: "date",
		label: "Date",
		validate: [date],
		component: DatePickerRedux,
		cellProps: { lg: 3 },
		// InputProps: { StartIcon: BsFillCalendarMinusFill },
		DatePickerProps: {
		  onChange: () => {},
		  minDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
		  InputFieldProps: {},
		  value: { date: "", error: false },
		},
	  },
	  {
		name: "time",
		label: "Time",
		validate: [date],
		component: TimePickerRedux,
		cellProps: { lg: 3 },
		// InputProps: { StartIcon: BsClockFill },
	  },
];
