import { SelectOption } from "components/atoms/Select";

const OptionService = {
  getOptions: (range: number, start: number = 0, before = "", after = "") => {
    let options: SelectOption[] = [];
    for (let i = start; i < range + start; i++) {
      options.push({ value: `${i}`, label: `${before}${i}${after}` });
    }

    return options;
  },
  getOptions_km: (
    range: number,
    start: number = 0,
    before = "",
    after = "",
    incl: any,
    km: any
  ) => {
    let options: SelectOption[] = [];
    let days = 24;
    let labelDays = 1;
    let max_km = 40;
    for (let i = start; i < range + start; i++) {
      if (i > 24) {
        days += 24;
        labelDays += 1;
        max_km += 480;

        options.push({
          value: `${days}`,
          label: ` ${before} ${labelDays}${" days"} (${incl} ${max_km} ${km})`,
        });
      } else {
        max_km += 40;
        options.push({
          value: `${i}`,
          label: ` ${before}${i} ${after} (${incl} ${max_km} ${km})`,
        });
      }
    }

    return options;
  },
  getClassOptions: () => {
    return [
      { value: "First", label: "First Class" },
      { value: "Business", label: "Business Class" },
      { value: "Van", label: "Van Class" },
    ];
  },
};
export default OptionService;
