import type React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ServiceHeader from "assets/services/chauffeur-service-banner.webp";
import Button from "components/atoms/Button";
import PickUp from "components/templates/PickUp";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { bookingActions } from "redux/slices/booking";
import { mapAction } from "redux/slices/map/mapSlice";

import appstoreAr from "assets/app-store--ar.svg";
import playstoreAr from "assets/google-play--ar.svg";
import appStoreDarkAr from "assets/app-store-dark--ar.svg";
import playStoreDarkAr from "assets/google-play-dark--ar.svg";

import complimentaryServicesA from "assets/services/chauffeur1.svg";
import complimentaryServicesB from "assets/services/chauffeur2.svg";
import complimentaryServicesC from "assets/services/chauffeur3.svg";

import ChauffeurA from "assets/services/chauffeurA.webp";
import ChauffeurB from "assets/services/chauffeurB.webp";
import ChauffeurC from "assets/services/chauffeurC.webp";
import ChauffeurD from "assets/services/chauffeurD.webp";

import PersonIcon from "assets/icons/seats.svg";
import BagIcon from "assets/icons/luggage.svg";

import ClassA from "assets/fleet/business-class.png";
import ClassB from "assets/fleet/electric-class.png";
import ClassC from "assets/fleet/premium-class.png";
import ClassD from "assets/fleet/first-class.png";
import ClassE from "assets/fleet/van-class.png";
import ClassF from "assets/fleet/business-class-suv.png";
import ClassG from "assets/fleet/first-class-suv.png";

import "../../servicepage.scss";
import SwiperSlider from "components/atoms/SwiperSlider";
import { SwiperSlide } from "swiper/react";

import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const ChauffeurService = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const tab = useAppSelector((state) => state.booking.tab);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
    if (i18n.language === "en" || pathname.includes("/en")) {
      navigate("/chauffeur-service");
    }
  }, [i18n.language, navigate, pathname]);

  const complimentaryServices = [
    {
      title: "استقبال وترحيب خاص عند الوصول",
      content:
        "استمتع بخدمة تأجير سيارات بسائق مع يلو بلس، حيث نوفر لك أفخم سيارات الليموزين مع سائق خاص محترف لضمان تجربة تنقل مريحة وآمنة. نحرص على تقديم استقبال VIP واهتمام شخصي لا مثيل له، حيث يكون سائقونا المحترفون في انتظارك بالموقع المحدد لمساعدتك في كل خطوة، بدءًا من استقبالك وحمل أمتعتك وحتى وصولك إلى وجهتك بكل راحة وسهولة. مع يلو بلس، ستستمتع بخدمة تأجير سيارات بسائق وفق أعلى معايير الرفاهية، لضمان تجربة سفر راقية ومميزة.",
      image: complimentaryServicesA,
    },
    {
      title: "مرونة فى رحلتك",
      content:
        "ابقَ متحكمًا بشكل كامل في خطط رحلتك مع خدمتنا الفاخرة.  سواء كنت بحاجة إلى تعديل أو إلغاء حجز، فإن خدمتنا توفر مرونة سلسة، مما يتيح لك تعديل جدولك بسهولة لتلبية احتياجاتك.",
      image: complimentaryServicesB,
    },
    {
      title: "رحلة فاخرة بأسعار تنافسية",
      content:
        "ستحصل على تجربة فاخرة وموثوقة بأسعارعادلة وشفافة مع خدمة تاجير سيارة بسائق من يلو بلس. نحن نؤمن بأن عملائنا يستحقون أفضل الخدمات، لذلك نقدم لهم خدمة عالية الجودة بأسعار تنافسية، مع ضمان تعويض عادل لسائقينا المحترفين. أسعارنا تعتمد على المسافة، نوع المركبة المطلوبة، ومدى التوفر ، مما يضمن لك الشفافية الكاملة وعدم وجود أي تكاليف باهظة!",
      image: complimentaryServicesC,
    },
  ];

  const cars = [
    {
      img: ClassB,
      name: "الفئة الكهربائية",
      link: "/",
      person: 3,
      bags: 2,
    },
    {
      img: ClassC,
      name: "الفئة المميزة",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassA,
      name: "فئة رجال الأعمال",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassE,
      name: "فئة الفان",
      link: "/",
      person: 6,
      bags: 4,
    },
    {
      img: ClassF,
      name: "فئة SUV",
      link: "/",
      person: 6,
      bags: 3,
    },
    {
      img: ClassD,
      name: "��لفئة الأولى",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassG,
      name: "الفئة الأولى SUV",
      link: "/",
      person: 6,
      bags: 5,
    },
  ];

  const faqs = [
    {
      question: "كم تبلغ تكلفة استئجار ليموزين مع سائق؟",
      answer:
        "تختلف التكلفة بناءً على عدة عوامل تشمل المسافة، نوع المركبة المطلوبة، ومدى التوفر. في يلو بلس، نضمن لك الشفافية الكاملة من خلال تقديم جميع الرسوم مسبقًا، بحيث يمكنك الحجز بثقة وتجنب أي رسوم غير متوقعة.",
    },
    {
      question: "ما هي المهام التي يقوم بها السائق؟",
      answer:
        "سائقو الليموزين لدينا ليسوا مجرد سائقين عاديين، بل هم محترفون مدربون على أعلى مستوى لتقديم خدمة ممتازة. يقومون بالعديد من المهام لضمان راحتك و رفاهيتك، منها: القيادة الآمنة والمريحة لضمان رحلة سلسة وآمنة، مع الالتزام بقواعد المرور و القيادة الاحترافية. الاستقبال والترحيب: حيث يستقبلونك بكل حفاوة واهتمام، ويساعدونك في حمل الأمتعة إذا لزم الأمر. تلبية احتياجاتك: يسعون جاهدين لتلبية جميع طلباتك واحتياجاتك خلال الرحلة.",
    },
  ];

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Helmet>
        <title>خدمات السائق الخاص | يلو بلس</title>
        <meta name="robots" content="index, follow" />
        <meta name="title" content="خدمات السائق الخاص | يلو بلس" />
        <meta
          name="description"
          content="استمتع بخدمة تأجير سيارات بسائق مع يلو بلس، حيث نوفر لك أفخم سيارات الليموزين مع سائق خاص محترف لضمان تجربة تنقل مريحة وآمنة."
        />
        <link
          rel="canonical"
          href="https://limousine.iyelo.com/ar/خدمات-السائق-الخاص"
        />
      </Helmet>
      <div className="services__container">
        <Container maxWidth="lg">
          <Box
            className="services__main"
            sx={{
              background: {
                xs: "none",
                md: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 38.36%, rgba(0, 0, 0, 0.00) 50%),center center / cover url(${ServiceHeader}) no-repeat`,
              },
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                minHeight: "53.5vh",
                position: "relative",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="travel__style_heading">
                  <h1 className="heading">
                    <span style={{ color: "#FFD207" }}>فاخرة</span> خدمات السائق
                    الخاص
                  </h1>
                  <p className="sub-heading">
                    استمتع بخدمة تأجير سيارات بسائق مع يلو بلس، حيث نوفر لك أفخم
                    سيارات الليموزين مع سائق خاص محترف لضمان تجربة تنقل مريحة
                    وآمنة.
                  </p>
                </div>
                <div className="travel__style">
                  <div className="booking__box">
                    <div className="bottom arBottom">
                      <PickUp />
                    </div>
                    <div ref={ref}></div>

                    <Stack
                      direction="row"
                      maxWidth={{ xs: "100%", md: "fit-content" }}
                      mt={"12px"}
                      borderRadius={{ xs: "12px 12px 0 0", md: "12px" }}
                      overflow={"hidden"}
                    >
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(0));
                        }}
                        sx={{
                          height: "44px",
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          width: { xs: "100%", md: "fit-content" },
                          borderRadius: "0",
                          color:
                            tab === 0
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 0
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 0
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        نقل
                      </Button>

                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(1));
                          dispatch(mapAction.setOpen(false));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 1
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 1
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 1
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        بالساعة
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(2));
                          dispatch(mapAction.setOpen(false));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 2
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 2
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 2
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        بين المدن
                      </Button>
                    </Stack>
                  </div>
                </div>
              </div>

              <Stack
                direction={"row"}
                gap={2}
                justifyContent={{
                  xs: "center",
                  md: "flex-end",
                }}
                mt={4}
              >
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://apps.apple.com/us/app/yelo-limousine/6446365805"
                    target="_blank"
                  >
                    <Box
                      component={"img"}
                      display={{ xs: "none", md: "block" }}
                      src={appstoreAr}
                      alt="App Store"
                    />
                    <Box
                      component={"img"}
                      display={{ xs: "block", md: "none" }}
                      src={appStoreDarkAr}
                      alt="App Store"
                    />
                  </Link>
                </Box>
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.wefaq.limousine"
                    target="_blank"
                  >
                    <Box
                      component={"img"}
                      display={{ xs: "none", md: "block" }}
                      src={playstoreAr}
                      alt="Play Store"
                    />
                    <Box
                      component={"img"}
                      display={{ xs: "block", md: "none" }}
                      src={playStoreDarkAr}
                      alt="Play Store"
                    />
                  </Link>
                </Box>
              </Stack>
            </div>
          </Box>

          <Box mt={{ xs: "60px", md: "80px" }}>
            <Grid container spacing={{ xs: 2, md: 0 }}>
              {complimentaryServices.map((complimentaryService, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card
                    sx={{
                      height: { md: "100%" },
                      px: { xs: 3, md: 4 },
                      py: { xs: 3, md: 0 },
                      boxShadow: "none",
                      borderRadius: { xs: "16px", md: 0 },
                      borderLeft: { md: "1px solid #ECEBF2" },
                      borderRight: {
                        md: index === 2 ? "1px solid #ECEBF2" : 0,
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        p: "0 !important",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <img
                        src={complimentaryService.image || "/placeholder.svg"}
                        alt={complimentaryService.title}
                        style={{ maxWidth: "68px" }}
                      />
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Typography
                          variant="h6"
                          component="h3"
                          fontSize={16}
                          fontWeight={700}
                          color={"#520A76"}
                          letterSpacing={"0.14px"}
                          textOverflow={"ellipsis"}
                        >
                          {complimentaryService.title}
                        </Typography>
                        <Typography variant="body2" fontSize={16}>
                          {complimentaryService.content}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box mt={{ xs: "60px", md: "80px" }}>
            <Grid
              container
              columnSpacing={4}
              alignItems={"center"}
              rowSpacing={{ xs: "60px", md: "80px" }}
            >
              <Grid item xs={12} md={6} order={{ xs: 0, md: 0 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    خدمة الليموزين الفاخرة مع سائق خاص
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    ستحظى بتجربة تنقل فاخرة واحترافية لا تضاهى مع خدمة الليموزين
                    من يلو بلس.
                    <br />
                    سائقينا المحترفين والمدربين على أعلى مستوي يضمنون لك رحلة
                    مريحة وآمنة، وخدمة شخصية تلبي جميع احتياجاتك.
                    <br />
                    يمكنك حجز خدمتك بكل سهولة من خلال موقعنا الإلكتروني أو
                    تطبيقنا الذكي، والاستمتاع بتحكم كامل في رحلتك.
                    <br />
                    مع يلو بلس، أنت دائمًا على اطلاع بالتكاليف، فأسعارنا شفافة
                    وواضحة، السعر الذي تراه عند الحجز هو السعر الذي تدفعه.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${ChauffeurA})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} order={{ xs: 3, md: 2 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${ChauffeurB})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 2, md: 3 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    تأجير سيارات فاخرة مع سائق محترف لرحلة مريحة
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    تضمن خدمة تأجير السيارات الفاخرة مع سائق محترف من يلو بلس
                    تنقلًا سلسًا بين الوجهات، حيث تجمع بين الراحة والرفاهية
                    والسهولة.
                    <br />
                    من الاجتماعات التجارية إلى الفعاليات الخاصة، أو حتى مجرد
                    رحلة بسيطة من نقطة A إلى نقطة B، يلتزم سائقونا المحترفون
                    بجعل رحلتك بلا جهد وخالية من التوتر.
                    <br />
                    يمكنك الاختيار من مجموعة متنوعة من سيارتنا الفاخرة، كل منها
                    مصمم لتعزيز تجربة سفرك.
                    <br />
                    يمكنك الاعتماد على حلول مُخصصة وخالية من المتاعب تتناسب
                    تمامًا مع احتياجاتك وجعل رحلتك أكثر اماناً وراحة
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} order={{ xs: 4, md: 4 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    تميز وخبرة تتجاوز الحدود
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    من شوارع الرياض النابضة بالحياة إلى قلب دبي الساحرة والمناظر
                    الطبيعية التاريخية في الأردن، تمتد خدمتنا عبر المملكة
                    العربية السعودية والأردن والإمارات العربية المتحدة، مما
                    يوفررحلة راقية أينما كنت.
                    <br />
                    مع التزامنا الراسخ بالتميز والخبرة، نضمن أن تكون رحلتك سلسة،
                    سواء كنت في قلب المدينة أو مسافرًا إلى وجهات أكثر هدوءًا.
                    <br />
                    أينما كانت وجهتك، تضمن لك يلو بلس تجربة راقية عبر إحدى أفضل
                    شركات تأجير سيارات بسائق، حيث نقدم خدمة عالمية لا مثيل لها
                    تتجاوز الحدود.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 5, md: 5 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${ChauffeurC})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} order={{ xs: 7, md: 6 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${ChauffeurD})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 6, md: 7 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    سائقون محترفون لتجربة سفر استثنائية
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    سائقو السيارات الفاخرة لدى يلو بلس هم أكثر من مجرد سائقين!
                    إنهم محترفون حقيقيون ملتزمون بتوفير تجربة سفر استثنائية.
                    <br />
                    مع شغفهم بالخدمة واهتمامهم بأدق التفاصيل، يضمنون لك رحلة سفر
                    سلسة وآمنة، لتصل إلى وجهتك وأنت في قمة الاسترخاء والراحة.
                    <br />
                    التزامنا بالتميز هو أكثر ما يميزنا عن الآخرين.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box mt={{ xs: "60px", md: "80px" }}>
          <SwiperSlider
            name="fleet"
            heading="اكتشف مجموعتنا من السيارات الفاخرة"
            slidesPerViews={5}
            showNavigation={true}
          >
            {cars.map((data, index) => (
              <SwiperSlide key={index} style={{ width: "auto" }}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "16px",
                    overflow: "hidden",
                    p: { xs: "12px", md: "24px" },
                    background:
                      "linear-gradient(180.00deg, rgb(249, 237, 255),rgb(255, 255, 255) 100%)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "16px",
                    height: { xs: 200, md: 300 },
                  }}
                >
                  <Box
                    component="img"
                    src={data.img}
                    alt={data.name}
                    sx={{
                      width: "100%",
                      height: { xs: "120px", md: "200px" },
                      objectFit: "contain",
                    }}
                  />
                  <Box pt={{ xs: 0, md: 3 }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: { xs: "14px", sm: "18px" },
                        lineHeight: { xs: "18px", sm: "24px" },
                        letterSpacing: "0.09px",
                        color: "#520a76",
                      }}
                    >
                      {data.name}
                    </Typography>
                    <Stack direction={"row"} gap={1.5} mt={2}>
                      <Stack gap={"4px"} direction={"row"} alignItems={"end"}>
                        <img
                          loading="lazy"
                          src={PersonIcon || "/placeholder.svg"}
                          alt=""
                          width={"18px"}
                        />
                        <Typography
                          lineHeight={1}
                          fontWeight={300}
                          fontSize={{ xs: "12px", sm: "14px" }}
                        >
                          حد أقصى&nbsp;
                          {data.person}
                        </Typography>
                      </Stack>
                      <Stack gap={"4px"} direction={"row"} alignItems={"end"}>
                        <img
                          loading="lazy"
                          src={BagIcon || "/placeholder.svg"}
                          alt=""
                          width={"18px"}
                        />
                        <Typography
                          lineHeight={1}
                          fontWeight={300}
                          fontSize={{ xs: "12px", sm: "14px" }}
                        >
                          حد أقصى&nbsp;
                          {data.bags}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </SwiperSlider>
        </Box>

        <Container maxWidth="lg">
          <Box mt={{ xs: "60px", md: "80px" }}>
            <Typography
              variant="h4"
              sx={{
                color: "#520a76",
                fontSize: { xs: 24, md: 32 },
                fontWeight: "bold",
                lineHeight: { xs: "32px", md: "48px" },
                letterSpacing: "0.165px",
                mb: { xs: 2, md: 4 },
              }}
            >
              الأسئلة الشائعة
            </Typography>

            <Grid container spacing={2}>
              {faqs.map((faq, index) => (
                <Grid item md={6} key={index}>
                  <Accordion
                    sx={{
                      background: "transparent",
                      border: "transparent",
                      borderRadius: "16px !important",
                      backgroundColor: "#F9F9F9",
                      boxShadow: "none",
                      "&.Mui-expanded": {
                        marginBottom: 0,
                        marginTop: 0,
                      },
                      "& .MuiPaper-root": {
                        marginBottom: 0,
                        marginTop: 0,
                      },
                    }}
                    expanded={expanded === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowLeft
                          style={{
                            transform:
                              expanded === `panel${index}`
                                ? "rotate(-270deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.3s",
                            fontSize: "24px",
                          }}
                        />
                      }
                      sx={{
                        p: 3,
                        borderRadius: "16px",
                        marginBottom: 0,
                        "& .MuiAccordionSummary-content": {
                          my: "0 !important",
                        },
                        "&.Mui-expanded": { my: 0, minHeight: "auto" },
                        minHeight: "auto",
                      }}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: 16,
                          fontWeight: 600,
                          letterSpacing: "0.17px",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ pt: 0, px: 3, pb: 3 }}>
                      <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default ChauffeurService;
