import { Suspense } from "react";
import PrivateRoute from "./PrivateRoute";
import { private_routes, public_routes } from "routes";
import CircleLoader from "components/atoms/CircleLoader";
import { Navigate, Route, Routes } from "react-router-dom";
// import FullScreenLoader from "components/atoms/FullScreenLoader";

export default function AppRoutes() {
  return (
    <div style={{ minHeight: "calc(100vh - 79px)", position: "relative" }}>
      <Suspense fallback={<CircleLoader />}>
        <Routes>
          {/* <Route
            path="/ar/chauffeur-service"
            element={<Navigate to={`/`} replace />}
          />
          <Route
            path="/ar/limousine-service"
            element={<Navigate to={`/`} replace />}
          />
          <Route
            path="/ar/airport-transfers"
            element={<Navigate to={`/`} replace />}
          />
          <Route
            path="/ar/city-to-city-transfer"
            element={<Navigate to={`/`} replace />}
          />
          <Route
            path="/ar/hire-by-the-hour"
            element={<Navigate to={`/`} replace />}
          />
          <Route
            path="/ar/event-transportation"
            element={<Navigate to={`/`} replace />}
          /> */}
          {public_routes.map(({ path, element }, i) => (
            <Route key={i} path={path} element={element} />
          ))}

          {private_routes.map(({ path, element }, i) => (
            <Route
              key={i}
              path={path}
              element={<PrivateRoute>{element}</PrivateRoute>}
            />
          ))}

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </div>
  );
}
