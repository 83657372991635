import { SelectOwnProps } from ".";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Box, styled, Typography } from "@mui/material";

export type SelectProps = SelectOwnProps &
  React.ComponentProps<typeof TextField>;

const CssTextField = styled(TextField)<{ customize?: boolean }>(
  ({ customize }) => ({
    // Filled Input
    "& .MuiFilledInput-root": {
      backgroundColor: "#FFFFFF",
      borderRadius: "12px",
      border: " 1px solid #ECEBF2",
    },
    "& .MuiFilledInput-root:after, .MuiFilledInput-root:before": {
      display: "none",
    },
    "& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
      backgroundColor: "#ececec",
      opacity: "0.5",
    },
    // "& .MuiFilledInput-root .Mui-disabled": {
    //   backgroundColor: "#ececec",
    //   opacity: "0.5",
    // },
    "& .Mui-focused": {
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiFilledInput-input:hover": {
      borderRadius: "12px",
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiFilledInput-root .MuiSelect-select": {
      padding: customize ? "16px 32px 16px 16px" : "24px 32px 8px 12px",
    },
  })
);

export default function Select({
  options = [],
  customize,
  StartIcon,
  StartIconProps,
  ...rest
}: SelectProps) {
  console.log("Here--->>", rest.name, customize);

  return (
    <>
      {StartIcon ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#FFFFFF",
            paddingLeft: "10px",
            borderRadius: "4px",
          }}
        >
          <StartIcon style={{ fontSize: "18px", ...StartIconProps }} />
          {/* <img
            src={StartIcon}
            alt=""
            loading="lazy"
            style={{ maxWidth: "18px" }}
          /> */}
          <CssTextField
            select
            color="info"
            fullWidth
            variant="filled"
            SelectProps={{ MenuProps: { sx: { maxHeight: "300px" } } }}
            {...rest}
          >
            {options.map(({ value, label }, index) => (
              <MenuItem key={index} value={value}>
                {label}
              </MenuItem>
            ))}
          </CssTextField>
        </Box>
      ) : (
        <>
          {customize && (
            <Typography
              mb={1}
              color={"#0a0a0e"}
              fontSize={"12px"}
              fontWeight={400}
              textTransform={"uppercase"}
            >
              {rest?.label}
            </Typography>
          )}
          <CssTextField
            color="info"
            select
            fullWidth
            customize
            variant="filled"
            SelectProps={{ MenuProps: { sx: { maxHeight: "300px" } } }}
            {...rest}
            label={!customize ? rest.label : ""}
          >
            {options.map(({ value, label }, index) => (
              <MenuItem key={index} value={value}>
                {label}
              </MenuItem>
            ))}
          </CssTextField>
        </>
      )}
    </>
  );
}
