import DatePicker from "components/atoms/DatePicker";
import { useEffect, useState } from "react";
import i18next from "i18next";

export default function DatePickerRedux({
  input,
  label,
  InputProps,
  handleBlur,
  handleFocus,
  DatePickerProps,
  meta: { error, touched, invalid },
  ...rest
}: any) {
  const { onChange, ...inputRest } = input;

  const [cError, setCError] = useState(i18next.t(`${error}`));
  const [currentLanguage, setCurrlang] = useState(i18next.language);

  if (currentLanguage !== i18next.language) {
    setCurrlang(i18next.language);
  }
  useEffect(() => {
    setCError(i18next.t(`${error}`));
  }, [error, currentLanguage]);

  return (
    <DatePicker
      {...DatePickerProps}
      {...rest}
      value={input.value}
      onChange={onChange}
      InputFieldProps={{
        ...InputProps,
        ...inputRest,
        label,
        helperText: touched && invalid && cError,
        error: touched && invalid && error && true,
        onBlur: (e) => {
          handleBlur?.(e);
          e.preventDefault();
        },
        onFocus: (e) => {
          handleFocus?.(e);
          e.preventDefault();
        },
      }}
    />
  );
}
