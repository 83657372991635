import Input from "../Input";
import { useState } from "react";
import { TimePickerProps } from ".";
import { default as BaseTimePicker } from "@mui/lab/TimePicker";
import { Box } from "@mui/material";

export default function TimePicker({
  onChange,
  editable,
  StartIcon,
  InputFieldProps,
  value: { date: value = "" },
  ...rest
}: TimePickerProps) {
  const [open, setOpen] = useState(false);
  const InputProps = {
    autoComplete: "off",
    id: "time",
    InputLabelProps: { shrink: true },
    ...InputFieldProps,
    onClick: () => setOpen(true),
    onKeyDown: (e: any) => {
      if (!editable) e.preventDefault();
    },
    error: InputFieldProps?.helperText ? InputFieldProps?.error : false,
  };

  return (
    <>
      {StartIcon ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "10px",
            borderRadius: "4px",
          }}
        >
          <StartIcon style={{ fontSize: "18px", color: "#939399" }} />
          <BaseTimePicker
            open={open}
            ampm={true}
            value={value}
            showTodayButton
            ampmInClock={true}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(date) =>
              onChange?.({ date: date ? `${date}` : "", error: false })
            }
            onAccept={(date) =>
              onChange?.({ date: date ? `${date}` : "", error: false })
            }
            onError={(reason, date) => {
              if (!reason) return;
              onChange?.({ date: date ? `${date}` : "", error: true });
            }}
            {...rest}
            renderInput={(params) => <Input {...params} {...InputProps} />}
            PopperProps={{ placement: "bottom-start", ...rest.PopperProps }}
          />
        </Box>
      ) : (
        <BaseTimePicker
          open={open}
          ampm={true}
          value={value}
          showTodayButton
          ampmInClock={true}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(date) =>
            onChange?.({ date: date ? `${date}` : "", error: false })
          }
          onAccept={(date) =>
            onChange?.({ date: date ? `${date}` : "", error: false })
          }
          onError={(reason, date) => {
            if (!reason) return;
            onChange?.({ date: date ? `${date}` : "", error: true });
          }}
          {...rest}
          renderInput={(params) => <Input {...params} {...InputProps} />}
          PopperProps={{ placement: "bottom-start", ...rest.PopperProps }}
        />
      )}
    </>
  );
}
