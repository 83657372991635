import Input from "../Input";
import { useState } from "react";
import { DatePickerProps } from ".";
import { DatePicker as BaseDatePicker } from "@mui/lab";
import { Box } from "@mui/material";

export default function DatePicker({
  onChange,
  editable,
  StartIcon,
  InputFieldProps,
  value: { date: value = "" },
  ...rest
}: DatePickerProps) {
  const [open, setOpen] = useState(false);
  const InputProps = {
    autoComplete: "off",
    id: "date",
    InputLabelProps: { shrink: true },
    ...InputFieldProps,
    onClick: () => setOpen(true),
    onKeyDown: (e: any) => {
      if (!editable) e.preventDefault();
    },
    error: InputFieldProps?.helperText ? InputFieldProps?.error : false,
  };
  const currentDate = (value: any) => {
    const today = new Date();
    const specificDate = new Date(value);
    const todaywithTime = new Date(today.getTime());
    const specificDateWithTime = new Date(specificDate.getTime());
    todaywithTime.setHours(0, 0, 0, 0);
    specificDateWithTime.setHours(0, 0, 0, 0);
    if (specificDateWithTime < todaywithTime) {
      onChange?.({ date: new Date(), error: false });
      return new Date();
    } else return value;
  };
  return (
    <>
      {StartIcon ? (
        <BaseDatePicker
          open={open}
          value={currentDate(value)}
          showTodayButton
          mask="__.__.____"
          inputFormat="dd.MM.yyyy"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disableCloseOnSelect={false}
          onChange={(date) =>
            onChange?.({ date: date ? `${date}` : "", error: false })
          }
          onAccept={(date) =>
            onChange?.({ date: date ? `${date}` : "", error: false })
          }
          onError={(reason, date) => {
            if (!reason) return;
            onChange?.({ date: date ? `${date}` : "", error: true });
          }}
          {...rest}
          renderInput={(params) => (
            <Input StartIcon={StartIcon} {...params} {...InputProps} />
          )}
          PopperProps={{ placement: "bottom-start", ...rest.PopperProps }}
        />
      ) : (
        <BaseDatePicker
          open={open}
          value={currentDate(value)}
          showTodayButton
          mask="__.__.____"
          inputFormat="dd.MM.yyyy"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disableCloseOnSelect={false}
          onChange={(date) =>
            onChange?.({ date: date ? `${date}` : "", error: false })
          }
          onAccept={(date) =>
            onChange?.({ date: date ? `${date}` : "", error: false })
          }
          onError={(reason, date) => {
            if (!reason) return;
            onChange?.({ date: date ? `${date}` : "", error: true });
          }}
          {...rest}
          renderInput={(params) => <Input {...params} {...InputProps} />}
          PopperProps={{ placement: "bottom-start", ...rest.PopperProps }}
        />
      )}
    </>
  );
}
