export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
	ADD_PAYMENT = "ADD_PAYMENT",
	ADD_STRIPE_PAYMENT = "ADD_STRIPE_PAYMENT",
	DELETE_USER = "DELETE_USER",
	APPLY_COUPON = "APPLY_COUPON",
	PAYMENT_CARDS = "PAYMENT_CARDS",
	CHILDREN_SEATS = "CHILDREN_SEATS",
	CANCEL_BOOKING = "CANCEL_BOOKING",
	UPDATE_PROFILE = "UPDATE_PROFILE",
	UPDATE_BOOKING = "UPDATE_BOOKING",
	BILLING_ADDRESS = "BILLING_ADDRESS",
	UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
	REPORT_A_PROBLEM = "REPORT_A_PROBLEM",
	CONFIRMATION_FORM = "CONFIRMATION_FORM",
	CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
	CONFIRM_CANCEL_BOOKING = "CONFIRM_CANCEL_BOOKING",
	EDIT_ADDRESS = "EDIT_ADDRESS",

}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
	data: any;
	width: any;
	type: ModalType;
	loading?: boolean;
}

export type ModalState = OpenModalState & {
	open: boolean;
};
