//@ts-nocheck
import { useEffect, useRef, useState } from "react";
import Input from "../Input";
import ToasterService from "utils/toaster.util";
import { AutoCompleteOwnProps } from ".";
import BookingService from "services/booking.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Box, Stack, Typography } from "@mui/material";
import MapsLoaderService from "services/map.loader.service";
import PlaneIcon from "assets/icons/plane.svg";
import LocationIcon from "assets/icons/loc.svg";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

declare global {
  interface Window {
    google: typeof google;
  }
}

export type AutoCompletesProps = AutoCompleteOwnProps &
  React.ComponentProps<typeof Input>;

export default function AutoComplete({
  val,
  value,
  handleChange,
  pickup,
  ...rest
}: AutoCompletesProps) {
  const { t, i18n } = useTranslation();
  const ref = useRef<HTMLInputElement | null>(null);
  const [suggestions, setSuggestions] = useState<
    //@ts-ignore
    google.maps.places.AutocompletePrediction[]
  >([]);
  const dispatch = useAppDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const user = useAppSelector((state) => state.auth.user);
  // const selectedForm = useAppSelector((state) => state.search.form);
  // const focusedInput = useAppSelector(
  //   (state) => state?.form?.[selectedForm]?.active
  // );
  const fetchPlaceDetails = (placeId: string) => {
    const container = document.createElement("div");
    const service = new window.google.maps.places.PlacesService(container);

    let componentForm: any = {
      country: "short_name",
    };

    service.getDetails(
      {
        placeId,
        fields: [
          "name",
          "formatted_address",
          "place_id",
          "types",
          "geometry",
          "address_components",
        ],
      },
      (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place
        ) {
          if (!place?.geometry?.location)
            return ToasterService.showError(
              ` No details available for input: '${place.name}'`
            );

          let airport = false;
          let city: any, route: any;
          const location = place.geometry.location;
          let country_name_code: string = "";

          const geometry = {
            location: { lat: location.lat(), lng: location.lng() },
          };

          for (const placeData of place.address_components) {
            let addressType = placeData.types[0];

            if (componentForm[addressType]) {
              country_name_code = placeData[componentForm["country"]];
            }
          }
          place.address_components.every(
            ({ types, long_name, short_name }: any) => {
              if (types.includes("route")) route = long_name;
              if (
                types.includes("locality") ||
                types.includes("administrative_area_level_1")
              )
                city = long_name;
              if (route && city) return false;
              return true;
            }
          );

          if (place.types.includes("airport")) airport = true;
          else if (place.name.toLowerCase().includes("airport")) airport = true;

          if (city === "Berlin") {
            if (!city)
              ToasterService.showError("Address does not contain city!");
            if (!route)
              ToasterService.showError("Address does not contain route!");
            if (place.types.includes("route"))
              ToasterService.showError(
                "Address does not contain street number!"
              );
          }

          const details = {
            ...place,
            city,
            route,
            airport,
            geometry,
            country_name_code,
          };

          // const details = {
          //   formatted_address: place.formatted_address,
          //   name: place.name,
          //   latitude: place.geometry?.location.lat(),
          //   longitude: place.geometry?.location.lng(),
          //   place_id: place.place_id,
          //   components: place.address_components.reduce((acc, component) => {
          //     const type = component.types[0];
          //     acc[type] = component.long_name;
          //     return acc;
          //   }, {}),
          // };
          handleChange({ value: place.formatted_address, details });
          BookingService.requestHistory({ ...details, user });
          // if (selectedForm && focusedInput) {
          // dispatch(searchActions.setShow(false));
          // dispatch(
          //   blur(selectedForm, focusedInput, {
          //     value: place.formatted_address,
          //     details,
          //   })
          // );
          // }
          setShowSuggestions(false);
        } else {
          ToasterService.showError("Failed to load place details.");
        }
      }
    );
  };

  const handleSelectSuggestion = (
    suggestion: google.maps.places.AutocompletePrediction
  ) => {
    fetchPlaceDetails(suggestion.place_id);
  };

  const handleInput = (value: string) => {
    if (!value) {
      setShowSuggestions(false);
      return;
    }
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: value }, (results, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        results
      ) {
        // Prioritize airports to the top
        const sortedResults = results.sort((a, b) =>
          a.types.includes("airport") === b.types.includes("airport")
            ? 0
            : a.types.includes("airport")
            ? -1
            : 1
        );
        setSuggestions(sortedResults);
        setShowSuggestions(true);
        setSelectedIndex(-1);
      }
    });
  };

  // const handleBlur = () => {
  //   setTimeout(() => {
  //     setShowSuggestions(false);
  //   }, 200);
  // };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!showSuggestions) return;

    if (e.key === "ArrowDown") {
      // Move down
      setSelectedIndex((prev) =>
        prev < suggestions.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === "ArrowUp") {
      // Move up
      setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === "Enter") {
      // Select current suggestion
      if (selectedIndex >= 0) {
        handleSelectSuggestion(suggestions[selectedIndex]);
        setShowSuggestions(false);
      }
    }
  };

  useEffect(() => {
    if (!MapsLoaderService.mapsLoaded) {
      MapsLoaderService.subscribe(() => setShowSuggestions(false));
    }
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <Input
        ref={ref}
        placeholder={t("enterLocation")}
        className="input-autocomplete"
        {...rest}
        value={val?.value || ""}
        label={val?.details?.name || rest.label}
        onChange={(e) => {
          handleChange({ value: e.target.value });
          handleInput(e.target.value);
        }}
        autoComplete="off"
        // onBlur={handleBlur}
        onKeyDown={handleKeyDown} // Handle keyboard navigation

        onBlur={() => {setIsFocused(false)}}
        onFocus={() => setIsFocused(true)}
        onKeyDown={handleKeyDown} // Handle keyboard navigation
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     e.preventDefault();
        //     setShowSuggestions(false);
        //   }
        // }}
      />
       {isFocused && (val || value) ? (
        <div
          onMouseDown={(e) => {
            e.preventDefault();
            handleChange("");
            handleInput("");
            ref.current?.focus();
          }}
          style={{
            paddingTop: "14px",
            paddingBottom: "10px",
            paddingLeft: "4px",
            paddingRight: "12px",
            borderRadius: "0 12px 12px 0",
            backgroundColor: "white",
            cursor: "pointer",
            position: "absolute",
            top: "1px",
            right: i18n.language === "en" ? "12px" : "auto",
            left: i18n.language === "ar" ? "12px" : "auto",
          }}
        >
          <Close
            style={{
              color: "#000000",
            }}
          />
        </div>
      ) : (
        ""
      )}
      {showSuggestions && (
        <Box
          top={{ xs: "60px", md: "68px" }}
          left={0}
          right={0}
          zIndex={999999}
          bgcolor={"#fff"}
          borderRadius={"16px"}
          position={"absolute"}
          p={{ xs: "4px 12px 16px 12px", md: "4px 16px 16px 16px" }}
          minWidth={{ xs: "auto", md: "360px" }}
          width={{ xs: "100%", md: "auto" }}
          boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.15)"
        >
          {suggestions.map((suggestion, index) => (
            <Box
              key={index}
              bgcolor={index === selectedIndex ? "#F2F2F7 !important" : ""}
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"12px"}
                sx={{ cursor: "pointer" }}
              >
                <Stack p={"6px"} borderRadius={"8px"}>
                  {suggestion.types.includes("airport") ? (
                    <img src={PlaneIcon} alt="Airport" width={"20px"} />
                  ) : (
                    <img src={LocationIcon} alt="Place" width={"18px"} />
                  )}
                </Stack>
                <Box
                  borderBottom={"1px solid #ebebeb"}
                  width={"100%"}
                  p="12px 0"
                  overflow={"hidden"}
                >
                  <Typography
                    component={"p"}
                    fontSize={"15px"}
                    color={"#000000"}
                    fontWeight={500}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    maxWidth={{ xs: "85%", md: "380px" }}
                    lineHeight={"normal"}
                  >
                    {suggestion?.structured_formatting?.main_text}
                  </Typography>
                  <Typography
                    component={"p"}
                    color={"#555555"}
                    fontSize={"13px"}
                    whiteSpace={"wrap"}
                    lineHeight={"normal"}
                    maxWidth={{ xs: "90%", md: "380px" }}
                  >
                    {suggestion?.structured_formatting?.secondary_text}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
}
