import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "./SideBar.scss";

import logo from "assets/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar, Stack } from "@mui/material";
import Button from "components/atoms/Button";
import { useAppSelector } from "redux/hooks";

import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import AuthService from "services/auth.service";
import { FaAngleDown, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import LanguageMenu from "components/atoms/BasicMenu/LanguageMenu";

export default function SideBar({
  state,
  toggleDrawer,
  englishLang,
  arabicLang,
}: any) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation");
  const [open, setOpen] = React.useState(false);
  const user = useAppSelector((state) => state.auth.user);

  const toggleOpen = () => {
    setOpen(!open);
  };
  const dropdownLinks = [
    {
      path: `/airport-transfers`,
      text: t("airportTransfers"),
    },
    {
      path: `/chauffeur-service`,
      text: t("chauffeurService"),
    },
    {
      path: `/limousine-service`,
      text: t("limousineService"),
    },
    {
      path: `/city-to-city-transfer`,
      text: t("City to City Transfer"),
    },
    { path: `/hire-by-the-hour`, text: t("hourlyBasis") },
    { path: `/event-transportation`, text: t("eventBasis") },
  ];

  const list = (state: any) => (
    <Box sx={{ width: "100%" }} role="presentation" onKeyDown={toggleDrawer}>
      <div className="sidebar__container">
        <div className="header">
          <Box display={"flex"}>
            <img src={logo} alt="" />
          </Box>
          <CloseIcon onClick={toggleDrawer} className="sideCross" />
        </div>
        <div className="side__links">
          <span className="dropdown link_side" onClick={toggleOpen}>
            {t("services")}
            {i18n.language === "ar" ? (
              <FaAngleLeft
                style={{
                  fontSize: "16px",
                  marginRight: "auto",
                }}
              />
            ) : (
              <FaAngleRight
                style={{
                  fontSize: "16px",
                  marginLeft: "auto",
                }}
              />
            )}
          </span>
          {open && (
            <Box px={2} bgcolor={"#ffffff"}>
              {dropdownLinks.map((service, index) => (
                <NavLink
                  key={index}
                  className="dropdown"
                  onClick={toggleDrawer}
                  to={service.path}
                >
                  {service.text}
                  {i18n.language === "ar" ? (
                    <FaAngleLeft
                      style={{
                        fontSize: "16px",
                        marginRight: "auto",
                      }}
                    />
                  ) : (
                    <FaAngleRight
                      style={{
                        fontSize: "16px",
                        marginLeft: "auto",
                      }}
                    />
                  )}
                </NavLink>
              ))}
            </Box>
          )}
          <NavLink className="dropdown" onClick={toggleDrawer} to={`/business`}>
            {t("business")}
            {i18n.language === "ar" ? (
              <FaAngleLeft
                style={{
                  fontSize: "16px",
                  marginRight: "auto",
                }}
              />
            ) : (
              <FaAngleRight
                style={{
                  fontSize: "16px",
                  marginLeft: "auto",
                }}
              />
            )}
          </NavLink>

          <NavLink
            className="dropdown"
            onClick={toggleDrawer}
            to={`/global-reach`}
          >
            {t("GlobalReach")}
            {i18n.language === "ar" ? (
              <FaAngleLeft
                style={{
                  fontSize: "16px",
                  marginRight: "auto",
                }}
              />
            ) : (
              <FaAngleRight
                style={{
                  fontSize: "16px",
                  marginLeft: "auto",
                }}
              />
            )}
          </NavLink>

          {user && (
            <>
              <NavLink
                className="dropdown"
                onClick={toggleDrawer}
                to={`/profile`}
              >
                {t("profile")}
                <FaAngleRight
                  style={{
                    marginLeft: i18n.language === "ar" ? 0 : "auto",
                    marginRight: i18n.language === "ar" ? "auto" : 0,
                    transform: i18n.language === "ar" ? "rotate(180deg)" : "",
                    fontSize: "16px",
                  }}
                />
              </NavLink>
              <NavLink
                className="dropdown"
                onClick={toggleDrawer}
                to={`/bookings`}
              >
                {t("bookings")}
                <FaAngleRight
                  style={{
                    marginLeft: i18n.language === "ar" ? 0 : "auto",
                    marginRight: i18n.language === "ar" ? "auto" : 0,
                    transform: i18n.language === "ar" ? "rotate(180deg)" : "",
                    fontSize: "16px",
                  }}
                />
              </NavLink>
              <NavLink
                className="dropdown"
                onClick={toggleDrawer}
                to={`/billing`}
              >
                {t("billingAddress")}
                <FaAngleRight
                  style={{
                    marginLeft: i18n.language === "ar" ? 0 : "auto",
                    marginRight: i18n.language === "ar" ? "auto" : 0,
                    transform: i18n.language === "ar" ? "rotate(180deg)" : "",
                    fontSize: "16px",
                  }}
                />
              </NavLink>
              <NavLink
                className="dropdown"
                onClick={toggleDrawer}
                to={`/wallet`}
              >
                {t("wallet")}
                <FaAngleRight
                  style={{
                    marginLeft: i18n.language === "ar" ? 0 : "auto",
                    marginRight: i18n.language === "ar" ? "auto" : 0,
                    transform: i18n.language === "ar" ? "rotate(180deg)" : "",
                    fontSize: "16px",
                  }}
                />
              </NavLink>
            </>
          )}
          <LanguageMenu
            list={[
              {
                text: "EN",
                onClick: englishLang,
              },
              {
                text: "عربي",
                onClick: arabicLang,
              },
            ]}
            avatar={false}
            color="#520A76"
          >
            <Stack py={2.5} direction="row" alignItems="center" columnGap={1}>
              <span>{i18n.language === "en" ? "EN" : "عربي"}</span>
              <FaAngleDown />
            </Stack>
          </LanguageMenu>
        </div>

        {
          <Stack style={{ padding: "30px 10px" }} spacing={1}>
            {!user ? (
              <>
                <Button
                  onClick={() => {
                    navigate(`/login`);
                    toggleDrawer();
                  }}
                  variant="outlined"
                  color="info"
                >
                  {t("login")}
                </Button>
                <Button
                  onClick={() => {
                    navigate(`/signup`);
                    toggleDrawer();
                  }}
                  variant="contained"
                  color="info"
                >
                  {t("signup")}
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                style={{ boxShadow: "none" }}
                variant="contained"
                onClick={() => {
                  AuthService.logout();
                  toggleDrawer();
                }}
                color="info"
              >
                {t("logout")}
              </Button>
            )}
          </Stack>
        }
      </div>
    </Box>
  );

  return (
    <div>
      <>
        <Drawer anchor="top" open={state} onClose={toggleDrawer}>
          {list(state)}
        </Drawer>
      </>
    </div>
  );
}
