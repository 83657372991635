import { useState, useCallback } from "react";
import GoogleMapReact from "google-map-react";
import MapService from "services/map.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { mapAction } from "redux/slices/map/mapSlice";
import ClearIcon from "@mui/icons-material/Clear";
import { change } from "redux-form";
import { IoMdPin } from "react-icons/io";
import { useTranslation } from "react-i18next";

const MapWithPin = () => {
  const form = "PickUpForm";
  const { t } = useTranslation("translation");

  const dispatch = useAppDispatch();
  const [finalAddress, setFinalAddress] = useState<any>(null);

  const [mapApi, setMapApi] = useState<any>(null);

  const picklatlong = useAppSelector(
    (state) => state.form?.[form]?.values?.pickup?.details?.geometry?.location
  );

  const destinationlatlong = useAppSelector(
    (state) =>
      state.form?.[form]?.values?.destination?.details?.geometry?.location
  );

  let endLat = useAppSelector((state) => state.map.endLat);
  let endlng = useAppSelector((state) => state.map.endlng);

  const show = useAppSelector((state) => state.map.show);

  const isSmallScreen = window.innerWidth < 768;

  const handleApiLoaded = useCallback((map: any, maps: any) => {
    setMapApi(map);
  }, []);
  const handleDrag = useCallback(async () => {
    if (mapApi) {
      const center = mapApi.getCenter();
      const newAddress: any = await MapService.getAddressFromLatLng(
        center.lat(),
        center.lng()
      );

      let airport = false;
      let city: any, route: any;
      const location = newAddress.geometry.location;
      const geometry = {
        location: { lat: location.lat(), lng: location.lng() },
      };

      newAddress.address_components.every(({ types, long_name }: any) => {
        if (types.includes("route")) route = long_name;
        if (types.includes("locality")) city = long_name;
        if (route && city) return false;
        return true;
      });

      if (newAddress.types.includes("airport")) airport = true;

      let startTitle = newAddress?.formatted_address
        ?.split(/[،,]+/)
        .slice(0, 2)
        .join(",");

      const details = {
        ...newAddress,
        city,
        route,
        airport,
        geometry,
        name: startTitle,
      };
      let mainAddress = { value: newAddress.formatted_address, details };
      setFinalAddress(mainAddress);
    }
  }, [mapApi]);

  const setLocationInForm = () => {
    console.log("Show", show);
    
    if (show === 0) {
      dispatch(
        mapAction.setStartLocation(finalAddress.details.geometry.location)
      );
      dispatch(mapAction.setStartFormatted(finalAddress));
      dispatch(change(form, "pickup", finalAddress));
    } else {
      dispatch(
        mapAction.setEndLocation(finalAddress.details.geometry.location)
      );
      dispatch(mapAction.setdestinationMap(false));

      dispatch(mapAction.setEndFormatted(finalAddress));
      dispatch(change(form, "destination", finalAddress));
    }
    dispatch(mapAction.setOpen(false));
  };

  return (
    <div
      style={{
        borderRadius: "12px",
        overflow: "hidden",
        height: "340px",
        width: "100%",
        position: "relative",
        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.04)",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "25px",
          width: "100%",
          zIndex: "200",
        }}
      >
        <ClearIcon
          onClick={() => {
            dispatch(mapAction.setOpen(false));
            dispatch(mapAction.setdestinationMap(false));
          }}
          style={{
            display: "flex",
            cursor: "pointer",
            margin: "0 auto",
            background: "#520a76",
            color: "white",
            borderRadius: "99px",
            boxShadow: "0px 6px 18px rgb(0 0 0 / 62%)",
            width: "25px",
            height: "25px",
            padding: "3px",
          }}
        />
      </div>
      <button
        onClick={setLocationInForm}
        className="choseLocation"
        style={{
          position: "absolute",
          bottom: "10px",
          width: "100%",
          zIndex: "200",
        }}
        disabled={finalAddress ? false : true}
      >
        {t("moveMap")}
      </button>

      <IoMdPin
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          zIndex: 99,
          fontSize: "48px",
          color: "rgb(82, 10, 118)",
        }}
      />

      <GoogleMapReact
        defaultCenter={
          show === 0
            ? {
                lat: picklatlong?.lat ? picklatlong?.lat : 24.7191924,
                lng: picklatlong?.lng ? picklatlong?.lng : 46.6722224,
              }
            : {
                lat: destinationlatlong?.lat ? destinationlatlong?.lat : endLat,
                lng: destinationlatlong?.lng ? destinationlatlong?.lng : endlng,
              }
        }
        options={{
          gestureHandling: isSmallScreen ? "greedy" : "auto",
        }}
        defaultZoom={13}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onDragEnd={handleDrag}
        onZoomAnimationEnd={handleDrag}
      ></GoogleMapReact>
    </div>
  );
};
export default MapWithPin;
