import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ServiceHeader from "assets/services/event-basis-banner.webp";
import Button from "components/atoms/Button";
import PickUp from "components/templates/PickUp";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { bookingActions } from "redux/slices/booking";
import { mapAction } from "redux/slices/map/mapSlice";

import appstoreEn from "assets/app-store--en.svg";
import appstoreAr from "assets/app-store--ar.svg";
import playStoreEn from "assets/google-play--en.svg";
import playstoreAr from "assets/google-play--ar.svg";

import appStoreDarkEn from "assets/app-store-dark--en.svg";
import appStoreDarkAr from "assets/app-store-dark--ar.svg";
import playStoreDarkEn from "assets/google-play-dark--en.svg";
import playStoreDarkAr from "assets/google-play-dark--ar.svg";

import EventA from "assets/services/eventA.webp";
import EventB from "assets/services/eventB.webp";
import EventC from "assets/services/hourC.webp";

import PersonIcon from "assets/icons/seats.svg";
import BagIcon from "assets/icons/luggage.svg";

import ClassA from "assets/fleet/business-class.png";
import ClassB from "assets/fleet/electric-class.png";
import ClassC from "assets/fleet/premium-class.png";
import ClassD from "assets/fleet/first-class.png";
import ClassE from "assets/fleet/van-class.png";
import ClassF from "assets/fleet/business-class-suv.png";
import ClassG from "assets/fleet/first-class-suv.png";

import "../../servicepage.scss";
import SwiperSlider from "components/atoms/SwiperSlider";
import { SwiperSlide } from "swiper/react";

import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { Helmet } from "react-helmet";

const EventTransportation = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation("");
  const ref = useRef<HTMLInputElement>(null);
  const tab = useAppSelector((state) => state.booking.tab);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (i18n.language === "ar" || pathname.includes("/ar")) {
      navigate("/النقل-الفاخر-للفعاليات");
    }
  }, [i18n.language, navigate, pathname]);
  
  const cars = [
    {
      img: ClassB,
      name: t("electricClass"),
      // link: "/business-class",
      link: "/",
      person: 3,
      bags: 2,
    },
    {
      img: ClassC,
      name: t("premiumClass"),
      // link: "/premium-class",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassA,
      name: t("businessClass"),
      // link: "/business-class",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassE,
      name: t("vanClass"),
      // link: "/van-class",
      link: "/",
      person: 6,
      bags: 4,
    },
    {
      img: ClassF,
      name: t("suvClass"),
      // link: "/suv-class",
      link: "/",
      person: 6,
      bags: 3,
    },
    {
      img: ClassD,
      name: t("firstClass"),
      // link: "/first-class",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassG,
      name: t("firstClassSuv"),
      // link: "/luxury-class",
      link: "/",
      person: 6,
      bags: 5,
    },
  ];
  const faqs = [
    {
      question: t("What types of vehicles are available for special events?"),
      answer: t(
        "What types of vehicles are available for special events Answer"
      ),
    },
    {
      question: t("How do I contact my chauffeur on the day of the event?"),
      answer: t(
        "How do I contact my chauffeur on the day of the event? Answer"
      ),
    },
  ];
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Helmet>
        <title>Event transportation | Yelo Plus</title>
        <meta name="robots" content="index, follow" />
        <meta name="title" content="Event transportation | Yelo Plus" />
        <meta
          name="description"
          content="Arrive in style with Yelo Plus event transportation luxury,
          punctuality, and seamless booking for weddings, galas, and corporate
          events."
        />
        <link
          rel="canonical"
          href="https://limousine.iyelo.com/en/event-transportation"
        />
      </Helmet>
      <div className="services__container">
        <Container maxWidth="lg">
          <Box
            className="services__main"
            sx={{
              background: {
                xs: "none",
                md: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 38.36%, rgba(0, 0, 0, 0.00) 50%),center center / cover url(${ServiceHeader}) no-repeat`,
              },
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                minHeight: "53.5vh",
                position: "relative",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="travel__style_heading">
                  <h1 className="heading">
                    <span style={{ color: "#FFD207" }}>{t("luxury")}</span>{" "}
                    {t("eventBasis")}
                  </h1>
                  <p className="sub-heading">
                    {t("eventBasisDescription")}
                  </p>
                </div>
                <div className="travel__style">
                  <div className="booking__box">
                    <div
                      className={
                        i18n.language === "en" ? "bottom" : "bottom arBottom"
                      }
                    >
                      <PickUp />
                    </div>
                    <div ref={ref}></div>

                    <Stack
                      direction="row"
                      maxWidth={{ xs: "100%", md: "fit-content" }}
                      mt={"12px"}
                      borderRadius={{ xs: "12px 12px 0 0", md: "12px" }}
                      overflow={"hidden"}
                    >
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(0));
                        }}
                        sx={{
                          height: "44px",
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          width: { xs: "100%", md: "fit-content" },
                          borderRadius: "0",
                          color:
                            tab === 0
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 0
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 0
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        {t("transfer")}
                      </Button>

                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(1));
                          dispatch(mapAction.setOpen(false));
                          // dispatch(change(form, "destination", ""));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 1
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 1
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 1
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                        // color={tab === 1 ? "info" : "secondary"}
                      >
                        {t("hourly")}
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(2));
                          dispatch(mapAction.setOpen(false));
                          // dispatch(change(form, "destination", ""));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 2
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 2
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 2
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                        // color={tab === 2 ? "info" : "secondary"}
                      >
                        {t("citytoCity")}
                      </Button>
                    </Stack>
                  </div>
                </div>
              </div>

              <Stack
                direction={"row"}
                gap={2}
                justifyContent={{
                  xs: "center",
                  md: i18n.language === "ar" ? "flex-end" : "start",
                }}
                mt={4}
              >
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://apps.apple.com/us/app/yelo-limousine/6446365805"
                    target="_blank"
                  >
                    {i18n.language === "en" ? (
                      <>
                        <Box
                          component={"img"}
                          display={{ xs: "none", md: "block" }}
                          src={appstoreEn}
                          alt="App Store"
                        />
                        <Box
                          component={"img"}
                          display={{ xs: "block", md: "none" }}
                          src={appStoreDarkEn}
                          alt="App Store"
                        />
                      </>
                    ) : (
                      <>
                        <Box
                          component={"img"}
                          display={{ xs: "none", md: "block" }}
                          src={appstoreAr}
                          alt="App Store"
                        />
                        <Box
                          component={"img"}
                          display={{ xs: "block", md: "none" }}
                          src={appStoreDarkAr}
                          alt="App Store"
                        />
                      </>
                    )}
                  </Link>
                </Box>
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.wefaq.limousine"
                    target="_blank"
                  >
                    {i18n.language === "en" ? (
                      <>
                        <Box
                          component={"img"}
                          display={{ xs: "none", md: "block" }}
                          src={playStoreEn}
                          alt="Play Store"
                        />
                        <Box
                          component={"img"}
                          display={{ xs: "block", md: "none" }}
                          src={playStoreDarkEn}
                          alt="Play Store"
                        />
                      </>
                    ) : (
                      <>
                        <Box
                          component={"img"}
                          display={{ xs: "none", md: "block" }}
                          src={playstoreAr}
                          alt="Play Store"
                        />
                        <Box
                          component={"img"}
                          display={{ xs: "block", md: "none" }}
                          src={playStoreDarkAr}
                          alt="Play Store"
                        />
                      </>
                    )}
                  </Link>
                </Box>
              </Stack>
            </div>
          </Box>

          <Box mt={{ xs: "60px", md: "80px" }}>
            <Grid
              container
              columnSpacing={4}
              rowSpacing={{ xs: "60px", md: "80px" }}
              alignItems={"center"}
            >
              <Grid item xs={12} md={6} order={{ xs: 0, md: 0 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    {t("Premium event transportation services")}
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    {t("Premium event transportation services description")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${EventA})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} order={{ xs: 3, md: 2 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${EventB})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 2, md: 3 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    {t("Luxurious special event transportation options")}
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    {t(
                      "Luxurious special event transportation options description"
                    )}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} order={{ xs: 4, md: 4 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    {t("Seamless booking process")}
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    {t("Seamless booking process description")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 5, md: 5 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${EventC})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box mt={{ xs: "60px", md: "80px" }}>
          <SwiperSlider
            name="fleet"
            heading={t("discoverCars")}
            slidesPerViews={5}
            showNavigation={true}
          >
            {cars.map((data, index) => (
              <SwiperSlide key={index} style={{ width: "auto" }}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "16px",
                    overflow: "hidden",
                    p: { xs: "12px", md: "24px" },
                    background:
                    "linear-gradient(180.00deg, rgb(249, 237, 255),rgb(255, 255, 255) 100%)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "16px",
                    height: { xs: 200, md: 300 },
                  }}
                >
                  <Box
                    component="img"
                    src={data.img}
                    alt={data.name}
                    sx={{
                      width: "100%",
                      height: { xs: "120px", md: "200px" },
                      objectFit: "contain",
                    }}
                  />
                  <Box pt={{ xs: 0, md: 3 }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: { xs: "14px", sm: "18px" },
                        lineHeight: { xs: "18px", sm: "24px" },
                        letterSpacing: "0.09px",
                        color: "#520a76",
                      }}
                    >
                      {data.name}
                    </Typography>
                    <Stack direction={"row"} gap={1.5} mt={2}>
                      <Stack gap={"4px"} direction={"row"} alignItems={"end"}>
                        <img
                          loading="lazy"
                          src={PersonIcon}
                          alt=""
                          width={"18px"}
                        />
                        <Typography
                          lineHeight={1}
                          fontWeight={300}
                          fontSize={{ xs: "12px", sm: "14px" }}
                        >
                          {t("max")}&nbsp;
                          {data.person}
                        </Typography>
                      </Stack>
                      <Stack gap={"4px"} direction={"row"} alignItems={"end"}>
                        <img
                          loading="lazy"
                          src={BagIcon}
                          alt=""
                          width={"18px"}
                        />
                        <Typography
                          lineHeight={1}
                          fontWeight={300}
                          fontSize={{ xs: "12px", sm: "14px" }}
                        >
                          {t("max")}&nbsp;
                          {data.bags}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </SwiperSlider>
        </Box>

        <Container maxWidth="lg">
          <Box mt={{ xs: "60px", md: "80px" }}>
            <Typography
              variant="h4"
              sx={{
                color: "#520a76",
                fontSize: { xs: 24, md: 32 },
                fontWeight: "bold",
                lineHeight: { xs: "32px", md: "48px" },
                letterSpacing: "0.165px",
                mb: { xs: 2, md: 4 },
              }}
            >
              {t("Frequently Asked Question")}
            </Typography>

            <Grid container spacing={2}>
              {faqs.map((faq, index) => (
                <Grid item md={6}>
                  <Accordion
                    key={index}
                    sx={{
                      background: "transparent",
                      border: "transparent",
                      borderRadius: "16px !important",
                      backgroundColor: "#F9F9F9",
                      boxShadow: "none",
                      "&.Mui-expanded": {
                        marginBottom: 0,
                        marginTop: 0,
                      },
                      "& .MuiPaper-root": {
                        marginBottom: 0,
                        marginTop: 0,
                      },
                    }}
                    expanded={expanded === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={
                        i18n.language === "en" ? (
                          <ArrowRight
                            style={{
                              transform:
                                expanded === `panel${index}`
                                  ? "rotate(-90deg)"
                                  : "rotate(0deg)",
                              transition: "transform 0.3s",
                              fontSize: "24px",
                            }}
                          />
                        ) : (
                          <ArrowLeft
                            style={{
                              transform:
                                expanded === `panel${index}`
                                  ? "rotate(-270deg)"
                                  : "rotate(0deg)",
                              transition: "transform 0.3s",
                              fontSize: "24px",
                            }}
                          />
                        )
                      }
                      sx={{
                        p: 3,
                        borderRadius: "16px",
                        marginBottom: 0,
                        "& .MuiAccordionSummary-content": {
                          my: "0 !important",
                        },
                        "&.Mui-expanded": { my: 0, minHeight: "auto" },
                        minHeight: "auto",
                      }}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: 16,
                          fontWeight: 600,
                          letterSpacing: "0.17px",
                        }}
                      >
                        {t(faq.question)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ pt: 0, px: 3, pb: 3 }}>
                      <Typography>{t(faq.answer)}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default EventTransportation;
