import { config } from "config";

class MapsLoaderService {
  private static susbcribers: any = [];
  static loadingScript: boolean = false;
  static mapsLoaded: boolean = false;
  static emitMapsLoadEvent() {
    MapsLoaderService.susbcribers.forEach((f: any) => {
      f();
    });
    MapsLoaderService.susbcribers = [];
    MapsLoaderService.mapsLoaded = true;
  }
  static subscribe(f: Function) {
    MapsLoaderService.susbcribers.push(f);
  }
  static LoadMaps() {
    MapsLoaderService.LoadScript(
      `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_MAPS_API_KEY}&libraries=places`,
      MapsLoaderService.emitMapsLoadEvent
    );
  }
  static LoadScripts() {
    if (!MapsLoaderService.loadingScript) {
      MapsLoaderService.loadingScript = true;
      MapsLoaderService.LoadMaps();
    }
  }
  private static LoadScript(url: string, onLoad: any = null) {
    const script = document.createElement("script");
    script.src = url;
    script.defer = true;
  
    if (onLoad) {
      script.onload = onLoad;
    }
    document.head.appendChild(script);
  }

  private static LoadInnerScript(url: string, onLoad: any = null) {
    const script = document.createElement("script");
    script.innerHTML = url;
    script.defer = true;
  

    if (onLoad) {
      script.onload = onLoad;
    }
    document.head.appendChild(script);
  }
}

export default MapsLoaderService;
