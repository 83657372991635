import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "../Button";
import Avatar from "@mui/material/Avatar";
import { useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import { ListItem } from "@mui/material";

export default function BasicMenu({ children, list, avatar, color }: any) {
  const user = useAppSelector((state) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { i18n } = useTranslation();

  const handleClose = () => setAnchorEl(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {avatar ? (
        <Button
          // id="basic-button"
          aria-label="menu"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : "false"}
          onClick={handleClick}
          variant="text"
          sx={{
            color: "#ffffff",
            fontSize: "18px",
            textTransform: "capitalize",
            lineHeight: "1",
            verticalAlign: "unset",
            minWidth: "initial",
            padding: "0",
            // "&:hover": {
            // 	color: "#f5d312",
            // },
          }}
        >
          {user.profile_pic ? (
            <div className="profile-img">
              <img
                loading="lazy"
                width={"42px"}
                height={"42px"}
                src={user.profile_pic}
                style={{ padding: "0" }}
                alt={`${user.first_name} ${user.last_name}`}
              />
            </div>
          ) : (
            <Avatar
              sx={{
                backgroundColor: "#fafafa",
                color: "#000000",
                fontSize: "16px",
              }}
            >
              {children}
            </Avatar>
          )}
        </Button>
      ) : (
        <Button
          // id="basic-button"
          aria-label="menu"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : "false"}
          onClick={handleClick}
          variant="text"
          sx={{
            color: color,
            fontSize: "18px",
            textTransform: "capitalize",
            lineHeight: "1",
            verticalAlign: "unset",
            minWidth: "initial",
            padding: "0",
            // "&:hover": {
            // 	color: "#f5d312",
            // },
          }}
        >
          {children}
        </Button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "none",
            border: "1px solid #ECEBF2",
            borderRadius: "16px",
            mt: 1.5,
          },
        }}
        transformOrigin={{
          horizontal: i18n.language === "ar" ? "right" : "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: i18n.language === "ar" ? "right" : "left",
          vertical: "bottom",
        }}
      >
        {list.map((item: any, index: any) => (
          <ListItem
            sx={{ py: 0 }}
            onClick={() => {
              item.onClick?.();
              handleClose();
            }}
            key={index}
          >
            <span className="links"> {item.text}</span>
          </ListItem>
        ))}
      </Menu>
    </div>
  );
}
