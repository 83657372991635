import "./AuthForm.css";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import CircleLoader from "components/atoms/CircleLoader";
import { useTranslation } from "react-i18next";

export default function AuthForm({ children }: any) {
  const { pathname } = useLocation();
  const { t } = useTranslation("translation");

  let email = localStorage.getItem("email");
  const loading = useAppSelector((state) => state.auth.loading);
  const showSignup = useAppSelector((state) => state.auth.showSignup);

  return (
    <div
      className={`${
        pathname.includes("search-form")
          ? "login-container-search"
          : "login-container"
      }`}
    >
      <Container maxWidth="sm">
        <div className={"wrap-login"}>
          {loading && <CircleLoader />}

          <span className="login-form-title">
            {pathname.includes("login") ? (
              <>{t("login")}</>
            ) : pathname.includes("search-form") ? (
              <> {showSignup ? t("signup") : t("login")}</>
            ) : pathname.includes("signup") ? (
              <>{t("signup")}</>
            ) : pathname.includes("forgot-password") ? (
              <b> {t("forgotPassword")}!</b>
            ) : pathname.includes("select-otp-option") ? (
              <b> {t("varificationCode")}!</b>
            ) : pathname.includes("password-otp") ? (
              <b> {t("varificationCode")}</b>
            ) : pathname.includes("reset-password") ? (
              <b> {t("resetPass")}!</b>
            ) : null}
          </span>
          <span className="login-form-slogan">
            {pathname.includes("login") ? (
              <>{t("Log in to access your account")}</>
            ) : pathname.includes("search-form") ? (
              <>
                {" "}
                {showSignup
                  ? t("Create an account to continue")
                  : t("Log in to access your account")}
              </>
            ) : pathname.includes("signup") ? (
              <>{t("Create an account to continue")}</>
            ) : pathname.includes("forgot-password") ? (
              <>{t("getVarificationCode")}</>
            ) : pathname.includes("select-otp-option") ? (
              <>{t("selectOneToGet")}</>
            ) : pathname.includes("password-otp") ? (
              <>
                {" "}
                {t("sentVarificationCode")}
                {email}
              </>
            ) : pathname.includes("reset-password") ? (
              <> {t("newPass")}</>
            ) : null}
          </span>
          <div className="login-form-inner">{children}</div>
        </div>
      </Container>
    </div>
  );
}
