import { Helmet } from "react-helmet";
export function getRandomInt(min = 1, max = 1000) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const LeadingPage = () => {
  return (
    <Helmet>
      <script>
        {`
        gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-9598208/yelo24/yelol0+standard'
        });
        `}
      </script>
      <noscript>
        {`
    <img src="https://ad.doubleclick.net/ddm/activity/src=9598208;type=yelo24;cat=yelol0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${getRandomInt()};gdpr_consent=${getRandomInt()};ord=[${getRandomInt()}]?" width="1" height="1" alt=""/>
    `}
      </noscript>
    </Helmet>
  );
};

export default LeadingPage;
