import React, { useCallback, useEffect } from "react";
import { Box, Container, Stack, Typography, Link, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import appStoreDarkEn from "assets/app-store-dark--en.svg";
import appStoreDarkAr from "assets/app-store-dark--ar.svg";
import playStoreDarkEn from "assets/google-play-dark--en.svg";
import playStoreDarkAr from "assets/google-play-dark--ar.svg";
import { ReactComponent as Facebook } from "assets/face-book.svg";
import { ReactComponent as Twitter } from "assets/Twitter.svg";
import { ReactComponent as LinkedIn } from "assets/Linkedin.svg";
// import { ReactComponent as YouTube } from "assets/youtube.svg";
import yeloPlus from "assets/yelo-plus.svg";
import { BsInstagram } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";

const Footer = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation("translation");

  const changeLanguage = useCallback(
    (lng: any) => {
      i18n.changeLanguage(lng);
    },
    [i18n]
  );
  const footerSections = [
    {
      title: t("company"),
      links: [
        { label: t("contactUs"), href: `/contactUs` },
        { label: t("careers"), href: "https://www.iyelo.com/en/careers" },
        { label: t("footerBusiness"), href: `/business` },
      ],
    },
    // {
    //   title: t("ourFleet"),
    //   links: [
    //     { label: t("businessVanClass"), href: "/van-class" },
    //     { label: t("business"), href: "/business-class" },
    //     { label: t("Business2Class"), href: "/business2-class" },
    //     { label: t("firstClass"), href: "/first-class" },
    //     { label: t("luxurySuv"), href: "/luxury-class" },
    //     { label: t("premiumClass"), href: "/premium-class" },
    //     { label: t("suvClass"), href: "/suv-class" },
    //   ],
    // },
    {
      title: t("usefulLinks"),
      links: [
        { label: t("privacy"), href: `/privacy` },
        { label: t("terms"), href: `/terms-and-conditions` },
        { label: t("cookies"), href: "https://www.iyelo.com/en/cookie-policy" },
      ],
    },
    {
      title: t("ourServicesinRiyadh"),
      links: [
        { label: t("airportTransferRiyadh"), href: "#" },
        { label: t("limousineServiceinRiyadh"), href: "#" },
        { label: t("rentaCarWithDriverinRiyadh"), href: "#" },
        { label: t("chauffeurServiceinRiyadh"), href: "#" },
        { label: t("eventTransportationinRiyadh"), href: "#" },
        { label: t("premiumCarServicesinRiyadh"), href: "#" },
      ],
    },
  ];

  const socialLinks = [
    {
      label: "Instagram",
      icon: <BsInstagram style={{ width: "22px", height: "22px" }} />,
      color: "#ffffff",
      bgColor: "#520a76",
      link: "https://www.instagram.com/iyeloplus/",
    },
    {
      label: "Facebook",
      icon: <Facebook />,
      color: "#ffffff",
      link: "https://www.facebook.com/iyeloplus",
    },
    {
      label: "Twitter",
      icon: <Twitter />,
      color: "#ffffff",
      link: "https://x.com/iyeloplus",
    },
    {
      label: "LinkedIn",
      icon: <LinkedIn />,
      color: "#ffffff",
      link: "https://www.linkedin.com/company/iyeloplus/",
    },
    {
      label: "Tiktok",
      icon: <FaTiktok style={{ width: "22px", height: "22px" }} />,
      color: "#ffffff",
      bgColor: "#520a76",
      link: "https://www.tiktok.com/@iyeloplus",
    },
  ];

  const arabicLang = useCallback(() => {
    localStorage.setItem("lang", "ar");
    changeLanguage("ar");
    document.body.style.direction = "rtl";
    document.body.style.fontFamily = "arabic";
  }, [changeLanguage]);

  const englishLang = useCallback(() => {
    localStorage.setItem("lang", "en");
    changeLanguage("en");
    document.body.style.direction = "ltr";
    document.body.style.fontFamily = "arabic";
  }, [changeLanguage]);

  useEffect(() => {
    if (i18n.language === "ar") {
      arabicLang();
    } else {
      englishLang();
    }
  }, [arabicLang, englishLang, i18n.language]);

  if (
    pathname.includes("login") ||
    pathname.includes("signup") ||
    pathname.includes("password-otp") ||
    pathname.includes("select-otp-option") ||
    pathname.includes("reset-password") ||
    pathname.includes("payment-form") ||
    pathname.includes("forgot-password")
  )
    return <></>;

  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          component="footer"
          sx={{
            pt: { xs: 3, md: 8 },
            pb: { xs: 2, md: 5 },
          }}
        >
          {/* <Stack
            direction={{ md: "row", sm: "column" }}
            gap={{ xs: 2, md: 4 }}
            justifyContent="space-between"
            alignItems="center"
            sx={{ pb: 5, borderBottom: "1px solid", borderColor: "divider" }}
          >
            <Box sx={{ maxWidth: "240px", width: "100%" }}>
              <img
                src={Logo}
                alt="logo"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Stack> */}

          {/* Main Links Section */}
          <Box py={5} sx={{ borderTop: "1px solid", borderColor: "divider" }}>
            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12} md={9}>
                <Grid container spacing={{ xs: 3, md: 3 }}>
                  {footerSections.map((section, index) => (
                    <Grid
                      item
                      xs={index < 1 ? 6 : 12}
                      sm={6}
                      md={index < 1 ? 3 : 4}
                      key={index}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            mb: 3,
                            color: "#520a76",
                            fontWeight: 700,
                            lineHeight: "24px",
                            letterSpacing: "0.9px",
                          }}
                        >
                          {section.title}
                        </Typography>
                        <Stack gap={1}>
                          {section.links.map((link, idx) => (
                            <Link
                              key={idx}
                              href={link.href}
                              underline="hover"
                              sx={{
                                color: "#0A0A0E",
                                fontSize: "16px",
                                lineHeight: "32px",
                                letterSpacing: "0.17px",
                              }}
                            >
                              {link.label}
                            </Link>
                          ))}
                        </Stack>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {/* Connect With Us section */}
              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      mb: 3,
                      color: "#520a76",
                      fontWeight: 700,
                      lineHeight: "24px",
                      letterSpacing: "0.9px",
                    }}
                  >
                    {t("connectWithUs")}
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        // color: "#520a76",
                        fontSize: "14px",
                        letterSpacing: "0.17px",
                      }}
                    >
                      {t("shareExperience")}
                    </Typography>
                    <Box
                      mt={2}
                      sx={{
                        width: "100%",
                        maxWidth: { xs: "64px", md: "140px" },
                      }}
                    >
                      <img
                        src={yeloPlus}
                        alt="logo"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Box>
                  </Box>

                  <Box sx={{ flexGrow: 1 }} />
                  <Box>
                    <Stack direction="row" spacing={2} mt={2}>
                      <Box borderRadius={"7px"}>
                        <Link
                          href="https://apps.apple.com/us/app/yelo-limousine/6446365805"
                          target="_blank"
                        >
                          {i18n.language === "en" ? (
                            <img
                              src={appStoreDarkEn}
                              alt="Download on the App Store"
                              width={135}
                              height={40}
                            />
                          ) : (
                            <img
                              src={appStoreDarkAr}
                              alt="Download on the App Store"
                              width={135}
                              height={40}
                            />
                          )}
                        </Link>
                      </Box>
                      <Box borderRadius={"7px"}>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.wefaq.limousine"
                          target="_blank"
                        >
                          {i18n.language === "en" ? (
                            <img
                              src={playStoreDarkEn}
                              alt="Get it on Google Play"
                              width={135}
                              height={40}
                            />
                          ) : (
                            <img
                              src={playStoreDarkAr}
                              alt="Download on the App Store"
                              width={135}
                              height={40}
                            />
                          )}
                        </Link>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      mt={2}
                      // justifyContent={"space-between"}
                      sx={{ alignItems: "center" }}
                    >
                      {socialLinks.map((social, idx) => (
                        <Link
                          href={social.link}
                          target="_blank"
                          key={idx}
                          aria-label={social.label}
                          sx={{
                            borderRadius: "4px",
                            padding: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            bgcolor: social.bgColor,
                            color: social.color,
                            // "&:hover": { bgcolor: "action.hover" },
                          }}
                        >
                          {social.icon}
                        </Link>
                      ))}
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Copyright Section */}
          <Typography
            align="center"
            sx={{
              pt: 5,
              borderTop: "1px solid",
              borderColor: "divider",
              color: "#0A0A0E",
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t("allRights")}
            {/* <Box
              sx={{
                display: "inline-block",
                minWidth: "54px",
                maxWidth: "54px",
                ml: 1,
              }}
            >
              <img
                src={yeloPlus}
                alt="yeloPlus"
                style={{ width: "100%", height: "auto" }}
              />
            </Box> */}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
