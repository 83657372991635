import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Modal,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import AccountHeader from "components/templates/AccountHeader";
import BookingService from "services/booking.service";
import { useEffect, useState } from "react";
import ClassImg from "assets/liked3.png";
import FromToIcon from "assets/icons/from-to.svg";
import DurationIcon from "assets/icons/duration.svg";
import EditIcon from "assets/icons/edit.svg";
import Time from "assets/icons/Time";
import Date from "assets/icons/Date";
import PersonIcon from "assets/icons/seats.svg";
import BagIcon from "assets/icons/luggage.svg";
import ProfileFill from "assets/icons/profile-icon.svg";
import DateService from "utils/date.util";
import Chip from "components/atoms/Chip";
import { MODAL, modalActions } from "redux/slices/modal";
import ObjectService from "utils/object.util";
import Button from "components/atoms/Button";
import ReportAProblem from "components/templates/AppModal/ReportAProblem";
import Close from "@mui/icons-material/Close";

export default function BookingDetails() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const booking = useAppSelector((state) => state.booking.booking);
  const loading = useAppSelector((state) => state.auth.loading);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    BookingService.getBookingById(id);
  }, []);

  return (
    <Container maxWidth="lg">
      <Stack direction={"row"}>
        <Box width={"100%"} pt={{ xs: "16px", md: "40px" }}>
          <AccountHeader
            back={true}
            link={`/bookings`}
            heading={"bookingId"}
            subHeading={booking?.booking_number}
          />
          <Grid container columnSpacing={8}>
            <Grid item md={7} xs={12}>
              <Stack gap={2}>
                <Box
                  mt={{ xs: 3, md: 8 }}
                  p="16px"
                  position={"relative"}
                  borderRadius={"12px"}
                  border={"1px solid #E4E3EB"}
                  boxShadow={"0px 8px 32px 0px rgba(42, 52, 86, 0.06)"}
                >
                  <Stack gap={2} position={"relative"}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box
                          px={1}
                          height={"100%"}
                          display={"flex"}
                          alignItems={"center"}
                          bgcolor={"#F2F2F7"}
                          borderRadius={"12px"}
                          textAlign={"center"}
                          justifyContent={"center"}
                        >
                          <img
                            src={booking?.class_details?.image || ClassImg}
                            alt=""
                            style={{
                              margin: "auto",
                              verticalAlign: "middle",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} position={"relative"}>
                        <Typography
                          fontSize={"16px"}
                          color={"#0A0A0E"}
                          textTransform={"uppercase"}
                          mb={0.5}
                          fontWeight={600}
                        >
                          {booking?.class_details?.name}
                        </Typography>
                        <Typography
                          fontSize={"14px"}
                          fontWeight={300}
                          color={"#939399"}
                        >
                          {booking?.class_details?.text}
                        </Typography>
                        <Stack gap={1.5} mt={2}>
                          <Stack gap={"4px"} direction={"row"}>
                            <img loading="lazy" src={PersonIcon} alt="" />
                            <Typography fontWeight={300}>
                              {t("max")}&nbsp;
                              {booking?.class_details?.max_persons || 2}
                            </Typography>
                          </Stack>
                          <Stack gap={"4px"} direction={"row"}>
                            <img loading="lazy" src={BagIcon} alt="" />
                            <Typography fontWeight={300}>
                              {t("max")}&nbsp;
                              {booking?.class_details?.max_bags || 2}
                            </Typography>
                          </Stack>
                        </Stack>
                        {booking?.additional_info?.pickup_sign && (
                          <Box mt={2}>
                            <Typography color={"#939399"} fontWeight={300}>
                              {t("pickUpSign")}
                            </Typography>
                            <Typography fontWeight={500}>
                              {booking?.additional_info?.pickup_sign}
                            </Typography>
                          </Box>
                        )}
                        <Box
                          position={"absolute"}
                          right={"12px"}
                          bottom={"6px"}
                        >
                          <Chip status={booking?.current_status} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
                <Box
                  p="16px"
                  position={"relative"}
                  borderRadius={"12px"}
                  border={"1px solid #E4E3EB"}
                  boxShadow={"0px 8px 32px 0px rgba(42, 52, 86, 0.06)"}
                >
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid item xs={5}>
                      <Typography
                        fontSize={{ xs: "16px", sm: "22px" }}
                        fontWeight={{ xs: 600, md: 700 }}
                        // textOverflow={"ellipsis"}
                        // whiteSpace={"nowrap"}
                        // maxWidth={"fit-content"}
                        // overflow={"hidden"}
                      >
                        {booking?.pickup?.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={"center"}>
                      {booking?.trip_type === "hourly" ? (
                        <img src={DurationIcon} alt="" />
                      ) : (
                        <img
                          src={FromToIcon}
                          alt=""
                          style={{
                            transform:
                              i18n.language === "ar"
                                ? "rotate(180deg)"
                                : "rotate(0)",
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        fontSize={{ xs: "16px", sm: "22px" }}
                        fontWeight={{ xs: 600, md: 700 }}
                        textAlign={"right"}
                        ml={"auto"}
                        // textOverflow={"ellipsis"}
                        // whiteSpace={"nowrap"}
                        // maxWidth={"fit-content"}
                        // overflow={"hidden"}
                      >
                        {booking?.trip_type === "hourly"
                          ? `${booking?.duration} ${t("hours")}`
                          : booking?.destination?.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    mt={1}
                    columnSpacing={0.5}
                  >
                    <Grid item xs={6}>
                      <Typography
                        color={"#939399"}
                        fontSize={"14px"}
                        fontWeight={300}
                        // textOverflow={"ellipsis"}
                        // whiteSpace={"nowrap"}
                        // maxWidth={"fit-content"}
                        // overflow={"hidden"}
                      >
                        {booking?.pickup?.address}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={"right"}>
                      <Typography
                        color={"#939399"}
                        fontSize={"14px"}
                        fontWeight={300}
                        // textOverflow={"ellipsis"}
                        // whiteSpace={"nowrap"}
                        // maxWidth={"fit-content"}
                        // overflow={"hidden"}
                        // marginLeft={"auto"}
                      >
                        {booking?.trip_type === "hourly"
                          ? t("Duration")
                          : booking?.destination?.address}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={
                      booking?.current_status === "posted" ||
                      booking?.current_status === "accepted"
                        ? 5
                        : 6
                    }
                  >
                    <Box
                      p="16px"
                      position={"relative"}
                      borderRadius={"12px"}
                      border={"1px solid #E4E3EB"}
                      boxShadow={"0px 8px 32px 0px rgba(42, 52, 86, 0.06)"}
                    >
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Date />
                        {DateService.getShortDateString(booking?.date)}
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={
                      booking?.current_status === "posted" ||
                      booking?.current_status === "accepted"
                        ? 5
                        : 6
                    }
                  >
                    <Box
                      p="16px"
                      position={"relative"}
                      borderRadius={"12px"}
                      border={"1px solid #E4E3EB"}
                      boxShadow={"0px 8px 32px 0px rgba(42, 52, 86, 0.06)"}
                    >
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Time />
                        {DateService.getTimeString(booking?.date)}
                      </Stack>
                    </Box>
                  </Grid>
                  {(booking?.current_status === "posted" ||
                    booking?.current_status === "accepted") && (
                    <Grid item xs={12} sm={2}>
                      <Box
                        p="17px"
                        color={"#520A76"}
                        textAlign={"center"}
                        borderRadius={"12px"}
                        border={"1px solid #520A76"}
                        boxShadow={"0px 8px 32px 0px rgba(42, 52, 86, 0.06)"}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          dispatch(
                            modalActions.openModal({
                              data: { ...booking },
                              width: "400px",
                              type: MODAL.UPDATE_BOOKING,
                            })
                          )
                        }
                      >
                        {t("edit")}
                        {/* <img src={EditIcon} alt="" /> */}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={5} xs={12}>
              <Stack gap={2} mt={8}>
                {booking?.driver_details &&
                  booking?.current_status !== "cancelled" && (
                    <Box
                      p="16px"
                      position={"relative"}
                      borderRadius={"12px"}
                      border={"1px solid #E4E3EB"}
                      boxShadow={"0px 8px 32px 0px rgba(42, 52, 86, 0.06)"}
                    >
                      <Stack
                        pb={1.5}
                        direction={"row"}
                        gap={1.5}
                        alignItems={"center"}
                        borderBottom={"1px solid #ECEBF2"}
                      >
                        <img
                          src={
                            booking?.driver_details?.profile_pic?.url ||
                            ProfileFill
                          }
                          alt=""
                          style={{
                            maxWidth: "42px",
                          }}
                        />
                        <Box>
                          <Typography fontWeight={700} mb={1}>
                            {ObjectService.getFullName(
                              booking?.driver_details,
                              false
                            )}
                          </Typography>
                          <Stack
                            alignItems={"center"}
                            gap={1.5}
                            direction={"row"}
                          >
                            <Typography
                              color={"#939399"}
                              fontSize={"12px"}
                              fontWeight={300}
                              sx={{ direction: "initial !important" }}
                            >
                              {booking?.driver_details?.phone}
                            </Typography>
                            {booking.vehicle_details?.license_plate && (
                              <>
                                <Typography color={"#939399"}>|</Typography>
                                <Typography
                                  color={"#939399"}
                                  fontSize={"12px"}
                                  fontWeight={300}
                                  textTransform={"uppercase"}
                                >
                                  {booking.vehicle_details?.license_plate}
                                </Typography>
                              </>
                            )}
                          </Stack>
                        </Box>
                      </Stack>
                      <Stack
                        pt={1}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        direction={"row"}
                        gap={1}
                      >
                        {booking?.driver_id &&
                          booking?.current_status !== "cancelled" && (
                            <Rating
                              readOnly
                              precision={0.1}
                              name="read-only"
                              value={booking?.driver_details?.ratings}
                            />
                          )}
                        <Button
                          variant="outlined"
                          onClick={() => setOpen(true)}
                        >
                          {t("reportProblem")}
                        </Button>
                      </Stack>
                    </Box>
                  )}
                <Box
                  bgcolor={"#ffffff"}
                  borderRadius={"12px"}
                  padding={"16px"}
                  border={"1px solid #E4E3EB"}
                  boxShadow={"0px 8px 32px 0px rgba(42, 52, 86, 0.06)"}
                >
                  <Typography
                    mb={1.5}
                    fontSize={"16px"}
                    fontWeight={700}
                    textTransform={"uppercase"}
                  >
                    {t("summary")}
                  </Typography>

                  <Stack gap={1}>
                    {booking?.price_details.netto_price > 0 && (
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        gap={1}
                      >
                        <Typography fontSize={"14px"} fontWeight={300}>
                          {t("netPrices")}
                        </Typography>
                        <Typography fontSize={"14px"} fontWeight={600}>
                          {booking?.price_details.netto_price.toFixed?.(2)}{" "}
                          {booking?.price_details.currency_sign}
                        </Typography>
                      </Stack>
                    )}
                    {booking?.price_details.VAT > 0 && (
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        gap={1}
                      >
                        <Typography fontSize={"14px"} fontWeight={300}>
                          {t("bookingVat")} 15%
                          {/* {booking?.price_details.VAT}% */}
                        </Typography>
                        <Typography fontSize={"14px"} fontWeight={600}>
                          {booking?.price_details.VAT.toFixed?.(2)}{" "}
                          {booking?.price_details.currency_sign}
                        </Typography>
                      </Stack>
                    )}
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      gap={1}
                      pt={1}
                      mt={1}
                      borderTop={"1px solid #ECEBF2"}
                    >
                      <Typography
                        fontSize={"14px"}
                        fontWeight={300}
                        color={"#520A76"}
                      >
                        {t("total")} {t("price")}
                      </Typography>
                      <Typography
                        fontSize={{ xs: "18px", md: "24px" }}
                        fontWeight={700}
                        color={"#520A76"}
                      >
                        {booking?.price.toFixed?.(2)}{" "}
                        {booking?.price_details.currency_sign}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>

                {(booking?.current_status === "posted" ||
                  booking?.current_status === "accepted" ||
                  booking?.current_status === "ongoing" ||
                  booking?.current_status === "arrived") && (
                  <Button
                    fullWidth
                    color="error"
                    variant="outlined"
                    sx={{
                      height: "50px",
                      borderRadius: "12px",
                      marginTop: "16px",
                    }}
                    onClick={() =>
                      dispatch(
                        modalActions.openModal({
                          width: "400px",
                          type: MODAL.CONFIRMATION_FORM,
                          data: {
                            id: booking._id,
                            type: MODAL.CANCEL_BOOKING,
                            heading: t("cancelBooking"),
                            message: t("confirmCancel"),
                          },
                        })
                      )
                    }
                  >
                    {t("cancelBooking")}
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") setOpen(false);
        }}
      >
        <Box
          sx={{
            width: "80%",
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
            maxWidth: "500px",
          }}
        >
          <Close
            onClick={() => setOpen(false)}
            style={{
              position: "absolute",
              top: "15px",
              right: i18n.language === "ar" ? "auto" : "16px",
              left: i18n.language === "ar" ? "16px" : "auto",
              cursor: "pointer",
            }}
          />
          <ReportAProblem setOpen={setOpen} />
        </Box>
      </Modal>
    </Container>
  );
}
