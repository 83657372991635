import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ServiceHeader from "assets/services/airport-transfers-banner.webp";
import Button from "components/atoms/Button";
import PickUp from "components/templates/PickUp";
import { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { bookingActions } from "redux/slices/booking";
import { mapAction } from "redux/slices/map/mapSlice";
import appstoreAr from "assets/app-store--ar.svg";
import playstoreAr from "assets/google-play--ar.svg";
import appStoreDarkAr from "assets/app-store-dark--ar.svg";
import playStoreDarkAr from "assets/google-play-dark--ar.svg";

import complimentaryServicesA from "assets/services/airport1.svg";
import complimentaryServicesB from "assets/services/airport2.svg";
import complimentaryServicesC from "assets/services/airport3.svg";

import AirportA from "assets/services/airportA.webp";
import AirportB from "assets/services/airportB.webp";
import AirportC from "assets/services/airportC.webp";

import PersonIcon from "assets/icons/seats.svg";
import BagIcon from "assets/icons/luggage.svg";

import ClassA from "assets/fleet/business-class.png";
import ClassB from "assets/fleet/electric-class.png";
import ClassC from "assets/fleet/premium-class.png";
import ClassD from "assets/fleet/first-class.png";
import ClassE from "assets/fleet/van-class.png";
import ClassF from "assets/fleet/business-class-suv.png";
import ClassG from "assets/fleet/first-class-suv.png";

import "../../servicepage.scss";
import SwiperSlider from "components/atoms/SwiperSlider";
import { SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const AirportTransfers = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const tab = useAppSelector((state) => state.booking.tab);

  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
    if (i18n.language === "en" || pathname.includes("/en")) {
      console.log("Here", i18n.language, pathname);

      navigate("/airport-transfers");
    }
  }, [i18n.language, navigate, pathname]);

  const complimentaryServices = [
    {
      title: "أسعار ثابتة وشاملة",
      content:
        "تجنب الأسعار المرتفعة والتكاليف غير المتوقعة مع خدمة النقل من وإلى المطار من يلو بلس. لا داعي للقلق بشأن تقلبات الأسعار أو التكاليف غير المتوقعة!نقدم لك أسعارًا ثابتة وشاملة يتم تحديدها مسبقًا عند الحجز، لتنعم براحة البال وتخطط لميزانيتك بكل سهولة.",
      image: complimentaryServicesA,
    },
    {
      title: "سائقون محترفون",
      content:
        "استمتع برحلة آمنة ومريحة مع سائقينا المحترفين ، الذين يتمتعون بخبرة واسعة في القيادة والتعامل مع عملائنا. سائقونا ملتزمون بتوفير أعلى معايير الخدمة والاحترافية، لضمان وصولك إلى وجهتك بكل أمان وراحة.",
      image: complimentaryServicesB,
    },
    {
      title: "استقبال وترحيب مميز",
      content:
        "تجنب عناء البحث عن سيارة أجرة عند الوصول. نقدم لك استقبال وترحيب مميز، حيث يكون فى استقبالك سائق محترف داخل المطار يساعدك في حمل  أمتعتك وإرشادك إلى سيارتك. وتوفير كل ما تحتاجه لرحلة مريحة وممتعة.",
      image: complimentaryServicesC,
    },
  ];

  const cars = [
    {
      img: ClassB,
      name: "الفئة الكهربائية",
      link: "/",
      person: 3,
      bags: 2,
    },
    {
      img: ClassC,
      name: "الفئة المميزة",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassA,
      name: "فئة رجال الأعمال",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassE,
      name: "فئة الفان",
      link: "/",
      person: 6,
      bags: 4,
    },
    {
      img: ClassF,
      name: "فئة SUV",
      link: "/",
      person: 6,
      bags: 3,
    },
    {
      img: ClassD,
      name: "الفئة الأولى",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassG,
      name: "الفئة الأولى SUV",
      link: "/",
      person: 6,
      bags: 5,
    },
  ];

  return (
    <>
      <Helmet>
        <title>خدمة النقل من وإلى المطار | يلو بلس</title>
        <meta name="robots" content="index, follow" />
        <meta name="title" content="خدمة النقل من وإلى المطار | يلو بلس" />
        <meta
          name="description"
          content="استمتع برحلة خالية من المتاعب مع خدمة النقل من وإلى المطار من يلو بلس بأسعار ثابتة وسائقين محترفين وخدمة استقبال وترحيب مميزة ومركبات فاخرة لتجربة سفر سلسة."
        />
        <link
          rel="canonical"
          href="https://limousine.iyelo.com/ar/النقل-من-وإلى-المطار"
        />
      </Helmet>
      <div className="services__container">
        <Container maxWidth="lg">
          <Box
            className="services__main"
            sx={{
              background: {
                xs: "none",
                md: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 38.36%, rgba(0, 0, 0, 0.00) 50%),center center / cover url(${ServiceHeader}) no-repeat`,
              },
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                minHeight: "53.5vh",
                position: "relative",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="travel__style_heading">
                  <h1 className="heading">
                    <span style={{ color: "#FFD207" }}>فاخرة</span> خدمة النقل
                    من وإلى المطار
                  </h1>
                  <p className="sub-heading">
                    نقدم لك خدمة نقل فاخرة من وإلى المطار مع سائقين محترفين
                    وأسطول من السيارات الفاخرة
                  </p>
                </div>
                <div className="travel__style">
                  <div className="booking__box">
                    <div className="bottom arBottom">
                      <PickUp />
                    </div>
                    <div ref={ref}></div>

                    <Stack
                      direction="row"
                      maxWidth={{ xs: "100%", md: "fit-content" }}
                      mt={"12px"}
                      borderRadius={{ xs: "12px 12px 0 0", md: "12px" }}
                      overflow={"hidden"}
                    >
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(0));
                        }}
                        sx={{
                          height: "44px",
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          width: { xs: "100%", md: "fit-content" },
                          borderRadius: "0",
                          color:
                            tab === 0
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 0
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 0
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        نقل
                      </Button>

                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(1));
                          dispatch(mapAction.setOpen(false));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 1
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 1
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 1
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        بالساعة
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(2));
                          dispatch(mapAction.setOpen(false));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 2
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 2
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 2
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        بين المدن
                      </Button>
                    </Stack>
                  </div>
                </div>
              </div>

              <Stack
                direction={"row"}
                gap={2}
                justifyContent={{
                  xs: "center",
                  md: "flex-end",
                }}
                mt={4}
              >
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://apps.apple.com/us/app/yelo-limousine/6446365805"
                    target="_blank"
                  >
                    <Box
                      component={"img"}
                      display={{ xs: "none", md: "block" }}
                      src={appstoreAr}
                      alt="App Store"
                    />
                    <Box
                      component={"img"}
                      display={{ xs: "block", md: "none" }}
                      src={appStoreDarkAr}
                      alt="App Store"
                    />
                  </Link>
                </Box>
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.wefaq.limousine"
                    target="_blank"
                  >
                    <Box
                      component={"img"}
                      display={{ xs: "none", md: "block" }}
                      src={playstoreAr}
                      alt="Play Store"
                    />
                    <Box
                      component={"img"}
                      display={{ xs: "block", md: "none" }}
                      src={playStoreDarkAr}
                      alt="Play Store"
                    />
                  </Link>
                </Box>
              </Stack>
            </div>
          </Box>

          <Box mt={{ xs: "60px", md: "80px" }}>
            <Grid container spacing={{ xs: 2, md: 0 }}>
              {complimentaryServices.map((complimentaryService, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card
                    sx={{
                      height: { md: "100%" },
                      px: { xs: 3, md: 4 },
                      py: { xs: 3, md: 0 },
                      boxShadow: "none",
                      borderRadius: { xs: "16px", md: 0 },
                      borderLeft: { md: "1px solid #ECEBF2" },
                      borderRight: {
                        md: index === 2 ? "1px solid #ECEBF2" : 0,
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        p: "0 !important",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <img
                        src={complimentaryService.image || "/placeholder.svg"}
                        alt={complimentaryService.title}
                        style={{ maxWidth: "68px" }}
                      />
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Typography
                          variant="h6"
                          component="h3"
                          fontSize={16}
                          fontWeight={700}
                          color={"#520A76"}
                          letterSpacing={"0.14px"}
                          textOverflow={"ellipsis"}
                        >
                          {complimentaryService.title}
                        </Typography>
                        <Typography variant="body2" fontSize={16}>
                          {complimentaryService.content}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box mt={{ xs: "60px", md: "80px" }}>
            <Grid
              container
              columnSpacing={4}
              rowSpacing={{ xs: "60px", md: "80px" }}
              alignItems={"center"}
            >
              <Grid item xs={12} md={6} order={{ xs: 0, md: 0 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    تجربة سفر فاخرة ومريحة
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    استمتع بتجربة سفر فاخرة ومريحة مع خدمات النقل من وإلى المطار
                    من يلو بلس.
                    <br /> سواء كنت تصل إلى مطار جدة أو أي مطار رئيسي آخر في
                    المملكة العربية السعودية أو الأردن أو الإمارات العربية
                    المتحدة، فإن أسطولنا من المركبات المُختارة بعناية فى انتظارك
                    لمساعدتك وراحتك. سائقونا المحترفون جاهزون لإرشادك عند وصولك
                    المطار ونقلك بسلاسة إلى وجهتك التالية.
                    <br /> نقدم أيضًا خدمة تأجير السيارات في مطار جدة، مما يوفر
                    لك مركبة فاخرة مصممة وفقًا لاحتياجاتك لتجربة سلسة ومريحة في
                    كل مرة.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${AirportA})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} order={{ xs: 3, md: 2 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${AirportB})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 2, md: 3 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    رحلة سفر من الدرجة الأولى
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    تقدم يلو بلس خدمة فاخرة عند وصولك المطار تضمن أعلى معايير
                    الراحة والكفاءة. سواء كنت تصل إلى مطار الرياض في رحلة عمل أو
                    عائدًا إلى الوطن بعد عطلة، تضمن لك خدمة تأجير السيارات في
                    مطار الرياض تجربة سلسة تلبى جميع احتياجاتك .
                    <br /> مع خيارات تتراوح بين المركبات الفاخرة للمسافرين
                    المنفردين إلى الحافلات الواسعة للعائلات أو الزملاء، تلتزم
                    يلو بتقديم خدمات نقل المطار بالليموزين بسلاسة وبدون متاعب ،
                    تتوفر لدينا مركبات فاخرة من الدرجة الأولى وخدمة مميزة لتجربة
                    أفضل خدمة نقل فاخرة على الإطلاق.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} order={{ xs: 4, md: 4 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    عملية حجز سريعة وسهلة
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    حجز خدمة النقل من وإلى المطار مع يلو بلس سريع وسهل، ومُصمم
                    خصيصاً لراحتك.
                    <br /> سواء كنت تفضل الحجز عبر الإنترنت من خلال منصتنا سهلة
                    الاستخدام أو عبر تطبيقنا الذكي، فإن حجز مركبتك يستغرق بضع
                    خطوات بسيطة فقط.
                    <br />
                    ما عليك سوى إدخال تواريخ سفرك، واختيار نوع المركبة التي ترغب
                    بها من مجموعة واسعة من الخيارات، وتأكيد تفاصيل الدفع.
                    <br />
                    نحن نفخر بالشفافية لذا فإن السعر الذي تراه هو السعر النهائي
                    لرحلتك بلا أي رسوم خفية!
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 5, md: 5 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${AirportC})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box mt={{ xs: "60px", md: "80px" }}>
          <SwiperSlider
            name="fleet"
            heading="اكتشف مجموعتنا المميزة من السيارات الفاخرة"
            slidesPerViews={5}
            showNavigation={true}
          >
            {cars.map((data, index) => (
              <SwiperSlide key={index} style={{ width: "auto" }}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "16px",
                    overflow: "hidden",
                    p: { xs: "12px", md: "24px" },
                    background:
                      "linear-gradient(180.00deg, rgb(249, 237, 255),rgb(255, 255, 255) 100%)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "16px",
                    height: { xs: 200, md: 300 },
                  }}
                >
                  <Box
                    component="img"
                    src={data.img}
                    alt={data.name}
                    sx={{
                      width: "100%",
                      height: { xs: "120px", md: "200px" },
                      objectFit: "contain",
                    }}
                  />
                  <Box pt={{ xs: 0, md: 3 }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: { xs: "14px", sm: "18px" },
                        lineHeight: { xs: "18px", sm: "24px" },
                        letterSpacing: "0.09px",
                        color: "#520a76",
                      }}
                    >
                      {data.name}
                    </Typography>
                    <Stack direction={"row"} gap={1.5} mt={2}>
                      <Stack gap={"4px"} direction={"row"} alignItems={"end"}>
                        <img
                          loading="lazy"
                          src={PersonIcon || "/placeholder.svg"}
                          alt=""
                          width={"18px"}
                        />
                        <Typography
                          lineHeight={1}
                          fontWeight={300}
                          fontSize={{ xs: "12px", sm: "14px" }}
                        >
                          حد أقصى&nbsp;
                          {data.person}
                        </Typography>
                      </Stack>
                      <Stack gap={"4px"} direction={"row"} alignItems={"end"}>
                        <img
                          loading="lazy"
                          src={BagIcon || "/placeholder.svg"}
                          alt=""
                          width={"18px"}
                        />
                        <Typography
                          lineHeight={1}
                          fontWeight={300}
                          fontSize={{ xs: "12px", sm: "14px" }}
                        >
                          حد أقصى&nbsp;
                          {data.bags}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </SwiperSlider>
        </Box>
      </div>
    </>
  );
};

export default AirportTransfers;
