import { ClassesState } from ".";
import { createSlice } from "@reduxjs/toolkit";
import LocalStorage from "utils/localstorage.util";

const classsPrice: any = LocalStorage.getItem("classsPrice");
const classesPrices: any = LocalStorage.getItem("classesPrices");

const initialState: ClassesState = {
  classesPrices: classesPrices || [],
  classsPrice: classsPrice || null,
};

export const classSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    setClasssPrice: (state, action) => {
      const classsPrice = action.payload;
      state.classsPrice = classsPrice;
      LocalStorage.setItem("classsPrice", classsPrice);
    },
    setClassesPrices: (state, action) => {
      const classesPrices = action.payload;
      let classsPrice;
      let prevClasssPrice = LocalStorage.getItem("classsPrice");

      if (prevClasssPrice) {
        classesPrices.every((el: any) => {
          if (el.class_id === prevClasssPrice.class_id) {
            classsPrice = el;
            return false;
          }
          return true;
        });
      }

      state.classsPrice = classsPrice;
      state.classesPrices = classesPrices;

      LocalStorage.setItem("classsPrice", classsPrice);
      LocalStorage.setItem("classesPrices", classesPrices);
    },
  },
});

const classReducer = classSlice.reducer;

export const classActions = classSlice.actions;
export default classReducer;
