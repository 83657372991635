import { makeStyles } from "@mui/styles";
import "./Chip.css";

const useStyles: any = makeStyles((theme: any) => ({
  primary: {
    color: theme.palette.contrastText,
    backgroundColor: theme.palette.primary.light,
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
  },
  info: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.light,
  },
  warning: {
    color: theme.palette.warning.main.contrastText,
    backgroundColor: theme.palette.warning.light,
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
}));

export default function Chip({ status }: any) {
  const classes = useStyles();

  return (
    <span
      className={`class-badge ${
        status === "arrived" ||
        status === "active" ||
        status === "approved" ||
        status === "completed" ||
        status === "paid" ||
        status === "finished"
          ? classes.success
          : status === "On the way" ||
            status === "On hold" ||
            status === "ongoing" ||
            status === "started"
          ? classes.info
          : status === "accepted"
          ? classes.primary
          : status === "rejected" || status === "cancelled"
          ? classes.error
          : classes.warning
      }`}
    >
      {status}
    </span>
  );
}
