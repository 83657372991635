import { BookingState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: BookingState = {
  tab: 0,
  bookings: [],
  booking: null,
  loading: false,
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setBooking: (state, action: PayloadAction<any>) => {
      state.booking = action.payload;
    },
    addBooking: (state, action) => {
      state.bookings.unshift(action.payload);
    },
    updateBooking: (state, action) => {
      const booking = action.payload;
      state.booking = booking;
      // state.bookings.every(({ _id }, i) => {
      //   if (booking._id === _id) {
      //     state.bookings[i] = booking;
      //     return false;
      //   }
      //   return true;
      // });
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
  },
});

const bookingReducer = bookingSlice.reducer;

export const bookingActions = bookingSlice.actions;
export default bookingReducer;
