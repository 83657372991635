import PickUpForm from "./PickUpForm";
import BookingService from "services/booking.service";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import LocalStorage from "utils/localstorage.util";
import { format } from "date-fns";
import ToasterService from "utils/toaster.util";
import { classActions } from "redux/slices/class";
import { destroy } from "redux-form";
import CircleLoader from "components/atoms/CircleLoader";
import { useTranslation } from "react-i18next";
import { SearchNow, SearchNowBtn } from "GoogleSnippet/SearchButton";

export default function PickUp() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation("translation");
  const user = useAppSelector((state) => state.auth.user);
  const { loading, tab } = useAppSelector((state) => state.booking);

  const handleSubmit = async (values: any) => {
    SearchNowBtn();
    let historyPayload = { ...values };
    const data = { ...values };
    const date = new Date(data.date.date).toDateString();
    // const time = new Date(data.time.date).toTimeString();
    const time = data.time;

    historyPayload.date = date;
    historyPayload.time = time;

    data.time_stamp = format(
      new Date(`${date} ${time}`),
      "yyyy-MM-dd HH:mm:ss"
    );

    const splitDate = data.time_stamp.split(" ");
    data.time_stamp = `${splitDate[0]}T${splitDate[1]}.000Z`;

    data.trip_type = "hourly";
    data.pickup_type = data.pickup?.details?.airport ? "airport" : "place";

    data.city_name = [data.pickup?.details?.city];
    const { location } = data.pickup.details?.geometry;
    data.coordinates = [[location.lng, location.lat]];
    data.pickupCoordinates = [location.lat, location.lng];
    historyPayload.city = data.pickup?.details?.city;
    historyPayload.pickup = data.pickup.details?.name + " " + data.pickup.value;
    if (data.pickup) data.pickup = data.pickup.value;
    if (tab === 0 || tab === 2) {
      historyPayload.destination =
        data.destination.details?.name + " " + data.destination.value;
      const locationDistination = data.destination.details?.geometry?.location;
      data.coordinates.push([locationDistination.lng, locationDistination.lat]);
      data.destinationCoordinates = [
        locationDistination.lat,
        locationDistination.lng,
      ];
      data.trip_type = tab === 2 ? "city_trip" : "trip";
      data.city_name.push(data.destination.details?.city);
      data.destination = data.destination.value;
      historyPayload.city = values.destination.details?.city;
      historyPayload.lat = values.destination.details?.geometry?.location?.lat;
      historyPayload.lng = values.destination.details?.geometry?.location?.lng;
      data.pickup_type = data.pickup_type == "airport" ? "airport" : values?.destination?.details?.airport ? "airport" : "place";
      delete historyPayload.duration
      delete data.duration

    } else {
      data.duration = Number(data.duration);
      historyPayload.destination = Number(data.duration);
      delete data.destination;
    }

    if (data.pickup === data.destination)
      return ToasterService.showError(t("pick_desination_must_be_different"));

    delete data.date;
    delete data.time;
    const [success, error]: any = await BookingService.getBookingPrices(data);

    if (success) {
      const { classes, isStripe,aggregator } = success.data.data;

      historyPayload.classes = classes;

      if (classes.length === 0)
        ToasterService.showError(`No classes in ${data.city_name[0]}`);
      else {
        localStorage.removeItem("SearchForm");
        LocalStorage.setItem("BookingFormPayload", { ...data, isStripe, aggregator });

        dispatch(destroy("SearchForm"));
        dispatch(classActions.setClassesPrices(classes));

        navigate(`/search-form`);
      }
    }

    historyPayload.error = error?.response?.data?.message;
    historyPayload.userID = user ? user._id : "";
    BookingService.bookingHistory(historyPayload);
  };

  return (
    <div>
      <SearchNow />
      {loading && <CircleLoader />}
      <PickUpForm onSubmit={handleSubmit} />
    </div>
  );
}
