import type React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ServiceHeader from "assets/services/trip-basis-banner.webp";
import Button from "components/atoms/Button";
import PickUp from "components/templates/PickUp";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { bookingActions } from "redux/slices/booking";
import { mapAction } from "redux/slices/map/mapSlice";

import appstoreAr from "assets/app-store--ar.svg";
import playstoreAr from "assets/google-play--ar.svg";
import appStoreDarkAr from "assets/app-store-dark--ar.svg";
import playStoreDarkAr from "assets/google-play-dark--ar.svg";

import complimentaryServicesA from "assets/services/city1.svg";
import complimentaryServicesB from "assets/services/city2.svg";
import complimentaryServicesC from "assets/services/city3.svg";

import City from "assets/services/cityA.webp";

import CityA from "assets/cities/riyadh.webp";
import CityB from "assets/cities/bahrain2.webp";
import CityC from "assets/cities/qassim.webp";
import CityD from "assets/cities/Damman2.webp";
import CityE from "assets/cities/al-jubail.webp";
import CityF from "assets/cities/jeddah.webp";
import CityG from "assets/cities/madina.webp";
import CityH from "assets/cities/makkah.webp";
import CityJ from "assets/cities/alula.webp";

import "../../servicepage.scss";
import SwiperSlider from "components/atoms/SwiperSlider";
import { SwiperSlide } from "swiper/react";

import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const CityToCityTransfer = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const tab = useAppSelector((state) => state.booking.tab);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
    if (i18n.language === "en" || pathname.includes("/en")) {
      navigate("/city-to-city-transfer");
    }
  }, [i18n.language, navigate, pathname]);

  const complimentaryServices = [
    {
      title: "رحلات بين المدن بلا عناء",
      content:
        "التنقل بين المدن أصبح الآن أسهل من أي وقت مضى. تقدم يلو بلس خدمة خدمة السفر من مدينة الى أخرى بطريقة سلسة ومريحة ، مما يمنحك حرية السفر وفقًا لشروطك. على عكس خيارات النقل الأخرى، تم تصميم خدمة السفر بين المدن لدينا لجعل رحلتك خالية من التعب والارهاق.",
      image: complimentaryServicesA,
    },
    {
      title: "استمتع براحة النقل بين المدن",
      content:
        "لماذا تختار يلو بلس؟ لأنها ستوفر وقتك مع خدمة النقل من الباب إلى الباب. لن تحتاج بعد الآن للوقوف في طوابير طويلة، أو التنقل بين وسائل نقل مختلفة، أو إضاعة الوقت في المحطات. أنت من يحدد جدولك، وتختار وقت الاستلام الأنسب لك، وتتمتع بالمرونة في الإلغاء حتى ساعة قبل موعد رحلتك.",
      image: complimentaryServicesB,
    },
    {
      title: "تسعير شفاف للسفر بدون توتر",
      content:
        "يشمل تسعيرنا الشفاف جميع الضرائب والرسوم دون أية رسوم مخفية. خدمتنا مثالية للرحلات القصيرة والطويلة على حد سواء. تقدم خدمة النقل بين المدن من ييلو بديلاً مميزاً لأولئك الذين يبحثون عن السفر بسهولة وراحة، مما يجعل كل رحلة تبدو بلا عناء.",
      image: complimentaryServicesC,
    },
  ];

  const swiperRoutes = [
    {
      id: 1,
      from: "الرياض",
      to: "الدمام",
      price: 1075.0,
      capacity: 3,
      image: CityA,
    },
    {
      id: 2,
      from: "الرياض",
      to: "البحرين",
      price: 1350.0,
      capacity: 3,
      image: CityB,
    },
    {
      id: 3,
      from: "الرياض",
      to: "القصيم",
      price: 1050.0,
      capacity: 4,
      image: CityC,
    },
    {
      id: 4,
      from: "الدمام",
      to: "البحرين",
      price: 330.0,
      capacity: 3,
      image: CityD,
    },
    {
      id: 5,
      from: "الجبيل",
      to: "البحرين",
      price: 550.0,
      capacity: 3,
      image: CityE,
    },
    {
      id: 6,
      from: "جدة",
      to: "مكة",
      price: 450.0,
      capacity: 5,
      image: CityF,
    },
    {
      id: 7,
      from: "جدة",
      to: "المدينة",
      price: 1800.0,
      capacity: 5,
      image: CityG,
    },
    {
      id: 8,
      from: "مكة",
      to: "المدينة",
      price: 1900.0,
      capacity: 5,
      image: CityH,
    },
    {
      id: 9,
      from: "جدة",
      to: "العلا",
      price: 1800.0,
      capacity: 3,
      image: CityJ,
    },
  ];

  const faqs = [
    {
      question: "هل يمكنني التوقف في وجهات إضافية خلال رحلتي بين المدن؟",
      answer:
        "نعم، بالتأكيد! يمكنك إضافة توقفات خلال رحلتك بين المدن. ما عليك سوى إبلاغ سائقك مسبقًا بمسار الرحلة المفضل والتوقفات المطلوبة، وسيقوم بتلبية طلبك بكل سرور، مما يضمن لك رحلة مرنة ومريحة ومُخصصة لتناسب احتياجاتك.",
    },
    {
      question: "كيف يمكنني التواصل مع السائق؟",
      answer:
        "للتواصل المباشر مع سائقك، يمكنك استخدام معلومات الاتصال التي ستُرسل إليك قبل ساعة من موعد الاستلام عبر البريد الإلكتروني والرسائل النصية القصيرة. تتضمن هذه الرسالة اسم السائق، ورقم هاتفه، بالإضافة إلى معلومات عن السيارة (نوعها، وموديلها، ولوحة أرقامها).",
    },
    {
      question: "هل يمكنني إلغاء رحلتي؟",
      answer:
        'نعم، يمكنك إلغاء رحلتك بكل سهولة ومرونة. يحق لك إلغاء الرحلة مجانًا بدون أي رسوم حتى ساعة واحدة قبل الموعد المحدد للاستلام. لإلغاء رحلتك، ما عليك سوى التوجه إلى قسم "الرحلات المحجوزة" في تطبيقنا أو موقعنا الإلكتروني، ثم تحديد الرحلة التي ترغب في إلغائها واتباع خطوات الإلغاء الموجودة.',
    },
  ];

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Helmet>
        <title>خدمة السفر من مدينة الى مدينة | يلو بلس</title>
        <meta name="robots" content="index, follow" />
        <meta name="title" content="خدمة السفر من مدينة الى مدينة | يلو بلس" />
        <meta
          name="description"
          content="استمتع بخدمة النقل بين المدن من يلو بلس - خدمة من الباب إلى الباب، أسعار شفافة، سائقين محترفين، وسيارات فاخرة لرحلة مريحة."
        />
        <link
          rel="canonical"
          href="https://limousine.iyelo.com/ar/السفر-من-مدينة-الى-مدينة"
        />
      </Helmet>
      <div className="services__container">
        <Container maxWidth="lg">
          <Box
            className="services__main"
            sx={{
              background: {
                xs: "none",
                md: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 38.36%, rgba(0, 0, 0, 0.00) 50%),center center / cover url(${ServiceHeader}) no-repeat`,
              },
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                minHeight: "53.5vh",
                position: "relative",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="travel__style_heading">
                  <h1 className="heading">
                    <span style={{ color: "#FFD207" }}>فاخرة</span> خدمة السفر
                    من مدينة الى مدينة
                  </h1>
                  <p className="sub-heading">
                    استمتع بخدمة النقل بين المدن من يلو بلس - خدمة من الباب إلى
                    الباب، أسعار شفافة، سائقين محترفين، وسيارات فاخرة لرحلة
                    مريحة
                  </p>
                </div>
                <div className="travel__style">
                  <div className="booking__box">
                    <div className="bottom arBottom">
                      <PickUp />
                    </div>
                    <div ref={ref}></div>

                    <Stack
                      direction="row"
                      maxWidth={{ xs: "100%", md: "fit-content" }}
                      mt={"12px"}
                      borderRadius={{ xs: "12px 12px 0 0", md: "12px" }}
                      overflow={"hidden"}
                    >
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(0));
                        }}
                        sx={{
                          height: "44px",
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          width: { xs: "100%", md: "fit-content" },
                          borderRadius: "0",
                          color:
                            tab === 0
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 0
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 0
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        نقل
                      </Button>

                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(1));
                          dispatch(mapAction.setOpen(false));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 1
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 1
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 1
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        بالساعة
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(2));
                          dispatch(mapAction.setOpen(false));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 2
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 2
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 2
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        بين المدن
                      </Button>
                    </Stack>
                  </div>
                </div>
              </div>

              <Stack
                direction={"row"}
                gap={2}
                justifyContent={{
                  xs: "center",
                  md: "flex-end",
                }}
                mt={4}
              >
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://apps.apple.com/us/app/yelo-limousine/6446365805"
                    target="_blank"
                  >
                    <Box
                      component={"img"}
                      display={{ xs: "none", md: "block" }}
                      src={appstoreAr}
                      alt="App Store"
                    />
                    <Box
                      component={"img"}
                      display={{ xs: "block", md: "none" }}
                      src={appStoreDarkAr}
                      alt="App Store"
                    />
                  </Link>
                </Box>
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.wefaq.limousine"
                    target="_blank"
                  >
                    <Box
                      component={"img"}
                      display={{ xs: "none", md: "block" }}
                      src={playstoreAr}
                      alt="Play Store"
                    />
                    <Box
                      component={"img"}
                      display={{ xs: "block", md: "none" }}
                      src={playStoreDarkAr}
                      alt="Play Store"
                    />
                  </Link>
                </Box>
              </Stack>
            </div>
          </Box>

          <Box mt={{ xs: "60px", md: "80px" }}>
            <Grid container spacing={{ xs: 2, md: 0 }}>
              {complimentaryServices.map((complimentaryService, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card
                    sx={{
                      height: { md: "100%" },
                      px: { xs: 3, md: 4 },
                      py: { xs: 3, md: 0 },
                      boxShadow: "none",
                      borderRadius: { xs: "16px", md: 0 },
                      borderLeft: { md: "1px solid #ECEBF2" },
                      borderRight: {
                        md: index === 2 ? "1px solid #ECEBF2" : 0,
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        p: "0 !important",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <img
                        src={complimentaryService.image || "/placeholder.svg"}
                        alt={complimentaryService.title}
                        style={{ maxWidth: "68px" }}
                      />
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Typography
                          variant="h6"
                          component="h3"
                          fontSize={16}
                          fontWeight={700}
                          color={"#520A76"}
                          letterSpacing={"0.14px"}
                          textOverflow={"ellipsis"}
                        >
                          {complimentaryService.title}
                        </Typography>
                        <Typography variant="body2" fontSize={16}>
                          {complimentaryService.content}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box mt={{ xs: "60px", md: "80px" }}>
            <Grid container columnSpacing={4} alignItems={"center"}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    خدمة نقل مميزة بين المدن
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    تقدم يلو بلس خدمة السيارات بين المدن بتجربة سفر سلسة تضمن لك
                    راحتك وسهولة تنقلك في كل رحلة. سواء كنت تنتقل بين المدن في
                    الإمارات، أو تستكشف المناظر الطبيعية الخلابة في السعودية، أو
                    تزور المعالم التاريخية في الأردن، فإن يلو بلس توفر لك مجموعة
                    متنوعة من السيارات الفاخرة التي تلبي جميع احتياجاتك، وسائقين
                    محترفين، ومجموعة من الخدمات المتميزة التي تعزز تجربة سفرك،
                    مثل خدمة الاستقبال والترحيب، والمساعدة في حمل الأمتعة،
                    وتوفير خدمة الأنترنت المجانية داخل السيارة. بالإضافة إلى
                    ذلك، نقدم لك أسعارًا تنافسية تتناسب مع مستوى الخدمة الفاخرة
                    التي نقدمها.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${City})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box mt={{ xs: "60px", md: "80px" }}>
          <SwiperSlider
            name="city"
            slidesPerViews={5}
            showNavigation={true}
            heading="وجهاتنا"
          >
            {swiperRoutes.map((route, index) => (
              <SwiperSlide key={index} style={{ width: "auto" }}>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    borderRadius: "16px",
                    overflow: "hidden",
                    cursor: "pointer",
                    "&:hover img": {
                      transform: "scale(1.15)",
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={route.image}
                    alt={`${route.from} إلى ${route.to}`}
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transition: "transform .5s ease 0s",
                      minHeight: { xs: "auto", md: "285px" },
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      p: { xs: "8px", sm: 2 },
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: "14px", sm: "16px" },
                        fontWeight: 600,
                        lineHeight: { xs: "18px", md: "22px" },
                        letterSpacing: "0.18px",
                      }}
                    >
                      {route.from} - {route.to}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        letterSpacing: "0.16px",
                        display: "flex",
                        alignItems: "center",
                        color: "#fccc00",
                      }}
                    >
                      يبدأ من {route.price.toFixed(2)} ريال
                    </Typography>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </SwiperSlider>
        </Box>

        <Container maxWidth="lg">
          <Box mt={{ xs: "60px", md: "80px" }}>
            <Typography
              variant="h4"
              sx={{
                color: "#520a76",
                fontSize: { xs: 24, md: 32 },
                fontWeight: "bold",
                lineHeight: { xs: "32px", md: "48px" },
                letterSpacing: "0.165px",
                mb: { xs: 2, md: 4 },
              }}
            >
              الأسئلة الشائعة
            </Typography>

            <Grid container spacing={2}>
              {faqs.map((faq, index) => (
                <Grid item md={6} key={index}>
                  <Accordion
                    key={index}
                    sx={{
                      background: "transparent",
                      border: "transparent",
                      borderRadius: "16px !important",
                      backgroundColor: "#F9F9F9",
                      boxShadow: "none",
                      "&.Mui-expanded": {
                        marginBottom: 0,
                        marginTop: 0,
                      },
                      "& .MuiPaper-root": {
                        marginBottom: 0,
                        marginTop: 0,
                      },
                    }}
                    expanded={expanded === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowLeft
                          style={{
                            transform:
                              expanded === `panel${index}`
                                ? "rotate(-270deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.3s",
                            fontSize: "24px",
                          }}
                        />
                      }
                      sx={{
                        p: 3,
                        borderRadius: "16px",
                        marginBottom: 0,
                        "& .MuiAccordionSummary-content": {
                          my: "0 !important",
                        },
                        "&.Mui-expanded": { my: 0, minHeight: "auto" },
                        minHeight: "auto",
                      }}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: 16,
                          fontWeight: 600,
                          letterSpacing: "0.17px",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ pt: 0, px: 3, pb: 3 }}>
                      <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default CityToCityTransfer;
