import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { mapAction } from "redux/slices/map/mapSlice";
import MapService from "services/map.service";
import ClearIcon from "@mui/icons-material/Clear";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const GoogleMapComponent = (props: { google: any }) => {
  const dispatch = useAppDispatch();
  const form = "PickUpForm";
  const { t, i18n } = useTranslation("translation");

  const picklatlong = useAppSelector(
    (state) => state.form?.[form]?.values?.pickup?.details?.geometry?.location
  );

  const destinationlatlong = useAppSelector(
    (state) =>
      state.form?.[form]?.values?.destination?.details?.geometry?.location
  );

  let startLat = useAppSelector((state) => state.map.startLat);
  let startlng = useAppSelector((state) => state.map.startlng);

  let endLat = useAppSelector((state) => state.map.endLat);
  let endlng = useAppSelector((state) => state.map.endlng);

  const show = useAppSelector((state) => state.map.show);

  const formattedStartlat = useAppSelector(
    // @ts-ignore
    (state) => state?.map?.startLocation?.lat
  );
  const formattedStartlng = useAppSelector(
    // @ts-ignore
    (state) => state?.map?.startLocation?.lng
  );

  const formattedEndlat = useAppSelector(
    // @ts-ignore
    (state) => state?.map?.endLocation?.lat
  );
  const formattedEndlng = useAppSelector(
    // @ts-ignore
    (state) => state?.map?.endLocation?.lng
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let startLocation = { lat: "", lng: "" };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let endLocation = { lat: "", lng: "" };

  useEffect(() => {
    dispatch(mapAction.setStartLocation(startLocation));
    dispatch(mapAction.setEndLocation(endLocation));
  }, [dispatch, endLocation, startLocation]);

  // pickup geocoding api
  let pick;
  // useEffect(() => {

  //   if (
  //     formattedStartlat === startLat ||
  //     formattedStartlng === startlng ||
  //     formattedStartlat === undefined ||
  //     formattedStartlng === undefined
  //   )
  //     return;
  //   MapService.getLocation(formattedStartlat, formattedStartlng, (pick = true));
  // }, [formattedStartlat, formattedStartlng, pick, startLat, startlng]);

  const getPickUP = () => {
    MapService.getLocation(formattedStartlat, formattedStartlng, (pick = true));
    dispatch(mapAction.setOpen(false));
  };
  const getDestination = () => {
    MapService.getLocation(formattedEndlat, formattedEndlng, (pick = false));
    dispatch(mapAction.setOpen(false));
  };

  // destination geocoding api
  // useEffect(() => {
  //   if (formattedEndlat !== endLat) {

  //   }
  //   if (formattedEndlat === endLat || formattedEndlng === endlng ||
  //    formattedEndlat === undefined ||
  //     formattedEndlng === undefined
  //   ) return;
  //   MapService.getLocation(formattedEndlat, formattedEndlng, (pick = false));
  // }, [endLat, endlng, formattedEndlat, formattedEndlng]);

  let markersList = [
    show === 0 && {
      lat: picklatlong?.lat ? picklatlong?.lat : startLat,
      lng: picklatlong?.lng ? picklatlong?.lng : startlng,
    },
    // hourly !== true
    show === 1 && {
      lat: destinationlatlong?.lat ? destinationlatlong?.lat : endLat,
      lng: destinationlatlong?.lng ? destinationlatlong?.lng : endlng,
    },
  ];
  let [markers, setMarkers] = useState(markersList);

  let [data, setData] = useState();

  let onMarkerDragEnd = (
    coord: { latLng: any },
    index: string,
    markers: any
  ) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    markers[index] = { lat, lng };

    setData(markers[index]);
    setMarkers(markers);
  };

  let myMarkers =
    markers &&
    Object.entries(markers).map(([key, val]) => (
      <Marker
        key={key}
        // @ts-ignore
        id={key}
        position={{
          // @ts-ignore
          lat: val?.lat,
          // @ts-ignore
          lng: val?.lng,
        }}
        draggable={true}
        onDragend={(t: any, map: any, coord: any) =>
          onMarkerDragEnd(coord, key, markers)
        }
      />
    ));

  startLocation.lat = myMarkers[0]?.props?.position?.lat;
  startLocation.lng = myMarkers[0]?.props?.position?.lng;
  endLocation.lat = myMarkers[1]?.props?.position?.lat;
  endLocation.lng = myMarkers[1]?.props?.position?.lng;

  return (
    <>
      <div style={{ height: "340px" }}>
        <div
          style={{
            position: "absolute",
            top: "10px",
            width: "100%",
            zIndex: "200",
          }}
        >
          <ClearIcon
            onClick={() => dispatch(mapAction.setOpen(false))}
            style={{
              display: "flex",
              cursor: "pointer",
              margin: "0 auto", // background:"red",
              background: "#520a76",
              color: "white",
              borderRadius: "99px",
              boxShadow: "0px 6px 18px rgb(0 0 0 / 62%)",
              width: "25px",
              height: "25px",
              padding: "3px",
            }}
          />
        </div>
        <div
          onClick={getPickUP}
          className="choseLocation"
          style={{
            position: "absolute",
            bottom: "10px",
            width: "100%",
            zIndex: "200",
            fontFamily: i18n.language === "ar" ? "arabic" : "arabic",
          }}
        >
          {t("moveMap")}
        </div>
        {show === 0 ? (
          <Tooltip
            placement="top"
            title="Move Arrow to Confirm your Location"
            arrow
          >
            <div
              onClick={getPickUP}
              className="choseLocation"
              style={{
                position: "absolute",
                bottom: "10px",
                width: "100%",
                zIndex: "200",
              }}
            >
              {t("confirmPickUp")}
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            placement="top"
            title="Move Arrow to Confirm your Location"
            arrow
          >
            <div
              onClick={getDestination}
              className="choseLocation"
              style={{
                position: "absolute",
                bottom: "10px",
                width: "100%",
                zIndex: "200",
              }}
            >
              {t("confirmDestination")}
            </div>
          </Tooltip>
        )}

        <Map
          google={props.google}
          // @ts-ignore
          zoom={15}
          initialCenter={
            show === 0
              ? {
                  lat: picklatlong?.lat ? picklatlong?.lat : 24.7136,
                  lng: picklatlong?.lng ? picklatlong?.lng : 46.6753,
                }
              : {
                  lat: destinationlatlong?.lat
                    ? destinationlatlong?.lat
                    : endLat,
                  lng: destinationlatlong?.lng
                    ? destinationlatlong?.lng
                    : endlng,
                }
          }
        >
          {myMarkers}
        </Map>
      </div>
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyDnO5gdUDI1we0x9noUejLSHlM4FKGPr9o",
})(GoogleMapComponent);
