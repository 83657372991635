import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BackIcon from "assets/icons/back-icon.svg";

const AccountHeader = ({ heading, subHeading, link, back }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack
      gap={1.5}
      direction={"row"}
      alignItems={"center"}
      sx={{
        cursor: back ? "pointer" : "auto",
      }}
    >
      {back && (
        <img
          src={BackIcon}
          alt=""
          onClick={() => navigate(link)}
          style={{
            transform: i18n.language === "ar" ? "rotate(180deg)" : "rotate(0)",
          }}
        />
      )}
      <Typography
        fontSize={{ xs: "18px", md: "24px" }}
        fontWeight={700}
        pt={"4px"}
        lineHeight={{ xs: "24px", md: "48px" }}
      >
        {t(heading)} {subHeading ? `#${t(subHeading)}` : ""}
      </Typography>
    </Stack>
  );
};

export default AccountHeader;
