import BookingDetails from "pages/BookingDetails";
// import ClassPage from "pages/ClassPage/ClassPage";
import PaymentHandler from "pages/PaymentHandler/PaymentHandler";
import PrivacyPolicy from "pages/PrivacyPolicy";
import SelectionForOtp from "pages/SelectionForOtp";
import AirportTransfers from "pages/ServicePages/en/AirportTransfers";
import ChauffeurService from "pages/ServicePages/en/ChauffeurService";
import CityToCityTransfer from "pages/ServicePages/en/CityToCityTransfer";
import EventTransportation from "pages/ServicePages/en/EventTransportation";
import HireByTheHour from "pages/ServicePages/en/HireByTheHour";
import LimousineService from "pages/ServicePages/en/LimousineService";

import AirportTransfersAR from "pages/ServicePages/ar/AirportTransfers";
import ChauffeurServiceAR from "pages/ServicePages/ar/ChauffeurService";
import CityToCityTransferAR from "pages/ServicePages/ar/CityToCityTransfer";
import EventTransportationAR from "pages/ServicePages/ar/EventTransportation";
import HireByTheHourAR from "pages/ServicePages/ar/HireByTheHour";
import LimousineServiceAR from "pages/ServicePages/ar/LimousineService";

import TermsAndConditions from "pages/TermsAndConditions";
import { lazy } from "react";

// const Home = lazy(() => import("pages/Home"));

// const Faqs = lazy(() => import("pages/Faqs"));
// const AboutUs = lazy(() => import("pages/AboutUs"));
const ContactUs = lazy(() => import("pages/ContactUs"));
const BookingStatusUpdate = lazy(() => import("pages/BookingStatusUpdate"));

// const Careers = lazy(() => import("pages/Careers"));
// const Partner = lazy(() => import("pages/Partner"));
// const Company = lazy(() => import("pages/Company"));
// const EventsPage = lazy(() => import("pages/EventsPage"));
// const CareerApply = lazy(() => import("pages/CareerApply"));
// const ServicesPage = lazy(() => import("pages/ServicesPage"));
// const ServiceRequest = lazy(() => import("pages/ServiceRequest"));
// const PartnerRegister = lazy(() => import("pages/PartnerRegister"));

// const Imprint = lazy(() => import("pages/Imprint"));
// const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
// const CookiesPolicy = lazy(() => import("pages/CookiesPolicy"));
// const TermsConditions = lazy(() => import("pages/TermsConditions"));
// const ConditionsTransport = lazy(() => import("pages/ConditionsTransport"));

const LoginPage = lazy(() => import("pages/LoginPage"));
const SignupPage = lazy(() => import("pages/SignupPage"));
const SearchForm = lazy(() => import("pages/SearchForm"));
const PasswordOTP = lazy(() => import("pages/PasswordOTP"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

const Wallet = lazy(() => import("pages/Wallet"));
const Profile = lazy(() => import("pages/Profile"));
const Billing = lazy(() => import("pages/Billing"));
const UpdateProfile = lazy(() => import("pages/ProfileUpdate"));
const Bookings = lazy(() => import("pages/Bookings"));
const Business = lazy(() => import("pages/Business"));
const Services = lazy(() => import("pages/Services"));
const EnGlobalReach = lazy(() => import("pages/GlobalReach/EnGlobalReach"));
const ArGlobalReach = lazy(() => import("pages/GlobalReach/ArGlobalReach"));
const Landing = lazy(() => import("pages/Landing"));
const PaymentForm = lazy(() => import("pages/PaymentForm"));
const AccountDelete = lazy(() => import("pages/AccountDelete"));
const DeleteUserAccount = lazy(() => import("pages/Delete-user-account"));
const DeleteDriverAccount = lazy(() => import("pages/Delete-driver-account"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [
  { path: "/", element: <Services /> },
  { path: "/business", element: <Business /> },
  { path: "/about", element: <Services /> },
  { path: "/unforgettable-moments", element: <Landing /> },

  { path: "/global-reach", element: <EnGlobalReach /> },
  { path: "/انطلاقة-من-السعودية-نحو-آفاق-عالمية", element: <ArGlobalReach /> },

  // { path: "/first-class", element: <ClassPage /> },
  // { path: "/van-class", element: <ClassPage /> },
  // { path: "/business-class", element: <ClassPage /> },
  // { path: "/business2-class", element: <ClassPage /> },
  // { path: "/suv-class", element: <ClassPage /> },
  // { path: "/luxury-class", element: <ClassPage /> },
  // { path: "/premium-class", element: <ClassPage /> },

  { path: "/chauffeur-service", element: <ChauffeurService /> },
  { path: "/limousine-service", element: <LimousineService /> },
  { path: "/airport-transfers", element: <AirportTransfers /> },
  { path: "/city-to-city-transfer", element: <CityToCityTransfer /> },
  { path: "/hire-by-the-hour", element: <HireByTheHour /> },
  { path: "/event-transportation", element: <EventTransportation /> },

  { path: "/خدمات-السائق-الخاص", element: <ChauffeurServiceAR /> },
  { path: "/خدمة-الليموزين-الفاخرة", element: <LimousineServiceAR /> },
  { path: "/النقل-من-وإلى-المطار", element: <AirportTransfersAR /> },
  { path: "/السفر-من-مدينة-الى-مدينة", element: <CityToCityTransferAR /> },
  { path: "/خدمة-الحجز-بالساعة", element: <HireByTheHourAR /> },
  { path: "/النقل-الفاخر-للفعاليات", element: <EventTransportationAR /> },

  { path: "/login", element: <LoginPage /> },
  { path: "/signup", element: <SignupPage /> },
  { path: "/password-otp", element: <PasswordOTP /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/select-otp-option", element: <SelectionForOtp /> },
  { path: "/delete-user-account", element: <DeleteUserAccount /> },
  { path: "/delete-driver-account", element: <DeleteDriverAccount /> },

  { path: "/search-form", element: <SearchForm /> },

  { path: "/payment", element: <PaymentHandler /> },
  { path: "/payment-form", element: <PaymentForm /> },
  { path: "/account/delete", element: <AccountDelete /> },
  { path: "/contactUs", element: <ContactUs /> },

  { path: "/privacy", element: <PrivacyPolicy /> },
  { path: "/terms-and-conditions", element: <TermsAndConditions /> },

  { path: "/status-update/:id", element: <BookingStatusUpdate /> },
];

export const private_routes: IRoute[] = [
  { path: "/wallet", element: <Wallet /> },
  { path: "/profile", element: <Profile /> },
  { path: "/billing", element: <Billing /> },
  { path: "/update-profile", element: <UpdateProfile /> },
  { path: "/bookings", element: <Bookings /> },
  { path: "/booking/:id", element: <BookingDetails /> },
];
