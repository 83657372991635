import type React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ServiceHeader from "assets/services/limo-service-banner.webp";
import Button from "components/atoms/Button";
import PickUp from "components/templates/PickUp";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { bookingActions } from "redux/slices/booking";
import { mapAction } from "redux/slices/map/mapSlice";
import appstoreAr from "assets/app-store--ar.svg";
import playstoreAr from "assets/google-play--ar.svg";
import appStoreDarkAr from "assets/app-store-dark--ar.svg";
import playStoreDarkAr from "assets/google-play-dark--ar.svg";

import complimentaryServicesA from "assets/services/limousine1.svg";
import complimentaryServicesB from "assets/services/limousine2.svg";
import complimentaryServicesC from "assets/services/limousine3.svg";

import LimousineA from "assets/services/limousineA.webp";
import LimousineB from "assets/services/limousineB.webp";
import LimousineC from "assets/services/limousineC.webp";

import PersonIcon from "assets/icons/seats.svg";
import BagIcon from "assets/icons/luggage.svg";

import ClassA from "assets/fleet/business-class.png";
import ClassB from "assets/fleet/electric-class.png";
import ClassC from "assets/fleet/premium-class.png";
import ClassD from "assets/fleet/first-class.png";
import ClassE from "assets/fleet/van-class.png";
import ClassF from "assets/fleet/business-class-suv.png";
import ClassG from "assets/fleet/first-class-suv.png";

import "../../servicepage.scss";
import SwiperSlider from "components/atoms/SwiperSlider";
import { SwiperSlide } from "swiper/react";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const LimousineService = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const tab = useAppSelector((state) => state.booking.tab);

  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
    if (i18n.language === "en" || pathname.includes("/en")) {
      navigate("/limousine-service");
    }
  }, [i18n.language, navigate, pathname]);

  const complimentaryServices = [
    {
      title: "سياسة إلغاء مرنة",
      content:
        "استمتع بتجربة تأجير سيارات فارهة مع يلو بلس، حيث نوفر لك أفخم السيارات مع خدمة ليموزين مميزة تلبي أعلى معايير الفخامة والراحة.تحكم في خطط سفرك بكل سهولة مع سياسة إلغاء مرنة تتيح لك تعديل أو إلغاء حجزك دون أي تعقيدات.نحن هنا لنضمن لك رحلة مريحة وخالية من التوتر، مع تجربة استثنائية في تأجير السيارات الفارهة تناسب احتياجاتك الفاخرة.تم دمج الكلمة المفتاحية بشكل سلس لتعزيز فرص الظهور في نتائج البحث مع تحسين السرد لجذب العملاء.",
      image: complimentaryServicesA,
    },
    {
      title: "أسعار تنافسية",
      content:
        "معنا أنت دائمًا على اطلاع بتكاليف رحلتك ، أسعارنا شفافة وواضحة تشمل جميع الرسوم والإكراميات، السعر الذي تراه عند الحجز هو السعر الذي ستدفعه، دون أي مفاجآت أو تكاليف غير متوقعة ، مما يضمن تجربة سلسة وشفافة مع كل رحلة.",
      image: complimentaryServicesB,
    },
    {
      title: "أوقات انتظار مجانية",
      content:
        "لا تقلق بشأن الوقت، نحن هنا لجعل رحلتك سهلة ومريحةمع خدمة الليموزين من يلو بلس، نقدم لك وقت انتظار مجاني يمنحك المرونة التي تحتاجها.سائقونا المحترفون سيكونون في انتظارك بكل احترام وتقدير، لضمان وصولك إلى وجهتك بكل سهولة ويسر.",
      image: complimentaryServicesC,
    },
  ];

  const cars = [
    {
      img: ClassB,
      name: "الفئة الكهربائية",
      link: "/",
      person: 3,
      bags: 2,
    },
    {
      img: ClassC,
      name: "الفئة المميزة",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassA,
      name: "فئة رجال الأعمال",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassE,
      name: "فئة الفان",
      link: "/",
      person: 6,
      bags: 4,
    },
    {
      img: ClassF,
      name: "فئة SUV",
      link: "/",
      person: 6,
      bags: 3,
    },
    {
      img: ClassD,
      name: "الفئة الأولى",
      link: "/",
      person: 4,
      bags: 3,
    },
    {
      img: ClassG,
      name: "الفئة الأولى SUV",
      link: "/",
      person: 6,
      bags: 5,
    },
  ];

  const faqs = [
    {
      question: "ماذا تعني سيارة الليموزين؟",
      answer:
        "الليموزين ليست مجرد سيارة فاخرة، بل أسطول متنوع من المركبات الفاخرة التي تتجاوز المفهوم التقليدي للسيارة، وتشمل: سيارات سيدان فاخرة، سيارات دفع رباعي فاخرة، ليموزين ممتدة والتي تتميز بطولها الفائق ومساحتها الداخلية الواسعة.",
    },
    {
      question: "لماذا تعد خدمة الليموزين مفيدة؟",
      answer:
        "توفر لك خدمات الليموزين الفخامة والراحة والاسترخاء، مع سائقين محترفين يضمنون لك الوصول إلى وجهتك بثقة.",
    },
  ];

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Helmet>
        <title>خدمة الليموزين الفاخرة | يلو بلس</title>
        <meta name="robots" content="index, follow" />
        <meta name="title" content="خدمة الليموزين الفاخرة | يلو بلس" />
        <meta
          name="description"
          content="استمتع برحلة فاخرة مع خدمة الليموزين من يلو بلس - سائقين محترفين، سيارات فاخرة، حجوزات مرنة وأسعار شفافة لرحلة خالية من المتاعب."
        />
        <link
          rel="canonical"
          href="https://limousine.iyelo.com/ar/خدمة-الليموزين-الفاخرة"
        />
      </Helmet>
      <div className="services__container">
        <Container maxWidth="lg">
          <Box
            className="services__main"
            sx={{
              background: {
                xs: "none",
                md: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 38.36%, rgba(0, 0, 0, 0.00) 50%),center center / cover url(${ServiceHeader}) no-repeat`,
              },
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                minHeight: "53.5vh",
                position: "relative",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="travel__style_heading">
                  <h1 className="heading">
                    <span style={{ color: "#FFD207" }}>فاخرة</span> خدمة
                    الليموزين
                  </h1>
                  <p className="sub-heading">
                    استمتع بتجربة سفر فاخرة ومميزة مع خدمة الليموزين من يلو بلس
                  </p>
                </div>
                <div className="travel__style">
                  <div className="booking__box">
                    <div className="bottom arBottom">
                      <PickUp />
                    </div>
                    <div ref={ref}></div>

                    <Stack
                      direction="row"
                      maxWidth={{ xs: "100%", md: "fit-content" }}
                      mt={"12px"}
                      borderRadius={{ xs: "12px 12px 0 0", md: "12px" }}
                      overflow={"hidden"}
                    >
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(0));
                        }}
                        sx={{
                          height: "44px",
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          width: { xs: "100%", md: "fit-content" },
                          borderRadius: "0",
                          color:
                            tab === 0
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 0
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 0
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        نقل
                      </Button>

                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(1));
                          dispatch(mapAction.setOpen(false));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 1
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 1
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 1
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        بالساعة
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(bookingActions.setTab(2));
                          dispatch(mapAction.setOpen(false));
                        }}
                        sx={{
                          height: "44px",
                          borderRadius: "0",
                          width: { xs: "100%", md: "fit-content" },
                          color:
                            tab === 2
                              ? "#0A0A0E"
                              : { xs: "#0A0A0E", md: "#ffffff" },
                          background:
                            tab === 2
                              ? "#ffffff"
                              : {
                                  xs: "transparent",
                                  md: "rgba(0, 0, 0, 0.49)",
                                },
                          boxShadow: "none",
                          fontSize: { xs: "0.775rem", sm: "inherit" },
                          textTransform: "unset",
                          "&:hover": {
                            background:
                              tab === 2
                                ? "#ffffff"
                                : {
                                    xs: "transparent",
                                    md: "rgba(0, 0, 0, 0.49)",
                                  },
                          },
                        }}
                        variant="contained"
                      >
                        بين المدن
                      </Button>
                    </Stack>
                  </div>
                </div>
              </div>

              <Stack
                direction={"row"}
                gap={2}
                justifyContent={{
                  xs: "center",
                  md: "flex-end",
                }}
                mt={4}
              >
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://apps.apple.com/us/app/yelo-limousine/6446365805"
                    target="_blank"
                  >
                    <Box
                      component={"img"}
                      display={{ xs: "none", md: "block" }}
                      src={appstoreAr}
                      alt="App Store"
                    />
                    <Box
                      component={"img"}
                      display={{ xs: "block", md: "none" }}
                      src={appStoreDarkAr}
                      alt="App Store"
                    />
                  </Link>
                </Box>
                <Box borderRadius={"7px"}>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.wefaq.limousine"
                    target="_blank"
                  >
                    <Box
                      component={"img"}
                      display={{ xs: "none", md: "block" }}
                      src={playstoreAr}
                      alt="Play Store"
                    />
                    <Box
                      component={"img"}
                      display={{ xs: "block", md: "none" }}
                      src={playStoreDarkAr}
                      alt="Play Store"
                    />
                  </Link>
                </Box>
              </Stack>
            </div>
          </Box>

          <Box mt={{ xs: "60px", md: "80px" }}>
            <Grid container spacing={{ xs: 2, md: 0 }}>
              {complimentaryServices.map((complimentaryService, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card
                    sx={{
                      height: { md: "100%" },
                      px: { xs: 3, md: 4 },
                      py: { xs: 3, md: 0 },
                      boxShadow: "none",
                      borderRadius: { xs: "16px", md: 0 },
                      borderLeft: { md: "1px solid #ECEBF2" },
                      borderRight: {
                        md: index === 2 ? "1px solid #ECEBF2" : 0,
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        p: "0 !important",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <img
                        src={complimentaryService.image || "/placeholder.svg"}
                        alt={complimentaryService.title}
                        style={{ maxWidth: "68px" }}
                      />
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Typography
                          variant="h6"
                          component="h3"
                          fontSize={16}
                          fontWeight={700}
                          color={"#520A76"}
                          letterSpacing={"0.14px"}
                          textOverflow={"ellipsis"}
                        >
                          {complimentaryService.title}
                        </Typography>
                        <Typography variant="body2" fontSize={16}>
                          {complimentaryService.content}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box mt={{ xs: "60px", md: "80px" }}>
            <Grid
              container
              columnSpacing={4}
              rowSpacing={{ xs: "60px", md: "80px" }}
              alignItems={"center"}
            >
              <Grid item xs={12} md={6} order={{ xs: 0, md: 0 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    خدمة الليموزين الفاخرة
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    استمتع بتجربة سفر فاخرة ومميزة مع خدمة الليموزين من يلو بلس
                    في جميع أنحاء المملكة العربية السعودية والأردن والإمارات
                    العربية المتحدة. سواء كنت متوجهًا إلى اجتماع عمل، مناسبة
                    خاصة، أو حتى في جولة استكشافية لمدينة، فإن سائقينا المحترفين
                    يضمنون لك رحلة سلسة ومريحة بأعلى المعايير والاهتمام
                    بالتفاصيل.
                    <br />
                    كما يمكنك الاستفادة من خيار استئجار ليموزين بالساعة، لتنظيم
                    جدولك بكل سهولة ويسر.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${LimousineA})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} order={{ xs: 3, md: 2 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${LimousineB})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 2, md: 3 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    تأجير سيارات فاخرة مع سائق خاص لكل مناسبة
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    استمتع بخدمة تأجير سيارات فاخرة مع يلو بلس، حيث نوفر لك
                    مجموعة مختارة بعناية من السيارات الفاخرة التي تجمع بين
                    الراحة والأداء العالي، لتجربة قيادة فريدة تناسب احتياجاتك
                    الفاخرة.
                    <br />
                    سياراتنا مثالية لأي حدث، سواء كنت تبحث عن سيارة فاخرة
                    لاجتماع عمل، مناسبة خاصة، أو حتى لقضاء عطلة نهاية الأسبوع،
                    لدينا ما يناسبك.
                    <br />
                    جميع سياراتنا تخضع لصيانة دورية صارمة لضمان أعلى معايير
                    الجودة والأداء، مما يضمن لك تجربة قيادة سلسة وفاخرة تتجاوز
                    توقعاتك.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} order={{ xs: 4, md: 4 }}>
                <Box>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#520A76",
                      mb: { xs: 2, md: 3 },
                      fontWeight: { xs: 600, md: 700 },
                      fontSize: { xs: "18px", md: "24px" },
                    }}
                  >
                    خدمة ليموزين فاخرة مع رعاية شخصية
                  </Typography>
                  <Typography
                    fontSize={16}
                    sx={{
                      mb: { xs: 2, md: 3 },
                    }}
                  >
                    نسعى في يلو بلس بشغف كبير فى ان نجعل تجربة السفر لعملائنا
                    استثنائية بكل المقاييس.
                    <br />
                    لذلك، نقدم لك خدمة ليموزين فاخرة مع رعاية شخصية، تجمع بين
                    الفخامة والأداء والاهتمام بأدق التفاصيل.
                    <br />
                    من لحظة الحجز لرحلتك معنا، سيكون فريقنا المتخصص في خدمتك،
                    ليضمن لك تجربة سفر لا مثيل لها.
                    <br />
                    نحن نهتم بالتفاصيل بشكل كبير، من اختيار السيارة المناسبة
                    وصولًا إلى توفير جميع وسائل الراحة والترفيه التي تحتاجها.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 5, md: 5 }}>
                <Box
                  sx={{
                    height: "400px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    backgroundImage: `url(${LimousineC})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box mt={{ xs: "60px", md: "80px" }}>
          <SwiperSlider
            name="fleet"
            heading="اكتشف مجموعتنا المميزة من السيارات الفاخرة"
            slidesPerViews={5}
            showNavigation={true}
          >
            {cars.map((data, index) => (
              <SwiperSlide key={index} style={{ width: "auto" }}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "16px",
                    overflow: "hidden",
                    p: { xs: "12px", md: "24px" },
                    background:
                      "linear-gradient(180.00deg, rgb(249, 237, 255),rgb(255, 255, 255) 100%)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "16px",
                    height: { xs: 200, md: 300 },
                  }}
                >
                  <Box
                    component="img"
                    src={data.img}
                    alt={data.name}
                    sx={{
                      width: "100%",
                      height: { xs: "120px", md: "200px" },
                      objectFit: "contain",
                    }}
                  />
                  <Box pt={{ xs: 0, md: 3 }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: { xs: "14px", sm: "18px" },
                        lineHeight: { xs: "18px", sm: "24px" },
                        letterSpacing: "0.09px",
                        color: "#520a76",
                      }}
                    >
                      {data.name}
                    </Typography>
                    <Stack direction={"row"} gap={1.5} mt={2}>
                      <Stack gap={"4px"} direction={"row"} alignItems={"end"}>
                        <img
                          loading="lazy"
                          src={PersonIcon || "/placeholder.svg"}
                          alt=""
                          width={"18px"}
                        />
                        <Typography
                          lineHeight={1}
                          fontWeight={300}
                          fontSize={{ xs: "12px", sm: "14px" }}
                        >
                          حد أقصى&nbsp;
                          {data.person}
                        </Typography>
                      </Stack>
                      <Stack gap={"4px"} direction={"row"} alignItems={"end"}>
                        <img
                          loading="lazy"
                          src={BagIcon || "/placeholder.svg"}
                          alt=""
                          width={"18px"}
                        />
                        <Typography
                          lineHeight={1}
                          fontWeight={300}
                          fontSize={{ xs: "12px", sm: "14px" }}
                        >
                          حد أقصى&nbsp;
                          {data.bags}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </SwiperSlider>
        </Box>

        <Container maxWidth="lg">
          <Box mt={{ xs: "60px", md: "80px" }}>
            <Typography
              variant="h4"
              sx={{
                color: "#520a76",
                fontSize: { xs: 24, md: 32 },
                fontWeight: "bold",
                lineHeight: { xs: "32px", md: "48px" },
                letterSpacing: "0.165px",
                mb: { xs: 2, md: 4 },
              }}
            >
              الأسئلة الشائعة
            </Typography>

            <Grid container spacing={2}>
              {faqs.map((faq, index) => (
                <Grid item md={6} key={index}>
                  <Accordion
                    sx={{
                      background: "transparent",
                      border: "transparent",
                      borderRadius: "16px !important",
                      backgroundColor: "#F9F9F9",
                      boxShadow: "none",
                      "&.Mui-expanded": {
                        marginBottom: 0,
                        marginTop: 0,
                      },
                      "& .MuiPaper-root": {
                        marginBottom: 0,
                        marginTop: 0,
                      },
                    }}
                    expanded={expanded === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowLeft
                          style={{
                            transform:
                              expanded === `panel${index}`
                                ? "rotate(-270deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.3s",
                            fontSize: "24px",
                          }}
                        />
                      }
                      sx={{
                        p: 3,
                        borderRadius: "16px",
                        marginBottom: 0,
                        "& .MuiAccordionSummary-content": {
                          my: "0 !important",
                        },
                        "&.Mui-expanded": { my: 0, minHeight: "auto" },
                        minHeight: "auto",
                      }}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: 16,
                          fontWeight: 600,
                          letterSpacing: "0.17px",
                        }}
                      >
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ pt: 0, px: 3, pb: 3 }}>
                      <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default LimousineService;
