import { useState } from "react";
import { InputOwnProps } from ".";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";

export type InputProps = InputOwnProps & React.ComponentProps<typeof TextField>;

const CssTextField = styled(TextField)<{ icon?: boolean; customize?: boolean }>(
  ({ customize, icon }) => ({
    // Filled Input
    "& .MuiFilledInput-root": {
      backgroundColor: "#FFFFFF",
      borderRadius: "12px",
      paddingLeft: icon ? "32px" : "0",
      border: " 1px solid #ECEBF2",
    },
    "& .MuiFilledInput-root:after, .MuiFilledInput-root:before": {
      display: "none",
    },
    "& .MuiFilledInput-root .Mui-disabled": {
      backgroundColor: "#ececec !important",
      opacity: "0.5",
      borderRadius: "12px",
    },
    "& .Mui-focused": {
      backgroundColor: "#ffffff !important",
      borderColor: "#520A76 !important",
    },
    "& .MuiFilledInput-input:hover": {
      borderRadius: "12px",
      backgroundColor: "#ffffff !important",
    },
    "& .Mui-disabled:hover": {
      backgroundColor: "#ececec !important",
    },
    "& .MuiInputBase-root.MuiFilledInput-root:hover": {
      backgroundColor: "#ffffff !important",
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled:hover": {
      backgroundColor: "#ececec !important",
    },
    "& .MuiFormLabel-root": {
      left: icon && "32px",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(183, 33, 54)",
    },
    "& .MuiFilledInput-input": {
      direction: "inherit",
      padding: customize ? "16px" : "25px 12px 8px 12px",
    },
    "& .MuiInputBase-inputMultiline": {
      paddingTop: "0 !important",
    },
    "& input:-webkit-autofill": {
      boxShadow: "none",
      BiBorderRadius: "12px",
    },
  })
);

export default function Input({
  type,
  showIcon,
  StartIcon,
  StartIconProps,
  error,
  helperText,
  customize,
  ...rest
}: InputProps) {
  const [show, setShow] = useState(false);

  return (
    <>
      {StartIcon ? (
        <>
          <Box
            sx={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                left: "16px",
                top: "16px",
                position: "absolute",
                zIndex: 1,
              }}
            >
              <StartIcon
                style={{
                  fontSize: "18px",
                  color: "#939399",
                  ...StartIconProps,
                }}
              />
            </Box>
            <CssTextField fullWidth variant="filled" icon={true} {...rest} />
          </Box>
          {error && helperText && (
            <div
              style={{
                color: "rgb(183, 33, 54)",
                fontSize: "0.75rem",
                lineHeight: "1.66",
                marginTop: "3px",
                marginLeft: "14px",
                fontWeight: "400",
              }}
            >
              {helperText}
            </div>
          )}
        </>
      ) : (
        <>
          {customize && (
            <Typography
              mb={1}
              color={"#0a0a0e"}
              fontSize={"12px"}
              fontWeight={400}
              textTransform={"uppercase"}
            >
              {rest?.label}
            </Typography>
          )}
          <CssTextField
            customize={customize}
            fullWidth
            color="info"
            variant="filled"
            className="customize"
            helperText={helperText}
            type={show && showIcon && type === "password" ? "text" : type}
            {...rest}
            label={!customize ? rest.label : ""}
            InputProps={{
              endAdornment: showIcon && type === "password" && (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShow(!show)}
                    onMouseDown={(e) => e.preventDefault()}
                    aria-label="toggle password visibility"
                  >
                    {show ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              ...rest.InputProps,
            }}
          />
        </>
      )}
    </>
  );
}
