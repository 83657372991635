import { Helmet } from "react-helmet";
import { getRandomInt } from "./LeadingPage";

const SearchNow = () => {
  return (
    <Helmet>
      <noscript>
        {`
<img src="https://ad.doubleclick.net/ddm/activity/src=9598208;type=yelo24;cat=yelol000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${getRandomInt()};gdpr_consent=${getRandomInt()};ord=[${getRandomInt()}]?" width="1" height="1" alt=""/>

`}
      </noscript>
    </Helmet>
  );
};



const SearchNowBtn = () => {
    const script = `
      gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-9598208/yelo24/yelol000+standard'
      });
      `;
    const scriptTag = document.createElement("script");
    scriptTag.innerHTML = script;
    document.head.appendChild(scriptTag);
  };
  
  export { SearchNow, SearchNowBtn };
  