import AutoComplete from "components/atoms/Autocomplete";
import { useEffect, useState } from "react";
import  i18next  from 'i18next';

export default function AutoCompleteRedux({
  input,
  handleBlur,
  handleFocus,
  meta: { error, touched, invalid },
  InputProps,
  AutocompleteProps,
  ...rest
}: any) {
  const { value, onChange, ...inputRest } = input;



  const [cError , setCError] = useState(i18next.t(`${error}`));
	const [currentLanguage,setCurrlang]=useState(i18next.language);

	

	if(currentLanguage !== i18next.language){
		setCurrlang(i18next.language)
	}
	useEffect(()=>{
		setCError(i18next.t(`${error}`));
	},[error,currentLanguage])


  return (
    <AutoComplete
      {...InputProps}
      {...AutocompleteProps}
      {...rest}
      {...inputRest}
      val={value}
      helperText={touched && invalid && cError}
      error={touched && invalid && error && true}
      handleChange={onChange}
      onBlur={(e) => {
        handleBlur?.(e);
        e.preventDefault();
      }}
      onFocus={(e) => {
        handleFocus?.(e);
        e.preventDefault();
      }}
    />
  );
}
