import { Box, Container, ListItem, Menu, Stack } from "@mui/material";
import "./NavBar.scss";

import logo from "assets/logo.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import AuthService from "services/auth.service";
import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import BasicMenu from "components/atoms/BasicMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { useCallback, useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import { ChevronLeft } from "@mui/icons-material";

const NavBar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation");
  const user = useAppSelector((state) => state.auth.user);
  // const origin = useAppSelector((state) => state.auth.orgin);
  const location = useLocation();
  const { pathname } = location;
  const [state, setState] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [currencySelected, setCurrencySelected] = useState("SAR");

  const toggleDrawer = () => {
    setState(!state);
  };

  // const changeLanguage = useCallback(
  //   (lng: any) => {
  //     i18n.changeLanguage(lng);
  //   },
  //   [i18n]
  // );

  const arabicLang = useCallback(() => {
    localStorage.setItem("lang", "ar");

    // changeLanguage("ar");
    // document.body.style.direction = "rtl";
    // document.body.style.fontFamily = "arabic";

    // const newPath = pathname.replace(/^\/(en|ar)/, "/ar");
    const newPathname = window.location.pathname.replace(/^\/(en|ar)/, `/ar`);
    window.location.replace(
      `${newPathname}${window.location.search}${window.location.hash}`
    );
    // navigate(`${newPath}${search}${hash}`, { replace: true });
  }, []);
  const englishLang = useCallback(() => {
    localStorage.setItem("lang", "en");

    // changeLanguage("en");
    // document.body.style.direction = "ltr";
    // document.body.style.fontFamily = "arabic";

    // const newPath = pathname.replace(/^\/(en|ar)/, "/en");
    const newPathname = window.location.pathname.replace(/^\/(en|ar)/, `/en`);
    window.location.replace(
      `${newPathname}${window.location.search}${window.location.hash}`
    );
    // navigate(`${newPath}${search}${hash}`, { replace: true });
  }, []);

  // useEffect(() => {
  //   if (i18n.language === "ar") {
  //     arabicLang();
  //   } else {
  //     englishLang();
  //   }
  // }, [arabicLang, englishLang, i18n.language]);

  const links = [
    // {
    //   path:
    //     i18n.language === "en"
    //       ? "https://iyelo.com/en"
    //       : "https://iyelo.com/ar",

    //   text: t("backToYelo"),
    // },
    // { path: "/", text: t("services") },
    { path: `/business`, text: t("business") },
    // { path: "/unforgettable-moments", text: t("unforgettable_moments") },

    // { path: "/about#service", text: t("about") },
    {
      path:
        i18n.language === "en"
          ? `/global-reach`
          : "/انطلاقة-من-السعودية-نحو-آفاق-عالمية",
      text: t("GlobalReach"),
    },
  ];
  const dropdownLinks = [
    {
      path:
        i18n.language === "en" ? `/airport-transfers` : "/النقل-من-وإلى-المطار",
      text: t("airportTransfers"),
    },
    {
      path:
        i18n.language === "en" ? `/chauffeur-service` : "/خدمات-السائق-الخاص",
      text: t("chauffeurService"),
    },
    {
      path:
        i18n.language === "en"
          ? `/limousine-service`
          : "/خدمة-الليموزين-الفاخرة",
      text: t("limousineService"),
    },
    {
      path:
        i18n.language === "en"
          ? `/city-to-city-transfer`
          : "/السفر-من-مدينة-الى-مدينة",
      text: t("City to City Transfer"),
    },
    {
      path:
        i18n.language === "en" ? `/hire-by-the-hour` : "/خدمة-الحجز-بالساعة",
      text: t("hourlyBasis"),
    },
    {
      path:
        i18n.language === "en"
          ? `/event-transportation`
          : "/النقل-الفاخر-للفعاليات",
      text: t("eventBasis"),
    },
  ];

  const [isFixed, setIsFixed] = useState(false);
  const [opacity, setOpacity] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(false);
      if (pathname.includes("search-form")) setIsFixed(true);
      else {
        setIsFixed(false);
        if (window.scrollY < 60) {
          setIsFixed(false);
          setOpacity(true);
        }
        if (window.scrollY > 60 && window.scrollY < 61) {
          setOpacity(false);
        }
        if (window.scrollY > 250) {
          setIsFixed(true);
          setOpacity(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // if (pathname === "/payment-form") return <></>;
  return (
    <Box
      className="navbar__container"
      sx={{
        position: isFixed ? "sticky" : "relative",
        opacity: opacity ? 1 : 0,
        transition: "all ease 0.3s",
        borderBottom:
          (pathname === "/" ||
            pathname.includes("airport-transfers") ||
            pathname.includes("limousine-service") ||
            pathname.includes("city-to-city-transfer") ||
            pathname.includes("hire-by-the-hour") ||
            pathname.includes("event-transportation") ||
            pathname.includes("chauffeur-service") ||
            pathname.includes("global-reach") ||
            pathname.includes("خدمات-السائق-الخاص") ||
            pathname.includes("خدمة-الليموزين-الفاخرة") ||
            pathname.includes("النقل-من-وإلى-المطار") ||
            pathname.includes("السفر-من-مدينة-الى-مدينة") ||
            pathname.includes("خدمة-الحجز-بالساعة") ||
            pathname.includes("النقل-الفاخر-للفعاليات") ||
            pathname.includes("انطلاقة-من-السعودية-نحو-آفاق-عالمية")) &&
          !isFixed
            ? { xs: "1px solid #DDDCE5", md: "none" }
            : "1px solid #DDDCE5",
        background: "#ffffff",
      }}
    >
      <Container maxWidth="lg">
        <div className="navbar">
          <Stack
            className="cursor"
            onClick={() => navigate("/")}
            direction="row"
          >
            <img src={logo} alt="" />
          </Stack>
          <div className="routes">
            <span
              className="links"
              onClick={handleClick}
              // onMouseOver={handleClick}
              style={{ display: "flex" }}
            >
              {t("services")}{" "}
              <ChevronLeft
                sx={{
                  transition: "all 0.3s ease",
                  transform: Boolean(anchorEl)
                    ? "rotate(90deg)"
                    : "rotate(-90deg)",
                }}
              />
            </span>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "none",
                  border: "1px solid #ECEBF2",
                  borderRadius: "16px",
                  mt: 1.5,
                },
              }}
              transformOrigin={{
                horizontal: i18n.language === "ar" ? "right" : "left",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: i18n.language === "ar" ? "right" : "left",
                vertical: "bottom",
              }}
            >
              {dropdownLinks.map(({ path, text }, i) => (
                // <MenuList key={i} onClick={handleClose} sx={{ p: "12px 16px" }}>
                <ListItem sx={{ py: 0 }} key={i} onClick={handleClose}>
                  <NavLink to={path} className="links">
                    {text}
                  </NavLink>
                </ListItem>
                // </MenuList>
              ))}
            </Menu>
            {links.map(({ path, text }, i) => (
              <NavLink key={i} to={path} className="links">
                {text}
              </NavLink>
            ))}
          </div>
          <Stack
            direction={"row"}
            gap={2}
            alignItems={"center"}
            className="hide__login"
          >
            <BasicMenu
              list={[
                {
                  text: "EN",
                  onClick: englishLang,
                },
                {
                  text: "عربي",
                  onClick: arabicLang,
                },
              ]}
              avatar={false}
              color="#520a76"
            >
              <Stack
                direction="row"
                alignItems="center"
                height={"40px"}
                px={"8px"}
                borderRadius={"12px"}
                border={"1px solid #520a76"}
                columnGap={1}
              >
                <span>{i18n.language === "en" ? "EN" : "عربي"}</span>
                {/* <FaAngleDown /> */}
              </Stack>
            </BasicMenu>
            <div className="hide__login">
              {user ? (
                <Stack direction="row" spacing={2}>
                  <BasicMenu
                    list={[
                      {
                        text: t("profile"),
                        onClick: () => navigate(`/profile`),
                      },
                      {
                        text: t("bookings"),
                        onClick: () => navigate(`/bookings`),
                      },
                      {
                        text: t("wallet"),
                        onClick: () => navigate(`/wallet`),
                      },
                      {
                        text: t("logout"),
                        onClick: () => AuthService.logout(),
                      },
                    ]}
                    avatar={true}
                  >
                    {user.first_name.charAt() + user.last_name.charAt()}
                  </BasicMenu>
                </Stack>
              ) : (
                <Button
                  onClick={() => navigate(`/login`)}
                  variant="contained"
                  color="secondary"
                  sx={{
                    height: "42px",
                    borderRadius: "12px",
                    textTransform: "capitalize",
                  }}
                >
                  {t("Get Started")}
                </Button>
              )}
            </div>
          </Stack>
        </div>
      </Container>

      <MenuIcon
        onClick={toggleDrawer}
        className={
          i18n.language === "ar" ? "burger__icons ar_burger" : "burger__icons"
        }
      />
      <SideBar
        state={state}
        toggleDrawer={toggleDrawer}
        englishLang={englishLang}
        arabicLang={arabicLang}
      />
    </Box>
  );
};

export default NavBar;
