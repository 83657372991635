
const Pin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34314 10.3431 7 12 7C13.6569 7 15 8.34314 15 10Z"
        stroke="#939399"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M12 3C7.58172 3 4 6.38776 4 10.5668C4 15.1181 8.44156 17.3917 11.1616 20.6099C11.5924 21.1195 12.4264 21.133 12.8669 20.6316C15.6372 17.478 20 15.0304 20 10.5668C20 6.38776 16.4183 3 12 3Z"
        stroke="#939399"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Pin;
