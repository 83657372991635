import BaseButton from "@mui/material/Button";

export type ButtonProps = React.ComponentProps<typeof BaseButton>;

export default function Button(props: ButtonProps) {
  return (
    <BaseButton
      disableElevation
      disableFocusRipple
      disableRipple
      disableTouchRipple
      {...props}
    />
  );
}
