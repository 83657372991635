import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg">
      <Box mt={{ xs: "60px", md: "80px" }}>
        <Typography
          color={"#520A76"}
          component={"h1"}
          fontSize={{ xs: 24, md: 40 }}
          fontWeight={700}
        >
          {t("Privacy Policy")}
        </Typography>
        <Typography component={"p"}>
          {t("Privacy Policy Description")}
        </Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Types of Information We Collect")}
        </Typography>
        <Typography component={"p"}>
          {t("Types of Information We Collect Description")}
        </Typography>

        <ol>
          <li>{t("Collect Your name")}</li>
          <li>{t("Collect Date of Birth")}</li>
          <li>{t("Collect Country")}</li>
          <li>{t("Collect Gender")}</li>
          <li>{t("Collect Marital Status")}</li>
          <li>{t("Collect Home, work, or email addresses")}</li>
          <li>{t("Collect Telephone and fax numbers")}</li>
          <li>{t("Collect Nationality")}</li>
          <li>
            {t("Collect ID, Passport, Residence, Driver License or Work Card")}
          </li>
          <li>
            {t(
              "Collect Other information necessary to enhance your experience"
            )}
          </li>
        </ol>

        <Typography component={"p"}>{t("Collect Also")}</Typography>
        <ol>
          <li>{t("Collect Your use of our websites or application")}</li>
          <li>{t("Collect Communication preferences")}</li>
          <li>{t("Collect Responses to promotional offers and surveys")}</li>
        </ol>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Purpose for Collection and Use")}
        </Typography>
        <Typography component={"p"}>
          {t("Purpose for Collection and Use Description")}
        </Typography>
        <ol>
          <li>{t("Purpose A")}</li>
          <li>{t("Purpose B")}</li>
          <li>{t("Purpose C")}</li>
        </ol>
        <Typography component={"p"}>{t("Purpose Last")}</Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Purpose of Processing and Disclosure")}
        </Typography>
        <Typography component={"p"}>
          {t("Purpose of Processing and Disclosure Description")}
        </Typography>
        <ol>
          <li>{t("Processing A")}</li>
          <li>{t("Processing B")}</li>
          <li>{t("Processing C")}</li>
          <li>{t("Processing D")}</li>
          <li>{t("Processing E")}</li>
          <li>{t("Processing F")}</li>
        </ol>
        <Typography component={"p"}>{t("Processing Last")}</Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Consent")}
        </Typography>
        <Typography component={"p"}>{t("Consent Description")}</Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Access to personally identifiable information")}
        </Typography>
        <Typography component={"p"}>
          {t("Access to personally identifiable information Description")}
        </Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Cookies and tracking technologies")}
        </Typography>
        <Typography component={"p"} mb={2}>
          {t("Cookies and tracking technologies Description A")}
        </Typography>
        <Typography component={"p"} mb={2}>
          {t("Cookies and tracking technologies Description B")}
        </Typography>
        <Typography component={"p"}>
          {t("Cookies and tracking technologies Description C")}
        </Typography>
        <ul>
          <li>{t("Cookies and tracking technologies Description list A")}</li>
          <li>{t("Cookies and tracking technologies Description list B")}</li>
          <li>{t("Cookies and tracking technologies Description list C")}</li>
          <li>{t("Cookies and tracking technologies Description list D")}</li>
        </ul>
        <Typography component={"p"} mb={2}>
          {t("Cookies and tracking technologies Description D")}
        </Typography>
        <Typography component={"p"} mb={2}>
          {t("Cookies and tracking technologies Description E")}
        </Typography>
        <Typography component={"p"}>
          {t("Cookies and tracking technologies Description F")}
        </Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Links to other websites")}
        </Typography>
        <Typography component={"p"}>
          {t("Links to other websites Description")}
        </Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Minors privacy")}
        </Typography>
        <Typography component={"p"}>
          {t("Minors privacy Description")}
        </Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Accuracy and privacy")}
        </Typography>
        <Typography component={"p"}>
          {t("Accuracy and privacy Description")}
        </Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Changes to This Privacy Policy")}
        </Typography>
        <Typography component={"p"}>
          {t("Changes to This Privacy Policy Description")}
        </Typography>
      </Box>

      <Box mt={{ xs: "24px", md: "40px" }}>
        <Typography
          component={"h2"}
          color={"#520A76"}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={600}
        >
          {t("Contact Us")}
        </Typography>
        <Typography component={"p"}>
          {t("Contact Us Description")}{" "}
          <a href="mailto:ccc@iyelo.com" style={{ color: "#520A76" }}>
            ccc@iyelo.com
          </a>
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
