import { useState, useEffect } from "react";
import { Box, Drawer, Grid, Typography } from "@mui/material";
import Button from "../Button";
import Input from "../Input";
import Time from "assets/icons/Time";
import { useTranslation } from "react-i18next";
import CloseIcon from "assets/icons/close.svg";

export default function TimeDropdown({
  value,
  onChange,
  InputFieldProps,
  ...rest
}: any) {
  const InputProps = {
    autoComplete: "off",
    id: "time",
    ...InputFieldProps,
    error: InputFieldProps?.helperText ? InputFieldProps?.error : false,
  };

  const isSmallScreen = window.innerWidth < 768;

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState<string | null>(null);
  const [selectedMinute, setSelectedMinute] = useState<string | null>(null);
  //   const [period, setPeriod] = useState<"AM" | "PM">("AM");
  const [timeValue, setTimeValue] = useState<string>(value || "");

  const hours = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const minutes = Array.from({ length: 12 }, (_, i) =>
    (i * 5).toString().padStart(2, "0")
  );

  useEffect(() => {
    if (value) {
      if (typeof value === "string") setTimeValue(value);
    }
  }, [value]);

  useEffect(() => {
    const handleConfirm = () => {
      if (selectedHour && selectedMinute) {
        //   const time = `${selectedHour}:${selectedMinute} ${period}`;
        const time = `${selectedHour}:${selectedMinute}`;
        setTimeValue(time);

        // Call the onChange handler with the formatted time string
        if (onChange) {
          onChange(time); // Ensure we pass a string, not an object
        }
        setOpen(false);
      }
    };
    if (selectedHour && selectedMinute) {
      handleConfirm();
    }
  }, [onChange, selectedHour, selectedMinute]);

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const dropdown = document.getElementById("time-dropdown");
    const input = document.getElementById("time");

    // Close the dropdown only if the click is outside both the input and dropdown
    if (
      dropdown &&
      input &&
      !dropdown.contains(target) &&
      !input.contains(target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      {/* <ClickAwayListener onClickAway={handleOutsideClick}> */}
      <Box position="relative">
        <Input
          value={timeValue || typeof value === "string" ? value : ""}
          {...rest}
          {...InputProps}
          placeholder="HH:MM"
          StartIcon={Time}
          onClick={() => setOpen(true)}
          readOnly
        />
        {/* Dropdown container */}
        {open &&
          (isSmallScreen ? (
            <DrawerComponent open={open} onClose={() => setOpen(false)}>
              <Box
                id="time-dropdown"
                width="100%"
                bgcolor="#ffffff"
                boxShadow={0}
                zIndex={10}
                borderRadius={"12px"}
                mt={1}
                p={2}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      component={"p"}
                      textAlign={"center"}
                      //  mt={1}
                    >
                      {t("hours")}
                    </Typography>
                    <Box maxHeight={"50vh"} overflow={"auto"}>
                      {hours.map((hour) => (
                        <div key={hour}>
                          <Button
                            fullWidth
                            variant={
                              hour === selectedHour ? "contained" : "text"
                            }
                            sx={{ borderRadius: "8px" }}
                            color="info"
                            onClick={() => setSelectedHour(hour)}
                          >
                            {hour}
                          </Button>
                        </div>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      component={"p"}
                      textAlign={"center"}
                      // mt={1}
                    >
                      {t("min")}
                    </Typography>
                    <Box maxHeight={"50vh"} overflow={"auto"}>
                      {minutes.map((minute) => (
                        <div key={minute}>
                          <Button
                            fullWidth
                            variant={
                              minute === selectedMinute ? "contained" : "text"
                            }
                            sx={{ borderRadius: "8px" }}
                            color="info"
                            onClick={() => setSelectedMinute(minute)}
                          >
                            {minute}
                          </Button>
                        </div>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </DrawerComponent>
          ) : (
            <Box
              id="time-dropdown"
              position={"absolute"}
              top="60px"
              left="0"
              width="100%"
              bgcolor="#ffffff"
              boxShadow={0}
              zIndex={10}
              borderRadius={"12px"}
              mt={1}
              p={2}
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    component={"p"}
                    textAlign={"center"}
                    //  mt={1}
                  >
                    {t("hours")}
                  </Typography>
                  <Box maxHeight={"200px"} overflow={"auto"}>
                    {hours.map((hour) => (
                      <div key={hour}>
                        <Button
                          fullWidth
                          variant={hour === selectedHour ? "contained" : "text"}
                          sx={{ borderRadius: "8px" }}
                          color="info"
                          onClick={() => setSelectedHour(hour)}
                        >
                          {hour}
                        </Button>
                      </div>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    component={"p"}
                    textAlign={"center"}
                    // mt={1}
                  >
                    {t("min")}
                  </Typography>
                  <Box maxHeight={"200px"} overflow={"auto"}>
                    {minutes.map((minute) => (
                      <div key={minute}>
                        <Button
                          fullWidth
                          variant={
                            minute === selectedMinute ? "contained" : "text"
                          }
                          sx={{ borderRadius: "8px" }}
                          color="info"
                          onClick={() => setSelectedMinute(minute)}
                        >
                          {minute}
                        </Button>
                      </div>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
      </Box>
      {/* </ClickAwayListener> */}
    </>
  );
}

const DrawerComponent = ({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Drawer
      anchor="bottom"
      open={true}
      onClose={onClose}
      PaperProps={{
        style: {
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
          padding: "16px",
          minHeight: "50vh",
        },
      }}
      sx={{ zIndex: "1400 !important" }}
    >
      <Box
        component={"img"}
        src={CloseIcon}
        alt="X"
        top={"16px"}
        position={"absolute"}
        onClick={() => onClose()}
        right={i18n.language === "ar" ? "auto" : "16px"}
        left={i18n.language === "ar" ? "16px" : "auto"}
        sx={{ cursor: "pointer" }}
      />
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <h3 style={{ margin: 0, fontSize: "16px" }}>{t("time")}</h3>
      </Box>
      {children}
    </Drawer>
  );
};
