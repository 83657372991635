import Select from "components/atoms/Select";
import { useEffect, useState } from "react";
import  i18next  from 'i18next';

export default function SelectRedux({
	input,
	hideError,
	handleBlur,
	handleFocus,
	SelectProps,
	meta: { error, touched, invalid },
	...rest

	
}: any) {

	
	const [cError , setCError] = useState(i18next.t(`${error}`));
	const [currentLanguage,setCurrlang]=useState(i18next.language);

	

	if(currentLanguage !== i18next.language){
		setCurrlang(i18next.language)
	}
	useEffect(()=>{
		setCError(i18next.t(`${error}`));
	},[error,currentLanguage])

	


	
	return (
		<Select
			{...SelectProps}
			{...rest}
			{...input}
			helperText={!hideError && touched && invalid && cError}
			error={!hideError && touched && invalid && error && true}
			onBlur={(e) => {
				handleBlur?.(e);
				e.preventDefault();
			}}
			onFocus={(e) => {
				handleFocus?.(e);
				e.preventDefault();
			}}
		/>
	);
}
