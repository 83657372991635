import { reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields, {
  ReduxFormField,
} from "components/molecules/ReduxFormFields";
import { useTranslation } from "react-i18next";
import InputRedux from "components/molecules/InputRedux";
import { required } from "utils/validate.util";

function ReportAProblemForm({ handleSubmit }: any) {
  const { t } = useTranslation();

  const fields: ReduxFormField[] = [
    {
      name: "report",
      label: t("reportMessage"),
      component: InputRedux,
      validate: [required],
      cellProps: { md: 12 },
      InputProps: { multiline: true, rows: 3 },
    },
  ];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={fields} />
        <br />
        <Button
          fullWidth
          size="large"
          type="submit"
          disableElevation
          variant="contained"
          color="secondary"
          sx={{ height: "52px", borderRadius: "12px" }}
        >
          {t("sendReport")}
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({ form: "ReportAProblemForm" })(ReportAProblemForm);
