import { useEffect, useState } from "react";
import i18next from "i18next";
import TimeDropdown from "components/atoms/TimeDropdown";

export default function TimePickerRedux({
  input,
  label,
  InputProps,
  handleBlur,
  handleFocus,
  TimePickerProps,
  meta: { error, touched, invalid },
  ...rest
}: any) {
  const { onChange, ...inputRest } = input;

  const [cError, setCError] = useState(i18next.t(`${error}`));
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  useEffect(() => {
    if (currentLanguage !== i18next.language) {
      setCurrentLanguage(i18next.language);
    }
  }, [currentLanguage]);

  useEffect(() => {
    setCError(i18next.t(`${error}`));
  }, [error, currentLanguage]);

  return (
    <TimeDropdown
      {...TimePickerProps}
      {...rest}
      onChange={onChange}
      value={input.value} // Bind Redux-form's value
      InputFieldProps={{
        ...TimePickerProps?.InputFieldProps,
        ...InputProps,
        ...inputRest,
        label,
        helperText: touched && invalid && cError,
        error: touched && invalid && error && true,
      }}
    />
  );
}
