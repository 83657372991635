import { change, Field, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import "./pickUp.scss";

import swap from "assets/swap.png";
import { Box, Drawer, Grid } from "@mui/material";
import { date, required, requiredAutoComplete } from "utils/validate.util";
import AutocompleteRedux from "components/molecules/AutocompleteRedux";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import SelectRedux from "components/molecules/SelectRedux";
import OptionService from "utils/option.util";
import { mapAction } from "redux/slices/map/mapSlice";
import { useEffect, useRef, useState } from "react";
import PersistReduxForm from "components/organisms/PersistReduxForm";
import { useTranslation } from "react-i18next";
import MapWithPin from "components/templates/MapWithPin";
import Pin from "assets/icons/Pin";
import Time from "assets/icons/Time";
import Date from "assets/icons/Date";
import TimeDropdownRedux from "components/molecules/TimeDropdownRedux";
import CloseIcon from "assets/icons/close.svg";

// DrawerComponent - A reusable Drawer component to avoid repetition
const DrawerComponent = ({
  open,
  onClose,
  field,
  title,
  children,
}: {
  open: boolean;
  onClose: () => void;
  field: string;
  title: string;
  children: React.ReactNode;
}) => {
  const { i18n } = useTranslation();
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
          padding: "16px",
          minHeight: "80vh",
        },
      }}
    >
      <Box
        component={"img"}
        src={CloseIcon}
        alt="X"
        top={"16px"}
        position={"absolute"}
        onClick={() => onClose()}
        right={i18n.language === "ar" ? "auto" : "16px"}
        left={i18n.language === "ar" ? "16px" : "auto"}
        sx={{ cursor: "pointer" }}
      />
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <h3 style={{ margin: 0, fontSize: "16px" }}>{title}</h3>
      </Box>
      <Box>{children}</Box>
    </Drawer>
  );
};

function PickUpForm({ handleSubmit }: any) {
  const form = "PickUpForm";
  const [formFocused, setFormFocused] = useState(false);
  const open = useAppSelector((state) => state.map.open);
  const responsiveMap = useAppSelector((state) => state.map.destinationMap);

  const tab = useAppSelector((state) => state.booking.tab);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const pickupForm = useRef<HTMLFormElement>(null);
  const outsideForm = useRef<HTMLDivElement>(null);
  const pickup = useAppSelector((state) => state.form?.[form]?.values?.pickup);
  const destination = useAppSelector(
    (state) => state.form?.[form]?.values?.destination
  );
  const duration = useAppSelector(
    (state) => state.form?.[form]?.values?.duration
  );
  const dateForm = useAppSelector((state) => state.form?.[form]?.values?.date);

  // Detect screen size for small screens and drawer
  const [drawerState, setDrawerState] = useState({
    pickup: false,
    destination: false,
    duration: false,
  });

  const isSmallScreen = window.innerWidth < 768;

  const toggleDrawer = (
    type: "pickup" | "destination" | "duration",
    state: boolean
  ) => {
    setDrawerState((prevState) => ({
      ...prevState,
      [type]: state, // Update the respective drawer's state
    }));
  };

  const handleFieldClick = (type: "pickup" | "destination" | "duration") => {
    if (isSmallScreen) {
      toggleDrawer(type, true);
      if (type === "pickup") {
        dispatch(mapAction.setOpen(false));
        dispatch(mapAction.setdestinationMap(false));
        setTimeout(() => {
          dispatch(mapAction.setShow(0));
          dispatch(mapAction.setOpen(true));
        }, 100);
      }
      if (type === "destination") {
        dispatch(mapAction.setOpen(false));
        dispatch(mapAction.setdestinationMap(false));
        setTimeout(() => {
          dispatch(mapAction.setShow(1));
          dispatch(mapAction.setOpen(true));
        }, 100);
      }
      // const selectedInput = document.getElementById(type);
      // selectedInput?.focus();
      // selectedInput?.click();
    } else if (type === "pickup") {
      dispatch(mapAction.setOpen(false));
      dispatch(mapAction.setdestinationMap(false));
      setTimeout(() => {
        dispatch(mapAction.setShow(0));
        dispatch(mapAction.setOpen(true));
      }, 100);
    } else if (type === "destination") {
      dispatch(mapAction.setOpen(false));
      dispatch(mapAction.setdestinationMap(false));
      setTimeout(() => {
        dispatch(mapAction.setShow(1));
        dispatch(mapAction.setOpen(true));
      }, 100);
    }
  };

  // Hook to close the map when clicked outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) =>
      !outsideForm.current?.contains(event.target as Node) &&
      dispatch(mapAction.setOpen(false));
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  useEffect(() => {
    if (formFocused) {
      const destinationInput = document.getElementById("destination");
      if (destinationInput) {
        if (pickup?.details) {
          destinationInput.focus();
          destinationInput.click();
          toggleDrawer("pickup", false);
        }
      }
    }
  }, [pickup]);

  useEffect(() => {
    if (formFocused) {
      const durationInput = document.getElementById("duration");
      if (durationInput) {
        if (pickup?.details) {
          durationInput.focus();
          durationInput.click();
          toggleDrawer("pickup", false);
        }
      }
    }
  }, [pickup]);

  useEffect(() => {
    if (formFocused) {
      const dateInput = document.getElementById("date");
      if (dateInput) {
        if (destination?.details) {
          dateInput.focus();
          dateInput.click();
          toggleDrawer("destination", false);

          setTimeout(() => {
            let parent = dateInput.parentNode as HTMLElement;
            parent.classList.add("Mui-focused");
          }, 0);
        }
      }
    }
  }, [destination]);

  useEffect(() => {
    if (formFocused) {
      const dateInput = document.getElementById("date");
      if (dateInput) {
        if (duration) {
          dateInput.focus();
          dateInput.click();

          setTimeout(() => {
            let parent = dateInput.parentNode as HTMLElement;
            parent.classList.add("Mui-focused");
          }, 0);
        }
      }
    }
  }, [duration]);

  useEffect(() => {
    if (formFocused) {
      const dateInput = document.getElementById("date");
      const timeInput = document.getElementById("time");
      if (timeInput) {
        if (dateForm?.date) {
          timeInput.click();

          setTimeout(() => {
            let parentDiv = dateInput?.parentNode as HTMLElement;
            let parent = timeInput.parentNode as HTMLElement;
            parent.classList.add("Mui-focused");
            parentDiv.classList.remove("Mui-focused");
          }, 0);
        }
      }
    }
  }, [dateForm]);

  const swapValue = () => {
    dispatch(change(form, "pickup", destination));
    dispatch(change(form, "destination", pickup));
  };
  const { t } = useTranslation("translation");
  return (
    <div style={{ position: "relative" }} ref={outsideForm}>
      <form
        onSubmit={handleSubmit}
        ref={pickupForm}
        onFocus={() => setFormFocused(true)}
      >
        <Grid style={{ position: "relative" }} container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className="pickUp__field">
              <Field
                // onClick={() => {
                //   if (isSmallScreen) {
                //     openDrawer();
                //   } else {
                //     dispatch(mapAction.setOpen(false));
                //     dispatch(mapAction.setdestinationMap(false));

                //     setTimeout(() => {
                //       dispatch(mapAction.setShow(0));
                //       dispatch(mapAction.setOpen(true));
                //     }, 100);
                //   }
                // }}
                onClick={() => handleFieldClick("pickup")}
                id="pickup"
                name={"pickup"}
                label={t("pickUp")}
                validate={[requiredAutoComplete]}
                component={AutocompleteRedux}
                StartIcon={Pin}
              />
              {(tab === 0 || tab === 2) && (
                <img
                  className={
                    i18n.language === "en"
                      ? "swap__image"
                      : "swap__image arSwap"
                  }
                  onClick={swapValue}
                  src={swap}
                  alt=""
                />
              )}
            </div>
          </Grid>
          {tab === 0 || tab === 2 ? (
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Field
                // onClick={() => {
                //   if (isSmallScreen) {
                //     openDrawer();
                //   } else {
                //     dispatch(mapAction.setOpen(false));
                //     dispatch(mapAction.setdestinationMap(true));

                //     setTimeout(() => {
                //       dispatch(mapAction.setShow(1));
                //       dispatch(mapAction.setOpen(true));
                //     }, 100);
                //   }
                // }}
                onClick={() => handleFieldClick("destination")}
                id="destination"
                name={"destination"}
                label={t("destination")}
                validate={[requiredAutoComplete]}
                component={AutocompleteRedux}
                StartIcon={Pin}
              />
            </Grid>
          ) : (
            <Grid
              onClick={() => {
                if (isSmallScreen) {
                  // openDrawer();
                } else {
                  dispatch(mapAction.setOpen(false));
                }
              }}
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
            >
              <Field
                id="duration"
                name={"duration"}
                label={t("duration")}
                validate={[required]}
                component={SelectRedux}
                SelectProps={{
                  // @ts-ignore
                  options: OptionService.getOptions_km(23, 2, " ", t("hours"), t("incl"), t("km")),
                }}
                StartIcon={Time}
                // onClick={() => handleFieldClick("duration")}
              />
            </Grid>
          )}
          <Grid
            onClick={() => dispatch(mapAction.setOpen(false))}
            item
            lg={2}
            md={2}
            sm={12}
            xs={12}
          >
            <Field
              id={"date"}
              name={"date"}
              label={t("date")}
              validate={[date]}
              disablePast
              component={DatePickerRedux}
              StartIcon={Date}
            />
          </Grid>
          <Grid
            onClick={() => dispatch(mapAction.setOpen(false))}
            item
            lg={2}
            md={2}
            sm={12}
            xs={12}
          >
            <Field
              id="time"
              name={"time"}
              label={t("time")}
              validate={[required]}
              component={TimeDropdownRedux}
              // onClick={() => handleFieldClick("time")}
              // StartIcon={Time}
            />
          </Grid>
          <Grid
            onClick={() => dispatch(mapAction.setOpen(false))}
            item
            lg={2}
            md={2}
            sm={12}
            xs={12}
          >
            <Button
              // endIcon={
              //   <img
              //     className={i18n.language === "ar" ? "rotate" : ""}
              //     onClick={() => {
              //       dispatch(mapAction.setShow(0));
              //     }}
              //     src={sendRequest}
              //     alt="none"
              //   />
              // }
              sx={{
                width: "100%",
                maxWidth: { xs: "100%", md: "200px" },
                height: "55px",
                marginLeft: "auto",
                display: "flex",
                borderRadius: "12px",
                justifyContent: "center",
              }}
              color="info"
              variant="contained"
              type="submit"
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
        <>
          <DrawerComponent
            open={drawerState.pickup}
            onClose={() => toggleDrawer("pickup", false)}
            field="pickup"
            title={t("PickUp")}
          >
            <Field
              onClick={() => handleFieldClick("pickup")}
              id="pickup"
              name="pickup"
              label={t("pickUp")}
              validate={[requiredAutoComplete]}
              component={AutocompleteRedux}
              StartIcon={Pin}
            />
            <Box mt={3}>
              <MapWithPin />
            </Box>
          </DrawerComponent>

          <DrawerComponent
            open={drawerState.destination}
            onClose={() => toggleDrawer("destination", false)}
            field="destination"
            title={t("destination")}
          >
            <Field
              onClick={() => handleFieldClick("destination")}
              id="destination"
              name="destination"
              label={t("destination")}
              validate={[requiredAutoComplete]}
              component={AutocompleteRedux}
              StartIcon={Pin}
            />
            <Box mt={2}>
              <MapWithPin />
            </Box>
          </DrawerComponent>

          <DrawerComponent
            open={drawerState.duration}
            onClose={() => toggleDrawer("duration", false)}
            field="duration"
            title={t("duration")}
          >
            <Field
              onClick={() => handleFieldClick("duration")}
              id="duration"
              name="duration"
              label={t("duration")}
              validate={[required]}
              component={SelectRedux}
              SelectProps={{
                // @ts-ignore
                options: OptionService.getOptions_km(23, 2, " ", t("hours")),
              }}
              StartIcon={Time}
            />
          </DrawerComponent>
        </>

        {/* <Button
          endIcon={
            <img
              className={i18n.language === "ar" ? "rotate" : ""}
              onClick={() => {
                dispatch(mapAction.setShow(0));
              }}
              src={sendRequest}
              alt="none"
            />
          }
          sx={{
            width: "100%",
            maxWidth: "200px",
            height: "55px",
            marginLeft: "auto",
            marginTop: "32px",
            display: "flex",
            justifyContent: "space-between",
          }}
          color="info"
          variant="contained"
          type="submit"
        >
          {t("search")}
        </Button> */}
      </form>
      {open === true && (
        <div
          className={responsiveMap === true ? "pinMap responsiveMap" : "pinMap"}
        >
          {/* <EditMapAddress /> */}
          <MapWithPin
          // center={{ lat: 24.7191924, lng: 46.6722224 }} zoom={10}
          />
        </div>
      )}

      <PersistReduxForm form={form} />
    </div>
  );
}

export default reduxForm({
  form: "PickUpForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PickUpForm);
