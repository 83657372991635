"use client";

import { Box, Typography, Container } from "@mui/material";
import { Swiper } from "swiper/react";
import { Navigation, FreeMode, Mousewheel, Scrollbar } from "swiper/modules";
import { useEffect, useRef, useState } from "react";
import "swiper/css/navigation";
import "swiper/css";
import "./SwiperSlider.scss";
import "swiper/css/mousewheel";
import { useTranslation } from "react-i18next";

interface SwiperSliderProps {
  children: React.ReactNode;
  heading: string;
  name: string;
  slidesPerViews: any;
  showNavigation?: Boolean;
}

export default function SwiperSlider({
  children,
  heading,
  name,
  slidesPerViews,
  showNavigation = true,
}: SwiperSliderProps) {
  const { i18n } = useTranslation();
  const swiperRef = useRef<any>(null);
  const [space, setSpace] = useState(48);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 1480 && width > 600) setSpace(48);
    else if (width < 600) setSpace(24);
    else setSpace(width - 1440);
  };
  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (swiperRef.current) {
      const direction = i18n.language === "ar" ? "rtl" : "ltr";
      (swiperRef.current as any).swiper?.changeLanguageDirection(direction);
    }
  }, [i18n.language]);

  return (
    <Box
      className="cities_slider"
      sx={{
        position: "relative",
        maxWidth: "100%",
        gap: { xs: 2, md: 3 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container maxWidth="lg" sx={{ position: "relative" }}>
        <Typography
          variant="h4"
          sx={{
            color: "#520a76",
            fontSize: { xs: 24, md: 32 },
            fontWeight: "bold",
            lineHeight: { xs: "32px", md: "48px" },
            letterSpacing: "0.165px",
          }}
        >
          {heading}
        </Typography>
        {showNavigation && (
          <>
            <div
              className={`${name}-prev ${
                i18n.language === "en"
                  ? "custom-swiper-button-prev"
                  : "custom-swiper-button-prev custom-swiper-button-prev-ar"
              }`}
            >
              <svg
                width="16"
                height="24"
                viewBox="0 0 16 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 20L2 12L10 4"
                  stroke="#520A76"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              className={`${name}-next
                ${
                  i18n.language === "en"
                    ? "custom-swiper-button-next"
                    : "custom-swiper-button-next custom-swiper-button-next-ar"
                }
              `}
            >
              <svg
                width="16"
                height="24"
                viewBox="0 0 16 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 20L14 12L6 4"
                  stroke="#520A76"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </>
        )}
      </Container>
      <Box position={"relative"}>
        <div className="full_container_for_slider">
          <Swiper
            onSwiper={(swiper: any) => {
              swiperRef.current = swiper;

              swiper.navigation.init();
              swiper.navigation.update();
              swiper.slideTo(1, 0);
            }}
            modules={[Navigation, FreeMode, Mousewheel, Scrollbar]}
            breakpoints={{
              100: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 3.5,
              },
              1200: {
                slidesPerView: slidesPerViews,
              },
            }}
            mousewheel={{
              invert: false,
              forceToAxis: true,
            }}
            cssMode={true}
            freeMode={{
              enabled: true,
              sticky: true,
            }}
            spaceBetween={16}
            watchOverflow={false}
            centerInsufficientSlides={true}
            slidesOffsetBefore={space}
            slidesOffsetAfter={space}
            className="city-swiper"
            lang={i18n.language}
            navigation={{
              prevEl: `.${name}-prev`,
              nextEl: `.${name}-next`,
            }}
          >
            {children}
          </Swiper>
        </div>
      </Box>
    </Box>
  );
}
